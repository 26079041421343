import React from 'react';
import PropTypes from 'prop-types';

const GrayWrapper = props => (
	<div id={props.id} className="PanelGray GrayWrapper h-paddingT--xs h-paddingB--xs h-marginB--lg">
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-12">
					<div className="row">{props.children}</div>
				</div>
			</div>
		</div>
	</div>
);

GrayWrapper.propTypes = {
	id: PropTypes.number,
	children: PropTypes.node,
};

export default GrayWrapper;
