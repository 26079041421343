export default internal => [
	{ Header: 'Report name', accessor: 'display_name', minWidth: 276 },
	{ Header: 'Partner', accessor: 'partner', minWidth: 160, show: !internal },
	{ Header: 'Description', accessor: 'description', minWidth: 314 },
	{ 
		Header: 'Custom Report', 
		id: 'customReport',
		minWidth: 150,
		accessor({ custom_report }) {
			return custom_report ? 'No' : 'Yes';
		}
	},
];
