import React from 'react';
import PropTypes from 'prop-types';
import Formsy, { addValidationRule } from 'formsy-react';
import randomKey from 'lodash.uniqueid';

import { validators } from 'gogo-sphere-shared-util';

import SmartButton from '../SmartButton';
import SVGIcon from '../SVGIcon/SVGIcon';

addValidationRule('isNotWhitespaceOnly', (values, value) => validators.isNotWhitespaceOnly(value));
addValidationRule('areValidEmails', (values, value) => validators.areValidEmails(value, 1)); // all emails valid and at least one email in text

export default class SendEmailForm extends React.Component {
	static propTypes = {
		error: PropTypes.string,
		dataAutomationSuffix: PropTypes.string.isRequired,
		closeCallback: PropTypes.func,
		submitCallback: PropTypes.func.isRequired,
		title: PropTypes.string,
		dataAutomation: PropTypes.string,
		children: PropTypes.array,
		inProgress: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.submit = ::this.submit;
		this.enableButton = ::this.enableButton;
		this.disableButton = ::this.disableButton;
		this.submit = ::this.submit;
		this.state = {
			error: null,
			formIsValid: false,
		};
	}

	submit(formData) {
		this.props.submitCallback(formData);
	}

	enableButton() {
		if (!this.state.formIsValid) {
			this.setState({
				formIsValid: true,
			});
		}
	}

	disableButton() {
		if (this.state.formIsValid) {
			this.setState({
				formIsValid: false,
			});
		}
	}

	render() {
		return (
			<div>
				<div data-automation={`regionModalSendEmail${this.props.dataAutomation}`}>
					<div>
						<div>
							<SVGIcon
								icon="CloseIcon"
								wrapperClassName="ButtonClose"
								handleClick={this.props.closeCallback}
								aria-label="Close"
								dataAutomation="actionCloseSendEmailForm"
							/>
							<h4
								className="h-textCenter h-marginB--md"
								data-automation={`action${this.props.dataAutomation}`}
							>
								{this.props.title}
							</h4>
						</div>
						<div>
							<div className="h-TextCenter">
								<span style={{ color: 'red' }}>{this.state.error}</span>
							</div>
							{this.state.errorsDescription
								? this.state.errorsDescription.map(ed => (
										<div className="h-TextCenter" key={`error-${randomKey()}`}>
											<span style={{ color: 'red' }}>{ed.message}</span>
										</div>
								  ))
								: null}
							<Formsy
								id={`form${this.props.dataAutomationSuffix}`}
								className="h-marginT--md"
								ref={el => {
									this.sendEmailForm = el;
								}}
								data-automation={`form${this.props.dataAutomationSuffix}`}
								onValidSubmit={this.submit}
								onValid={this.enableButton}
								onInvalid={this.disableButton}
							>
								{this.props.children}
								{this.props.error && (
									<p
										className="Modal__listItem Modal__listItem--error"
										data-automation={`textErrorMessage${this.props.dataAutomationSuffix}`}
									>
										{this.props.error}
									</p>
								)}

								<div className="h-pullRight">
									<input
										type="button"
										className="Button Button--text Button--text--underline"
										data-automation={`actionButtonCancel${this.props.dataAutomationSuffix}`}
										defaultValue="Cancel"
										onClick={this.props.closeCallback}
									/>
									<SmartButton
										type="submit"
										className="Button Button--action"
										formNoValidate
										isInPendingState={this.props.inProgress}
										text="Send"
										data-dismiss="no-dismiss"
										isDisabled={!this.state.formIsValid || this.props.inProgress}
										pendingText="Sending..."
										dataAutomation={`actionButtonSaveUser${this.props.dataAutomationSuffix}`}
										defaultValue="Send"
									/>
								</div>
							</Formsy>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
