import React from 'react';
import { connect, Provider } from 'react-redux';

import ComboSelect from 'react-combo-select';
import { store } from 'gogo-sphere-shared-redux';

export const _AirlineSwitcher = props => {
	const render = () => {
		const {
			userDetails,
			currentAirline: { selectedAirline },
			menuControl: { isAirlineSwitcherDisabled },
		} = props;

		let profileAirline = null;

		if (userDetails) {
			const airlineCodesNum = userDetails['airline_codes'] ? userDetails['airline_codes'].length : 0;

			if (airlineCodesNum > 1) {
				profileAirline = (
					<div className="h-marginT--md">
						<ComboSelect
							value={selectedAirline || userDetails['airline_codes'][0]}
							data={userDetails['airline_codes']}
							iconSelectInactive={false}
							iconSelectActive={false}
							onChange={props.onChangeAirline}
							disabled={isAirlineSwitcherDisabled}
							data-automation="fieldSwitchAirlines"
						/>
					</div>
				);
			}
		}

		return profileAirline;
	};

	return render();
};

const mapStateToProps = state => ({
	currentAirline: state.currentAirline,
	menuControl: state.menuControl,
	userDetails: state.userDetails,
});

const AirlineSwitcher = connect(mapStateToProps)(_AirlineSwitcher);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<AirlineSwitcher {...this.props} />
			</Provider>
		);
	}
}
