import React from 'react';
import PropTypes from 'prop-types';

const Tile = props => (
	<div className={props.icon ? 'Tile' : 'Tile Tile--noIcon'} data-automation={`regionTile_${props.name}`}>
		{props.icon && (
			<div>
				{props.icon == 'Tile__icon--defaultIDP' || props.icon == 'Tile__icon--defaultDepo' ? (
					<div
						className={`Tile__icon Tile__icon--default ${props.icon}`}
						data-automation={`regionTileDefaultIcon_${props.name}`}
					/>
				) : (
					<img
						className="Tile__icon"
						src={props.icon}
						alt="Tile icon"
						data-automation={`regionTileIcon_${props.name}`}
					/>
				)}
				<div className="Tile__iconHalfCircleBorder" />
			</div>
		)}
		<div className="Tile__info">
			<h4 className="h-textCenter h-marginB--sm" data-automation={`textTileName_${props.name}`}>
				{props.name}
			</h4>
			{props.description && (
				<p className="Tile__infoDescription h-textCenter" data-automation={`textTileDescription_${props.name}`}>
					({props.description})
				</p>
			)}
		</div>

		<div className="Tile__footer" data-automation={`regionTileFooter_${props.name}`}>
			{props.children}
		</div>
	</div>
);

Tile.propTypes = {
	children: PropTypes.any,
	icon: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
};

Tile.defaultProps = {
	name: '',
	description: '',
};

export default Tile;
