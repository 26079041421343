import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-combo-modal';

import LoadingIndicator from '../LoadingIndicator';
import SmartButton from '../SmartButton';
import YesNo from '../YesNo';
import i18n from '../util/i18n';

const loadingRow = {
	email: <LoadingIndicator />,
	roles: <LoadingIndicator />,
};

const loadingRows = [loadingRow];

export default class extends React.Component {
	static displayName = 'AdminTable';

	static propTypes = {
		admins: PropTypes.object.isRequired,
		deleteAdmin: PropTypes.func,
		partnerId: PropTypes.number,
	};

	state = {
		pending: {
			deletes: [],
		},
		admin: {},
		isDeleteAdminModalOpen: false,
	};

	onCloseDeleteModal = () => this.setState({ isDeleteAdminModalOpen: false });
	onOpenDeleteModal = () => this.setState({ isDeleteAdminModalOpen: true });

	onSendDeleteRequest = user => {
		this.onCloseDeleteModal();
		const index = this.props.admins.data.findIndex(el => el.id === user.id);
		this.props.deleteAdmin(index, user);
	};

	generateTable = tableBody => (
		<div className="h-overflowAuto h-fullWidth">
			<Modal open={this.state.isDeleteAdminModalOpen} onCloseCallback={this.onCloseDeleteModal}>
				<YesNo
					onYesCallback={() => this.onSendDeleteRequest(this.state.admin)}
					onNoCallback={this.onCloseDeleteModal}
					title="Delete admin"
				>
					<p data-automation="textDeleteAdminConfirmation">
						Are you sure you want to delete admin for this partner?
					</p>
				</YesNo>
			</Modal>

			<table
				className="DataTable DataTable--stripped DataTable--bordered DataTable--framed"
				data-automation="tablePartnerAdminDetails"
			>
				<thead>
					<tr>
						<th data-automation="textTableHeadingAdminEmail">{i18n._t('user_email')}</th>
						<th data-automation="textTableHeadingAdminOperations" />
					</tr>
				</thead>
				<tbody>{tableBody}</tbody>
			</table>
		</div>
	);

	render() {
		const { partnerId } = this.props;
		const rows = this.props.admins.isLoading ? loadingRows : this.props.admins.data;
		let tableBody = [];

		if (rows.length === 0) {
			// empty table
			tableBody.push(
				<tr key="empty">
					<td colSpan="2" className="h-textCenter">
						{i18n._t('no_admins_for_partner')}
					</td>
				</tr>
			);
		} else {
			// regular table
			tableBody = rows.map(
				admin => (
					<tr key={admin.email} data-automation={`admin-email-${admin.email}`}>
						<td data-automation="textAdminEmail">{admin.email}</td>
						<td className="h-textRight" data-automation="textAdminOperations">
							<span className="h-flexCenter" role="group">
								<SmartButton
									dataAutomation="actionOpenDeleteAdminConfirmation"
									isInPendingState={!!this.state.pending.deletes[partnerId]}
									isDisabled={this.props.admins.isLoading}
									text={i18n._t('delete')}
									pendingText={i18n._t('deleting')}
									onClickCallback={this.onOpenDeleteModal}
								/>
							</span>
						</td>
					</tr>
				),
				this
			);
		}

		return this.generateTable(tableBody);
	}
}
