import { isExisty } from 'gogo-sphere-shared-util';

const setColumnsAdditionalProps = (columns, dataAutomationSuffix, columnsMinWidth) => {
	let globalFilterApplied = false;
	return columns.map((column, index) => {
		const newColumn = {
			...column,
			getHeaderProps: () => ({
				'data-automation': `table-${dataAutomationSuffix}-inner-header-${index}`,
			}),
		};

		if (column.filterable !== false && !globalFilterApplied) {
			newColumn.filterAll = true;
			newColumn.filterMethod = (filter, rows) => {
				if (filter.value === '') {
					return rows;
				}

				const excluded = columns.reduce((arr, col) => {
					if (col.filterable === false) {
						arr.push(col.id ? col.id : col.accessor);
					}
					return arr;
				}, []);

				return rows.filter(row => {
					for (const rowKey in row) {
						if (
							Object.prototype.hasOwnProperty.call(row, rowKey) &&
							!excluded.includes(rowKey) &&
							!rowKey.startsWith('_') &&
							isExisty(row[rowKey]) &&
							typeof row[rowKey] === 'string' &&
							row[rowKey].toLowerCase().includes(filter.value.toLowerCase())
						) {
							return true;
						}
					}

					return false;
				});
			};
			globalFilterApplied = true;
		}

		if (columnsMinWidth && !column.minWidth) {
			newColumn.minWidth = columnsMinWidth;
		}

		return newColumn;
	});
};

export default setColumnsAdditionalProps;
