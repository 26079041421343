import PropTypes from 'prop-types';

import React from 'react';

/**
 * Filed wrapper is UI unifier. It ads custom class '' together with other custom classes
 *
 * In order to show inline elements pass 'inline' as param
 */
export default class FieldWrapper extends React.Component {
	static displayName = 'FieldWrapper';

	static propTypes = {
		children: PropTypes.any.isRequired,
		className: PropTypes.string,
		isInline: PropTypes.bool,
	};

	render() {
		const { className, isInline } = this.props;

		const combinedClasses = `${className || ''} ${isInline ? 'h-displayInline' : 'h-displayBlock'}`;

		return <div className={` ${combinedClasses}`}>{this.props.children}</div>;
	}
}
