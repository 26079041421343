/* eslint-disable  */
import PropTypes from 'prop-types';
import React from 'react';

export default class DatatablesLinkLengthMenu extends React.Component {
	static propTypes = {
		lengths: PropTypes.array,
		defaultLength: PropTypes.number,
		datatable: PropTypes.object.isRequired,
	};

	static defaultProps = {
		lengths: [10, 25, 50, 100],
		defaultLength: 0,
	};

	state = {
		current: this.props.defaultLength,
	};

	componentDidUpdate() {
		if (this.props.datatable.api && !this.tableDrawHandlerBound) {
			this.props.datatable.api.on('draw', _ => {
				this.setState({
					total: this.props.datatable.api.rows().count(),
				});
			});

			this.tableDrawHandlerBound = true;

			this.setState({
				total: this.props.datatable.api.rows().count(),
			});
		}
	}

	_changeLength = (index, event) => {
		if (event) event.preventDefault();

		const newLength = typeof this.props.lengths[index] === 'number' ? this.props.lengths[index] : -1;

		if (newLength == this.props.datatable.api.page.len()) return;

		this.props.datatable.api.page.len(newLength);
		this.props.datatable.api.draw();
		this.setState({
			current: index,
		});
	};

	render() {
		const items = this.props.lengths.map((length, index) => (
			<li key={`dt-length-menu-item-${index}`} className={index == this.state.current ? 'active' : null}>
				<a href="#" key={`dt-length-menu-item-link-${index}`} onClick={this._changeLength.bind(this, index)}>
					{length}
				</a>
			</li>
		));

		return (
			<div className="DataTablesLengthMenu">
				{this.state.total > 0 && (
					<span className="DataTablesLengthMenu__total">Total results: {this.state.total}</span>
				)}
				<span>Results per page:</span>
				<ul className="h-noMargin">{items}</ul>
			</div>
		);
	}
}
