import React from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import {
	store,
	fetchPartnerDetails,
	menuControlActionCreators,
	authActionCreators,
	setUserPrefs,
	getUserPrefs,
	tableauAppsActionCreators,
	getInternalApps,
	getBIATableauAppId,
} from 'gogo-sphere-shared-redux';
import { isGogoUser, bugsnag, saveLastViewedPage } from 'gogo-sphere-shared-util';

import Header from './Header/Header';
import Footer from './Footer';
import ErrorNotification from './ErrorNotification';
import NotificationSystem from './NotificationSystem';

class _AppContainer extends React.Component {
	static propTypes = {
		closeAll: PropTypes.func,
		saveForcedFromPage: PropTypes.func,
		triggerRefreshTokenTimer: PropTypes.func,
		fetchPartnerDetails: PropTypes.func,
		loginStatus: PropTypes.string,
		isTokenExpired: PropTypes.bool,
		userDetails: PropTypes.shape({
			roles: PropTypes.array,
			permissions: PropTypes.array,
		}),
		currentPartner: PropTypes.shape({
			selectedPartner: PropTypes.string,
			partnerDetails: PropTypes.object,
		}),
		app: PropTypes.shape({
			rememberLastVisitedPage: PropTypes.bool,
			path: PropTypes.string,
			name: PropTypes.string,
			tableauApp: PropTypes.bool,
			showPartnerLogo: PropTypes.bool,
		}),
		apps: PropTypes.array,
		gotTableauApps: PropTypes.bool,
		getTableauApps: PropTypes.func,
		BIATableauAppId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		getBIATableauAppId: PropTypes.func,
		rememberLastVisitedPage: PropTypes.bool,
		setUserPrefs: PropTypes.func,
		navItems: PropTypes.array,
		allowedAirlines: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		notifications: PropTypes.array,
		hasAccessToPage: PropTypes.func,
		tabNavigationOverride: PropTypes.bool,
		navChildren: PropTypes.array,
		noAppHeader: PropTypes.bool,
		children: PropTypes.node,
		pageRoutes: PropTypes.array,
		user: PropTypes.shape({}),
		localStorageUnavailable: PropTypes.func,
		getTableauInternalApps: PropTypes.func.isRequired,
		renderHeader: PropTypes.func,
	};

	static defaultProps = {
		renderHeader: (props) => <Header {...props} />,
	};

	constructor(props) {
		super(props);

		this.state = {
			error: null,
		};

		this.onChangePartner = ::this.onChangePartner;
	}

	componentDidMount() {
		this.props.closeAll();

		if (this.props.loginStatus === 'LOGGED_IN') {
			this.props.triggerRefreshTokenTimer();
		}

		if (this.props.loginStatus !== 'LOGGED_IN' || this.props.isTokenExpired) {
			this.props.saveForcedFromPage(this.props.location.pathname);
			this.props.history.push('/login');
		} else {
			this._checkPartnerUrlParamAllowed(this.props);
		}

		this._init();

		window.adobeDTMData = {
			airline: this.props.currentPartner.selectedPartner,
			roles: this.props.userDetails ? this.props.userDetails.roles : null,
		};

		if (
			this.props.location.pathname === '/' &&
			!this.props.gotTableauApps &&
			this.props.loginStatus === 'LOGGED_IN' &&
			this.props.currentPartner.selectedPartner
		) {
			this.props.getTableauApps(this.props.currentPartner.selectedPartner);
		}

		if (
			this.props.location.pathname === '/' &&
			this.props.loginStatus === 'LOGGED_IN' &&
			isGogoUser(this.props.userDetails)
		) {
			this.props.getTableauInternalApps();
		}

		if (!this.props.BIATableauAppId && this.props.loginStatus === 'LOGGED_IN') {
			this.props.getBIATableauAppId();
		}
	}

	static getDerivedStateFromProps() {
		return { error: null };
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.isTokenExpired || nextProps.loginStatus === 'NOT_LOGGING_IN' || !nextProps.userDetails) {
			this.props.saveForcedFromPage(this.props.location.pathname);
			this.props.history.push('/login');

			return false;
		}
		return true;
	}

	componentDidUpdate(prevProps) {
		this._init();

		let currentPath = this.props.location.pathname;

		//this is BIA-specific, generalize when we have another example
		if (currentPath.indexOf('customreports/report') >= 0) {
			currentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
		}

		if (
			this.props.app &&
			this.props.app.rememberLastVisitedPage &&
			localStorage.getItem(`lastViewedPage${this.props.app.path}`) !== currentPath
		) {
			saveLastViewedPage(currentPath, this.props.app.path, this.props.setUserPrefs);
		}

		if (
			this.props.location.pathname === '/' &&
			this.props.currentPartner.selectedPartner !== prevProps.currentPartner.selectedPartner &&
			this.props.loginStatus === 'LOGGED_IN' &&
			this.props.currentPartner.selectedPartner
		) {
			this.props.getTableauApps(this.props.currentPartner.selectedPartner);
		}
		if (
			this.props.location.pathname === '/' &&
			this.props.currentPartner.selectedPartner !== prevProps.currentPartner.selectedPartner &&
			this.props.loginStatus === 'LOGGED_IN' &&
			this.props.currentPartner.selectedPartner &&
			isGogoUser(this.props.userDetails)
		) {
			this.props.getTableauInternalApps();
		}
	}

	onChangePartner(partner) {
		if (window.adobeDTMData) {
			window.adobeDTMData.airline = partner;
		}

		// we now fetch partner no matter what, because it's properties change (locale / locales)
		this.props.fetchPartnerDetails(partner);
	}

	_checkPartnerUrlParamAllowed(props) {
		if (props.location.pathname === '/') {
			if (
				props.userDetails &&
				(props.userDetails['partner_code'] === '*' ||
					props.userDetails['partner_code'] === props.currentPartner.selectedPartner) &&
				props.currentPartner.selectedPartner
			) {
				this.onChangePartner(props.currentPartner.selectedPartner);
			} else {
				const partnerList = [...props.userDetails['partner_codes']].sort();
				// https://git.gogoair.com/GGS/shared-redux/commit/3723ebf17eec79cfb930dd58d4bbce9db093324b line: 116
				this.onChangePartner(partnerList[0]);
			}
		}
	}

	pages = [];

	_init() {
		let lastViewedPage;

		try {
			// check if user has permission to view last viewed page
			lastViewedPage = this.props.app && localStorage.getItem(`lastViewedPage${this.props.app.path}`);
		} catch (e) {
			this.props.localStorageUnavailable();
		}

		const pageDetails = lastViewedPage
			? this.props.navItems.filter((item) => {
					const lastViewed = lastViewedPage.substring(5, lastViewedPage.length);

					return lastViewed === item.path;
			  })[0]
			: undefined;

		const pagePermissionTarget = pageDetails ? pageDetails.permission.target : undefined;
		const pagePermissionDomain = pageDetails ? pageDetails.permission.domain : undefined;

		const hasAccessToViewPage =
			(pagePermissionTarget || pagePermissionDomain) && this.props.userDetails
				? this.props.userDetails.permissions.some((perm) => {
						if (pagePermissionTarget) {
							return perm.target === pagePermissionTarget;
						}
						return perm.domain === pagePermissionDomain;
				  })
				: false;

		//check if app is allowed for the user's airline
		if (
			this.props.allowedAirlines &&
			this.props.allowedAirlines !== '*' &&
			this.props.allowedAirlines.indexOf(this.props.currentPartner.selectedPartner) === -1
		) {
			bugsnag.notify('Access denied without making an API request', {
				metaData: {
					debug: {
						reason: 'Airline access check failed',
						location: window.location.href,
					},
				},
				severity: 'warning',
				context: 'Client-side 403',
			});
			this.props.history.push('/forbidden');
		}

		//if we're on the app url, check if user has access to any pages, redirect to landing page if no app root route is defined
		if (
			this.props.app &&
			(this.props.location.pathname.endsWith(this.props.app.path) ||
				this.props.location.pathname.endsWith(`${this.props.app.path}/`))
		) {
			if (this.pages.length && this.pages[0].path !== '/') {
				const indexPage = `/${this.props.app.path}/${this.pages[0].path}`;

				// eslint-disable-next-line no-unused-expressions
				lastViewedPage && hasAccessToViewPage
					? this.props.history.replace(lastViewedPage)
					: this.props.history.push(indexPage);
			}
		}

		// Control document/tab title name
		if (this.props.app) {
			document.title = this.props.app.name;
		} else {
			document.title = 'Sphere by Intelsat';
		}
	}

	_recurseNestedSubpages = (routeCollection, subpages, pageRoute, error, path) => {
		subpages.forEach((subpage) => {
			const nextPath = path[0] === '/' ? `${path}/${subpage.page.path}` : `/${path}/${subpage.page.path}`;

			routeCollection.push(
				<Route
					exact
					path={nextPath}
					key={subpage.appPath + pageRoute.page.path + subpage.page.path}
					render={(props) => (
						<subpage.component
							{...props}
							airline={this.props.currentPartner.selectedPartner}
							partner={this.props.currentPartner.selectedPartner}
							currentPartner={this.props.currentPartner}
							partnerCode={this.props.currentPartner.selectedPartner}
							partnerDetails={this.props.currentPartner.partnerDetails}
							user={this.props.userDetails}
							error={error}
							loginStatus={this.props.loginStatus}
						/>
					)}
					name={subpage.page.name}
				/>
			);

			if (subpage.subpages) {
				this._recurseNestedSubpages(routeCollection, subpage.subpages, subpage, error, nextPath);
			}
		});
	};

	_generateSubpageRoutes(subpages, pageRoute, error) {
		const routeCollection = [];

		this._recurseNestedSubpages(
			routeCollection,
			subpages,
			pageRoute,
			error,
			`${pageRoute.appPath}/${pageRoute.page.path}`
		);

		return routeCollection;
	}

	render() {
		if (bugsnag && this.props.app) {
			if (!bugsnag.metaData) {
				bugsnag.metaData = {};
			}

			bugsnag.metaData.app = this.props.app.name;
			bugsnag.metaData.appShort = this.props.app.path;
		}

		this.pages = [];

		if (this.props.userDetails && this.props.navItems) {
			Object.keys(this.props.navItems).forEach((key) => {
				if (
					!this.props.navItems[key].disabled &&
					this.props.hasAccessToPage(
						this.props.userDetails.roles,
						this.props.userDetails.permissions,
						this.props.navItems[key]
					)
				) {
					this.pages.push(this.props.navItems[key]);
				}
			});
		}

		const error = this.state.error && (
			<ErrorNotification text={`Error ${this.state.error.code}: ${this.state.error.text}`} />
		);

		const appLink = this.props.location.pathname.replace(/[^a-z0-9]/gi, '');

		const headerProps = {
			location: this.props.location,
			partner: this.props.loginStatus === 'LOGGED_IN' ? this.props.currentPartner.selectedPartner : null,
			userDetails: this.props.userDetails,
			currentApp: this.props.app,
			appLink,
			appBase: this.props.app && this.props.app.path,
			pages: this.pages,
			onChangePartner: this.onChangePartner,
			apps: this.props.apps,
			tabNavigationOverride: this.props.tabNavigationOverride,
			navChildren: this.props.navChildren,
			noAppHeader: this.props.noAppHeader,
			showPartnerLogo: !this.props.app || this.props.app.showPartnerLogo,
			appName: this.props.app ? this.props.app.name : '',
		};

		return (
			<main
				id={this.props.app && this.props.app.path}
				className={this.props.app && this.props.app.tableauApp ? 'TableauApp' : appLink}
				data-automation="regionMain"
			>
				<NotificationSystem notifications={this.props.notifications} />
				{!this.props.isTokenExpired && this.props.renderHeader(headerProps)}

				{this.props.pageRoutes &&
					this.props.pageRoutes.map((pageRoute) => (
						<div key={pageRoute.appPath + pageRoute.page.path}>
							<Route
								exact
								path={`/${pageRoute.appPath}/${pageRoute.page.path}`}
								key={pageRoute.appPath + pageRoute.page.path}
								render={(props) => (
									<pageRoute.component
										{...props}
										airline={this.props.currentPartner.selectedPartner}
										partner={this.props.currentPartner.selectedPartner}
										partnerCode={this.props.currentPartner.selectedPartner}
										partnerDetails={this.props.currentPartner.partnerDetails}
										currentPartner={this.props.currentPartner}
										user={this.props.userDetails}
										error={error}
										loginStatus={this.props.loginStatus}
										key={pageRoute.appPath + pageRoute.page.path}
									/>
								)}
								name={pageRoute.page.name}
							/>

							{pageRoute.subpages && this._generateSubpageRoutes(pageRoute.subpages, pageRoute, error)}
						</div>
					))}

				{/*this is redundant and should be unified with upper code*/}
				{this.props.children &&
					React.cloneElement(this.props.children, {
						partner: this.props.currentPartner.selectedPartner,
						airline: this.props.currentPartner.selectedPartner,
						user: this.props.userDetails,
						error,
					})}

				<Footer />
			</main>
		);
	}
}

const mapStateToProps = (state) => ({
	isTokenExpired: state.isTokenExpired,
	userDetails: state.userDetails,
	errorMessage: state.errorMessage,
	currentPartner: state.currentPartner,
	loginStatus: state.loginStatus,
	notifications: state.notifications,
	gotTableauApps: state.gotTableauApps,
	BIATableauAppId: state.BIATableauAppId,
});

const mapDispatchToProps = (dispatch) => ({
	fetchPartnerDetails: (partner) => dispatch(fetchPartnerDetails(partner)),
	closeAll: () => dispatch(menuControlActionCreators.closeAll()),
	triggerRefreshTokenTimer: () => dispatch(authActionCreators.triggerRefreshTokenTimer()),
	setUserPrefs: (key, value) => dispatch(setUserPrefs(key, value)),
	getUserPrefs: () => dispatch(getUserPrefs()),
	getTableauApps: (partner) => dispatch(tableauAppsActionCreators.getApps(partner)),
	getTableauInternalApps: () => dispatch(getInternalApps()),
	getBIATableauAppId: () => dispatch(getBIATableauAppId()),
	saveForcedFromPage: (pathname) => dispatch(authActionCreators.saveForcedFromPage(pathname)),
	localStorageUnavailable: () => dispatch(authActionCreators.localStorageUnavailable()),
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(_AppContainer);

class Container extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<AppContainer {...this.props} />
			</Provider>
		);
	}
}

export default withRouter(Container);
