import React from 'react';
import PropTypes from 'prop-types';
import randomKey from 'lodash.uniqueid';
import LinkWithScrollToTop from '../LinkWithScrollToTop';
import SVGIcon from '../SVGIcon/SVGIcon';

const Sidebar = props => {
	const toggleSidebar = () => {
		props.toggleSidebar(false);
		props.toggleAppMenu(false);
		props.toggleProfileMenu(false);
	};

	return (
		<div
			data-automation="regionSidebar"
			className={`${props.menuControl.isSidebarOpen ? '' : 'h-hidden '}Sidebar js-ignore-global-menu-close`}
		>
			{props.children
				? props.children.map(child => (
						<div key={`nav_child_${randomKey()}`} className="h-clearfix">
							{child}
						</div>
				  ))
				: null}
			{props.items.map(item => {
				let icon;
				if (!item) {
					return null;
				}

				if (item.icon) {
					icon = (
						<SVGIcon
							icon={item.icon}
							wrapperClassName="Sidebar__icon"
							dataAutomation={`regionSidebarIcon_${item.path}`}
							size={25}
						/>
					);
				}

				const url = `/${props.appBase}/${item.path}`;

				return (
					<div
						key={`item_${randomKey()}`}
						onClick={toggleSidebar}
						className={`Sidebar__item h-clearfix ${
							props.location.pathname === url ? 'Sidebar__item--active' : ''
						}`}
						data-automation={`regionSidebarItem_${item.path}`}
					>
						<LinkWithScrollToTop
							data-automation={`actionClickToSection${item.path}`}
							to={url}
							className="Sidebar__link"
						>
							{icon}
							<p className="Sidebar__name">{item.name}</p>
						</LinkWithScrollToTop>
					</div>
				);
			})}
		</div>
	);
};

Sidebar.propTypes = {
	menuControl: PropTypes.shape({
		isSidebarOpen: PropTypes.bool,
	}),
	children: PropTypes.array,
	items: PropTypes.array,
	appBase: PropTypes.string,
	location: PropTypes.object,
	toggleSidebar: PropTypes.func,
	toggleAppMenu: PropTypes.func,
	toggleProfileMenu: PropTypes.func,
};

export default Sidebar;
