import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from './SVGIcon/SVGIcon';

const YesNo = props => (
	<div>
		<div>
			{props.hideCloseButton ? null : (
				<SVGIcon
					icon="CloseIcon"
					wrapperClassName="ButtonClose"
					handleClick={props.onNoCallback}
					aria-label="Close"
					dataAutomation="actionCloseModal"
				/>
			)}
			<h4 data-automation={`textModalYesNoTitle${props.title}`}>{props.title}</h4>
		</div>

		<br />

		<div>{props.children}</div>

		<div className="Modal__footer">
			<button
				type="button"
				className="Button Button--action h-pullRight"
				data-automation="actionReject"
				onClick={props.onNoCallback}
				data-dismiss="modal"
			>
				No
			</button>

			<button
				type="button"
				disabled={props.yesDisabled}
				className="Button Button--text Button--text--underline h-pullRight"
				data-automation="actionConfirm"
				onClick={props.onYesCallback}
				data-dismiss="modal"
			>
				Yes
			</button>
		</div>
	</div>
);

YesNo.propTypes = {
	hideCloseButton: PropTypes.bool,
	onNoCallback: PropTypes.func,
	onYesCallback: PropTypes.func,
	title: PropTypes.string,
	children: PropTypes.node,
	yesDisabled: PropTypes.bool,
};

export default YesNo;
