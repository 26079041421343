/* eslint-disable  */
import { jQueryWithDt as $ } from './SmartTable';

function createButton(name, text, tableId, index) {
	return $(
		`<li id="${tableId}_${name}" class="paginate_button ${name}"><a href="#" aria-controls="${tableId}" data-dt-idx="${index}" tabindex="0">${text}</a></li>`
	);
}

if ($.fn.dataTableExt) {
	$.fn.dataTableExt.oPagination.vague = {
		fnInit(oSettings, nPaging, fnCallbackDraw) {
			const pagination = $('<ul class="pagination"></ul>');

			const nFirst = createButton('first', oSettings.oLanguage.oPaginate.sFirst, oSettings.sTableId, 0).appendTo(
				pagination
			);
			const nPrevious = createButton(
				'previous',
				oSettings.oLanguage.oPaginate.sPrevious,
				oSettings.sTableId,
				1
			).appendTo(pagination);
			const nCurrent = createButton(
				'active',
				oSettings.oAjaxData ? Math.round(oSettings._iDisplayStart / oSettings.oAjaxData.length) + 1 : 1,
				oSettings.sTableId,
				2
			).appendTo(pagination);
			const nNext = createButton('next', oSettings.oLanguage.oPaginate.sNext, oSettings.sTableId, 3).appendTo(
				pagination
			);

			$(nPaging).append(pagination);

			nFirst.click(function(event) {
				event.preventDefault();

				if (!$(this).hasClass('disabled')) {
					oSettings.oApi._fnPageChange(oSettings, 'first');
					fnCallbackDraw(oSettings);
				}
			});

			nPrevious.click(function(event) {
				event.preventDefault();

				if (!$(this).hasClass('disabled')) {
					oSettings.oApi._fnPageChange(oSettings, 'previous');
					fnCallbackDraw(oSettings);
				}
			});

			nCurrent.click(event => {
				event.preventDefault();
			});

			nNext.click(function(event) {
				event.preventDefault();

				if (!$(this).hasClass('disabled')) {
					oSettings.oApi._fnPageChange(oSettings, 'next');
					fnCallbackDraw(oSettings);
				}
			});

			/* Disallow text selection */
			nFirst.bind('selectstart', () => false);
			nPrevious.bind('selectstart', () => false);
			nNext.bind('selectstart', () => false);
		},

		fnUpdate(oSettings, fnCallbackDraw) {
			if (!oSettings.aanFeatures.p) {
				return;
			}

			const length = oSettings.oAjaxData ? oSettings.oAjaxData.length : 0;

			const lastPage = oSettings._iDisplayLength < length;

			/* Loop over each instance of the pager */
			const an = oSettings.aanFeatures.p;
			for (let i = 0, iLen = an.length; i < iLen; i++) {
				const buttons = an[i].getElementsByTagName('li');
				if (oSettings._iDisplayStart === 0) {
					$(buttons[0]).addClass('disabled');
					$(buttons[1]).addClass('disabled');
				} else {
					$(buttons[0]).removeClass('disabled');
					$(buttons[1]).removeClass('disabled');
				}

				$(buttons[2])
					.find('a')
					.text(
						oSettings.oAjaxData ? Math.round(oSettings._iDisplayStart / oSettings.oAjaxData.length) + 1 : 1
					);

				if (lastPage) {
					$(buttons[3]).addClass('disabled');
				} else {
					$(buttons[3]).removeClass('disabled');
				}
			}
		},
	};
}
