import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-combo-modal';

import ComboTable from '../ComboTable/ComboTable';
import SmartButton from '../SmartButton';
import YesNo from '../YesNo';
import dataTableConfigs from './tableConfigTableau';
import AddReportForm from './AddReportForm';

export default class TableauReportTable extends React.Component {
	static propTypes = {
		deleteReport: PropTypes.func.isRequired,
		updateReport: PropTypes.func.isRequired,
		resetAllData: PropTypes.func.isRequired,
		tableauApp: PropTypes.object.isRequired,
		isSaving: PropTypes.bool,
		isDeleting: PropTypes.bool,
		isLoading: PropTypes.bool,
		fetchRoles: PropTypes.func,
		fetchPartnerRoles: PropTypes.func,
		partnerRoles: PropTypes.object,
		internalRoles: PropTypes.object,
		fetchPartnerDetails: PropTypes.func,
		selectedPartnerDetails: PropTypes.object,
		partnerLoading: PropTypes.bool,
		biaApp: PropTypes.bool,
		internal: PropTypes.bool,
		fetchTableauPageList: PropTypes.func,
		availablePages: PropTypes.array,
		availablePagesIsLoading: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			report: {},
			isDeleteReportModalOpen: false,
			isUpdateReportModalOpen: false,
		};
	}

	componentWillUnmount() {
		this.props.resetAllData();
	}

	showDeleteReportDialog = report => {
		this.setState({ report, isDeleteReportModalOpen: true });
	};

	showUpdateReportDialog = report => {
		this.setState({ report, isUpdateReportModalOpen: true });
	};

	checkPendingState = () => this.props.isSaving || this.props.isDeleting;

	_deleteReport = (reportId, appId) => {
		this.props.deleteReport(reportId, appId);
		this.setState({ isDeleteReportModalOpen: false });
	};

	closeUpdateModal = () => this.setState({ isUpdateReportModalOpen: false });

	closeDeleteModal = () => this.setState({ isDeleteReportModalOpen: false });

	render() {
		const buttons = {
			Header: '',
			id: '',
			minWidth: 230,
			Cell: row => (
				<div>
					<SmartButton
						dataAutomation="actionOpenReportEditForm"
						isInPendingState={this.props.isSaving}
						text="Update"
						pendingText="Updating..."
						onClickCallback={() => this.showUpdateReportDialog(row.original)} //?
					/>

					<SmartButton
						dataAutomation="actionOpenDeleteReportConfirmation"
						isInPendingState={this.props.isDeleting}
						text="Delete"
						pendingText="Deleting..."
						className="Button Button--small Button--small--passive h-marginL--xs"
						onClickCallback={() => this.showDeleteReportDialog(row.original)} //?
					/>
				</div>
			),
		};

		const data = {
			data: this.props.tableauApp ? this.props.tableauApp.reports : [],
			isLoading: this.props.isLoading,
		};

		return (
			<div className="container-fluid">
				<Modal open={this.state.isDeleteReportModalOpen} onCloseCallback={this.closeDeleteModal}>
					<YesNo
						onYesCallback={() =>
							this._deleteReport(this.state.report.id, this.state.report.tableau_application_id)
						}
						title="Delete report"
						onNoCallback={this.closeDeleteModal}
					>
						<p>Are you sure you want to delete this report?</p>
					</YesNo>
				</Modal>

				<Modal open={this.state.isUpdateReportModalOpen} onCloseCallback={this.closeUpdateModal}>
					<AddReportForm
						report={this.state.report}
						internal={this.props.internal}
						onCloseCallback={this.closeUpdateModal}
						availablePartners={this.props.tableauApp.partners}
						updateReport={this.props.updateReport}
						isLoading={this.props.isSaving}
						appId={this.props.tableauApp.id}
						fetchRoles={this.props.fetchRoles}
						fetchPartnerRoles={this.props.fetchPartnerRoles}
						partnerRoles={this.props.partnerRoles}
						internalRoles={this.props.internalRoles}
						fetchPartnerDetails={this.props.fetchPartnerDetails}
						selectedPartnerDetails={this.props.selectedPartnerDetails}
						partnerLoading={this.props.partnerLoading}
						editMode
						biaApp={this.props.biaApp}
						fetchTableauPageList={this.props.fetchTableauPageList}
						availablePages={this.props.availablePages}
						availablePagesIsLoading={this.props.availablePagesIsLoading}
					/>
				</Modal>

				<div className="row">
					<div className="col-lg-12">
						<div className="PanelWhite">
							<br />
							<ComboTable
								title="Reports used from Tableau"
								data={data.data}
								loading={data.loading}
								columns={[...dataTableConfigs(this.props.internal), buttons]}
								dataAutomationSuffix="regionBiaManageReportsTable"
								lazyLoadModePageSize={24}
								defaultSorted={[{ id: 'display_name', desc: false }]}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
