import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import setColumnsAdditionalProps from '../util/tableUtils';
import Pagination from './Pagination';
import TreeTableHOC from './TreeTableHOC';

export default class BaseTable extends React.Component {
	static propTypes = {
		loading: PropTypes.bool,
		data: PropTypes.array,
		columns: PropTypes.array.isRequired,
		noDataText: PropTypes.string,
		columnsMinWidth: PropTypes.number,
		showPagination: PropTypes.bool,
		defaultPageSize: PropTypes.number,
		dataAutomationSuffix: PropTypes.string.isRequired,
		rowDataAutomationPrefix: PropTypes.string,
		rowDataAutomationField: PropTypes.string,
		lazyLoadModePageSize: PropTypes.number,
		lazyLoadMode: PropTypes.bool,
		rowHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		getTableProps: PropTypes.func,
		getTheadProps: PropTypes.func,
		getTbodyProps: PropTypes.func,
		getPaginationProps: PropTypes.func,
		PaginationComponent: PropTypes.any,
		getTrProps: PropTypes.func,
		searchText: PropTypes.string,
		ExpanderComponent: PropTypes.func,
		treeTable: PropTypes.bool,
		showEntriesCount: PropTypes.bool,
	};

	static defaultProps = {
		data: [],
		noDataText: 'No data available',
		showPagination: false,
		lazyLoadMode: true,
		lazyLoadModePageSize: 10,
		rowHeight: 39,
		PaginationComponent: Pagination,
		rowDataAutomationField: 'id',
		showEntriesCount: true,
		getTableProps: () => ({}),
		getTheadProps: () => ({}),
		getTrProps: () => ({}),
		getTbodyProps: () => ({}),
		getPaginationProps: () => ({}),
		ExpanderComponent: ({ isExpanded }) => (
			<div className={isExpanded ? 'expandRowMinus' : 'expandRowPlus'}>&bull;</div>
		),
	};

	extendTableProps = props => {
		const tableProps = this.props.getTableProps(props);
		return {
			'data-automation': `regionTableInner_${this.props.dataAutomationSuffix}`,
			style: { overflowX: this.props.showPagination ? 'auto' : 'hidden' },
			...tableProps,
		};
	};

	extendTheadProps = props => {
		const theadProps = this.props.getTheadProps(props);
		return {
			'data-automation': `regionTableHeader_${this.props.dataAutomationSuffix}`,
			...theadProps,
		};
	};

	extendTrProps = (state, rowInfo, column) => {
		const rowDataAutomationField = this.props.rowDataAutomationField.split('.');

		let id;

		/********* Supports only one level deep nesting **********/
		if (rowDataAutomationField.length === 2) {
			id = rowInfo.original ? rowInfo.original[rowDataAutomationField[0]][rowDataAutomationField[1]] || '' : '';
		} else {
			id = rowInfo.original ? rowInfo.original[this.props.rowDataAutomationField] || '' : '';
		}

		const trProps = this.props.getTrProps(state, rowInfo, column);
		const rowDataAutomationPrefix =
			this.props.rowDataAutomationPrefix || `regionTableRow_${this.props.dataAutomationSuffix}`;

		return {
			'data-automation': `${rowDataAutomationPrefix}-${id}`,
			...trProps,
		};
	};

	extendTBodyProps = props => {
		const tbodyProps = this.props.getTbodyProps(props);

		return {
			'data-automation': `regionTableBody_${this.props.dataAutomationSuffix}`,
			style: { overflowX: !this.props.lazyLoadMode && this.props.showPagination ? 'hidden' : 'auto' },
			...tbodyProps,
		};
	};

	extendPaginationProps = props => {
		const paginationProps = this.props.getPaginationProps(props);

		return {
			dataAutomationSuffix: this.props.dataAutomationSuffix,
			...paginationProps,
		};
	};

	render() {
		const {
			loading,
			data,
			columns,
			noDataText,
			showPagination,
			defaultPageSize,
			columnsMinWidth,
			dataAutomationSuffix,
			PaginationComponent,
			searchText,
			treeTable,
			ExpanderComponent,
			showEntriesCount,
			...props
		} = this.props;

		const tableColumns = treeTable
			? columns
			: setColumnsAdditionalProps(columns, dataAutomationSuffix, columnsMinWidth);

		const filtered = tableColumns.reduce((arr, column) => {
			if (column.filterMethod) {
				arr.push({
					id: column.id ? column.id : column.accessor,
					value: searchText.trim(),
				});
			}
			return arr;
		}, []);

		return treeTable ? (
			<TreeTableHOC
				{...this.props}
				getTableProps={this.extendTableProps}
				getTheadProps={this.extendTheadProps}
				getTrProps={this.extendTrProps}
				getTbodyProps={this.extendTBodyProps}
				getPaginationProps={this.extendPaginationProps}
			/>
		) : (
			<ReactTable
				{...props}
				data={data}
				columns={tableColumns}
				showPagination={showPagination}
				minRows={0}
				filterAll
				filtered={filtered.length > 0 ? filtered : undefined}
				defaultPageSize={!showPagination ? 10000 : defaultPageSize}
				loading={loading}
				noDataText={loading ? '' : noDataText}
				PaginationComponent={PaginationComponent}
				getTableProps={this.extendTableProps}
				getTheadProps={this.extendTheadProps}
				getTrProps={this.extendTrProps}
				getTbodyProps={this.extendTBodyProps}
				getPaginationProps={this.extendPaginationProps}
				lazyLoadUniqueID={dataAutomationSuffix}
				className="BaseTable"
			>
				{(state, makeTable, instance) => {
					// you might think this is a hack
					// you are right
					const eventData = {
						tableData: state.sortedData,
						tableID: dataAutomationSuffix,
					};
					const event = new CustomEvent('tableData', { detail: eventData });

					document.dispatchEvent(event);

					return (
						<>
							{makeTable()}
							{showEntriesCount &&
								!showPagination &&
								instance &&
								!loading && (
									<p
										className="ComboTable__itemCount"
										data-automation={`textTableItemCount_${dataAutomationSuffix}`}
									>
										{`Showing ${
											state.resolvedData.length !== 0
												? state.sortedData.length === 0
													? 0
													: 1
												: 0
										} to ${state.sortedData.length} of ${state.resolvedData.length} entries`}
									</p>
								)}
						</>
					);
				}}
			</ReactTable>
		);
	}
}
