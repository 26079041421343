import React from 'react';
import PropTypes from 'prop-types';

const EnableDisable = props => (
	<div className="EnableDisable">
		{!props.noLabel ? (
			<div
				className="EnableDisable__enabledDisabledText"
				data-automation={`textEnableDisableSwitcherDisabledText_${props.dataAutomationSuffix}`}
			>
				{props.isEnabled ? 'Disable' : 'Disabled'}
			</div>
		) : null}

		<div
			className="EnableDisable__switcherInput"
			data-automation={`actionEnableDisableSwitcher_${props.dataAutomationSuffix}`}
			onClick={props.handleSwitch}
		>
			<span
				className={
					props.isEnabled
						? 'EnableDisable__switcherIcon EnableDisable__switcherIcon--enabled'
						: 'EnableDisable__switcherIcon EnableDisable__switcherIcon--disabled'
				}
			/>
		</div>
		{!props.noLabel ? (
			<div
				className="EnableDisable__enabledDisabledText"
				data-automation={`textEnableDisableSwitcherEnabledText_${props.dataAutomationSuffix}`}
			>
				{props.isEnabled ? 'Enabled' : 'Enable'}
			</div>
		) : null}
	</div>
);

EnableDisable.propTypes = {
	isEnabled: PropTypes.bool,
	handleSwitch: PropTypes.func,
	dataAutomationSuffix: PropTypes.string.isRequired,
	noLabel: PropTypes.bool,
};

export default EnableDisable;
