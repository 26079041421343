import { hasPermission } from 'gogo-sphere-shared-util';
import { store } from 'gogo-sphere-shared-redux';

export default function renderWithPermission(permission, element) {
	const user = store.getState().userDetails;

	if (typeof permission === 'function') {
		return user && permission(user.permissions) ? element : null;
	}
	return user && user.permissions && hasPermission(user.permissions, permission) ? element : null;
}
