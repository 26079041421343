import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import Dropzone from 'react-dropzone';

export class _FormsyDropzone extends React.Component {
	static propTypes = {
		value: PropTypes.any,
		setValue: PropTypes.func,
		name: PropTypes.string,
		className: PropTypes.string,
		multiple: PropTypes.bool,
		children: PropTypes.any,
		onDrop: PropTypes.func,
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.value && this.props.value !== nextProps.value) {
			this.props.setValue(nextProps.value);
		}
	}

	render() {
		const { name, className, multiple, children, onDrop } = this.props;

		return (
			<Dropzone onDrop={onDrop} name={name || 'dropzone'} className={className} multiple={multiple}>
				{children}
			</Dropzone>
		);
	}
}

export default withFormsy(_FormsyDropzone);
