import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from './SVGIcon/SVGIcon';

export default class extends React.Component {
	static displayName = 'SmartButton';

	static propTypes = {
		isInPendingState: PropTypes.bool,
		isDisabled: PropTypes.bool,
		onClickCallback: PropTypes.func,
		text: PropTypes.any.isRequired,
		pendingText: PropTypes.any,
		className: PropTypes.string,
		dataAutomation: PropTypes.string.isRequired,
		style: PropTypes.any,
		type: PropTypes.string,
		title: PropTypes.string,
		onMouseOver: PropTypes.func,
	};

	static defaultProps = {
		pendingText: '',
	};

	_generatePendingOperationContent = () => (
		<span className="h-flexCenter">
			<SVGIcon icon="Spinner2" size={25} wrapperClassName="spinAnimation" />
			{this.props.pendingText}
		</span>
	);

	render() {
		const {
			onClickCallback,
			isInPendingState,
			isDisabled,
			text,
			className,
			dataAutomation,
			type,
			title,
			onMouseOver,
			...other
		} = this.props;

		const content = isInPendingState ? this._generatePendingOperationContent() : text;
		const classNameFinal = typeof className !== 'undefined' ? className : 'Button Button--small h-marginL--xs';

		return (
			<button
				style={this.props.style ? this.props.style : {}}
				className={classNameFinal}
				disabled={isInPendingState || isDisabled === true}
				onClick={onClickCallback}
				data-dismiss={other['data-dismiss'] || 'modal'}
				type={type}
				title={title}
				data-tip={other['data-tip']}
				onMouseOver={onMouseOver}
				onFocus={onMouseOver}
				data-automation={dataAutomation}
			>
				{content}
			</button>
		);
	}
}
