export default class Tableau {
	_path = null;
	_selector = null;
	_baseUrl = null;
	options = null;

	_defaultOptions = {
		'Date grain': 'Day',
		scheme: window.protocol,
		user: null,
	};

	containerDiv = null;
	viz = null;

	constructor(path, selector, baseUrl, options = {}) {
		this._path = path || [];
		this._selector = selector;
		this._baseUrl = baseUrl;
		this.options = { ...this._defaultOptions, ...options };
	}

	initViz() {
		let urlParamConnector = '?';

		if (this._path.indexOf(urlParamConnector) >= 1) {
			urlParamConnector = '&';
		}

		this.containerDiv = document.getElementById(this._selector);
		const url = `${this._baseUrl}/${this._path}${urlParamConnector}:embed=y&:customViews=n&:alerts=n&:showShareOptions=false`;
		this.viz = new tableau.Viz(this.containerDiv, url, this.options);
	}

	recreateViz(path) {
		if (this.viz) {
			// If a viz object exists, delete it.
			this.viz.dispose();
		}
		this._path = path;
		this.initViz();
	}
}
