import React from 'react';
import PropTypes from 'prop-types';

export default function(Component, props) {
	return class extends React.Component {
		static propTypes = {
			children: PropTypes.node,
		};

		render() {
			return React.createElement(Component, Object.assign({}, props, this.props), this.props.children);
		}
	};
}
