import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BreadcrumbItem = props => {
	const { className } = props;

	if (props.location) {
		return (
			<Link to={props.location} className={`js-Breadcrumbs ${className || ''}`}>
				<span className="Breadcrumbs__pathName">{props.pathName}</span>
			</Link>
		);
	}
	return (
		<span className={`js-Breadcrumbs ${className || ''}`} onClick={props.goBack}>
			{props.pathName}
		</span>
	);
};

BreadcrumbItem.propTypes = {
	pathName: PropTypes.string,
	location: PropTypes.string,
	className: PropTypes.string,
	goBack: PropTypes.func,
};

export default BreadcrumbItem;
