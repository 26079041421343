import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DateTimeField from './DateTimePicker';
import i18n from '../util/i18n';

export default class DateRangeFilter extends React.PureComponent {
	static propTypes = {
		defaultFrom: PropTypes.object,
		defaultTo: PropTypes.object,
		onChange: PropTypes.func,
		title: PropTypes.string,
		mode: PropTypes.string,
		dateColumnIndex: PropTypes.number,
		dataAutomationSuffix: PropTypes.string,
		dateFormat: PropTypes.string,
		timeFormat: PropTypes.string,
	};

	static defaultProps = {
		defaultTo: moment(),
		defaultFrom: moment().subtract(3, 'months'),
		title: 'Date range',
		mode: 'date',
	};

	constructor(props) {
		super(props);

		this.from = null;
		this.to = null;
	}

	componentDidMount() {
		this._checkDatatablesFiltering(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this._checkDatatablesFiltering(nextProps);
	}

	_checkDatatablesFiltering(props) {
		this.filterDatatables = props.clientSideFilter && props.dateColumnIndex >= 0;
	}

	_getFrom() {
		return this.from ? this.from.getValue() : this.props.defaultFrom;
	}

	_getTo() {
		return this.to ? this.to.getValue() : this.props.defaultTo;
	}

	handleDateRangeChange(datepickerKey, newValue) {
		this.setState(
			{
				[datepickerKey]: newValue,
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange();
				}
			}
		);
	}

	datatablesFilter(settings, row) {
		if (!this.filterDatatables) {
			return true;
		}

		const creationDate = new Date(row[this.props.dateColumnIndex]).getTime();
		return creationDate >= this._getFrom() && creationDate <= this.getTo();
	}

	datatablesFilterServerSide(ajax, data, callback, settings) {
		data.search['date_from'] = this._getFrom();
		data.search['date_to'] = this.getTo();

		return ajax(data, callback, settings);
	}

	render() {
		const dateFormat = typeof this.props.dateFormat !== 'undefined' ? this.props.dateFormat : i18n.getDateFormat();
		const timeFormat = typeof this.props.timeFormat !== 'undefined' ? this.props.timeFormat : i18n.getTimeFormat();

		return (
			<div
				className="DataTableFilterInjectable"
				style={{ float: 'right', marginLeft: '10px', position: 'relative', top: '5px' }}
				data-automation={`regionDateRangePicker${this.props.dataAutomationSuffix}`}
			>
				<div
					className="CheckBoxTitle"
					data-automation={`textDateRangePickerTitle${this.props.dataAutomationSuffix}`}
				>
					{this.props.title}
				</div>

				<div className="h-relative" style={{ marginBottom: 0 }}>
					<DateTimeField
						ref={el => {
							this.from = el;
						}}
						defaultValue={this.props.defaultFrom}
						mode={this.props.mode}
						dateFormat={dateFormat}
						timeFormat={timeFormat}
						onChange={() => this.handleDateRangeChange('dateFrom')}
						dataAutomation={`fieldDateRangePickerFrom${this.props.dataAutomationSuffix}`}
					/>
				</div>

				<div className="h-relative" style={{ marginBottom: 0 }}>
					<DateTimeField
						ref={el => {
							this.to = el;
						}}
						dateTime={this.props.defaultTo}
						mode={this.props.mode}
						dateFormat={dateFormat}
						timeFormat={timeFormat}
						onChange={() => this.handleDateRangeChange('dateTo')}
						dataAutomation={`fieldDateRangePickerTo${this.props.dataAutomationSuffix}`}
					/>
				</div>
			</div>
		);
	}
}
