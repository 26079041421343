import React from 'react';
import PropTypes from 'prop-types';
import withSizeMe from 'react-sizeme';
import isEqual from 'lodash.isequal';

import Breadcrumbs from './Breadcrumbs/Breadcrumbs';

export class _AppHeader extends React.Component {
	static propTypes = {
		apps: PropTypes.array,
		config: PropTypes.object,
		goBack: PropTypes.func,
		paths: PropTypes.array,
		size: PropTypes.object,
		noBreadcrumbsText: PropTypes.string,
		children: PropTypes.node,
	};

	shouldComponentUpdate(nextProps) {
		if (!this.props.paths && !nextProps.paths) {
			return true;
		}
		return !isEqual(this.props, nextProps);
	}

	render() {
		return (
			<div
				className="AppHeader h-paddingLR--lg js-AppHeader"
				ref={el => {
					this.container = el;
				}}
				data-automation="regionAppHeader"
			>
				<h1 className="AppHeader__title" data-automation="textPageHeader" data-tip data-for="bundle">
					{!this.props.noBreadcrumbsText ? (
						<Breadcrumbs
							paths={this.props.paths}
							goBack={this.props.goBack}
							config={this.props.config}
							apps={this.props.apps}
							appHeaderWidth={this.props.size.width - 60}
						/>
					) : (
						this.props.noBreadcrumbsText
					)}
				</h1>
				{this.props.children}
			</div>
		);
	}
}

export default withSizeMe({ noPlaceholder: true })(_AppHeader);
