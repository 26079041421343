import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-combo-modal';

import { getExportData } from 'gogo-sphere-shared-util';

import ExportDialog from '../datatables/ExportTableDialog';

const exportDialogCustomClassNames = {
	background: 'ReactComboModalBackground',
	holder: 'ReactComboModalHolder',
	modal: 'ReactComboModal ReactComboModal--narrow',
};

export default class ExportModal extends React.Component {
	static propTypes = {
		dataAutomationSuffix: PropTypes.string,
		tableColumns: PropTypes.array,
		closeExportModal: PropTypes.func,
		name: PropTypes.string,
		isExportModalOpen: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			tableData: [],
		};
	}

	componentDidMount() {
		document.addEventListener('tableData', this.getData);
	}

	componentWillUnmount() {
		document.removeEventListener('tableData', this.getData);
	}

	getData = event => {
		const { tableData } = event.detail;

		if (this.props.dataAutomationSuffix === event.detail.tableID) {
			this.setState({ tableData });
		}
	};

	render() {
		const { isExportModalOpen, closeExportModal, name, tableColumns } = this.props;

		return (
			<Modal
				open={isExportModalOpen}
				onCloseCallback={closeExportModal}
				customClassNames={exportDialogCustomClassNames}
				closeOnClickOutside
			>
				<ExportDialog
					closeModalCallback={closeExportModal}
					dataForExport={getExportData(this.state.tableData, tableColumns)}
					name={name}
				/>
			</Modal>
		);
	}
}
