import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import ProfileButtons from './ProfileButtons';
import ProfilePartner from './ProfilePartner';
import LinkWithScrollToTop from '../LinkWithScrollToTop';
import SVGIcon from '../SVGIcon/SVGIcon';

const ProfileMenu = props => {
	const ProfileButtonsRouter = withRouter(ProfileButtons);
	return (
		<div data-automation="regionProfileMenu" className="ProfileMenu" role="menu">
			<div data-automation="regionProfileMenuHead" className="ProfileMenu__head">
				<div className="ProfileMenu__airline h-clearfix">
					<ProfilePartner
						disablePartnerSwitcher={props.disablePartnerSwitcher}
						userDetails={props.userDetails}
						onChangePartner={props.onChangePartner}
						switcherData={props.switcherData}
					/>
				</div>
			</div>

			<div
				data-automation="regionProfileMenuBottom"
				className="js-ignore-global-menu-close ProfileMenu__bottomPart"
			>
				{props.showProfileMenu ? <ProfileButtonsRouter userDetails={props.userDetails} /> : ''}

				<div style={{ display: 'none' }} role="presentation">
					<LinkWithScrollToTop to="/logout">
						<SVGIcon icon="SignOut" wrapperClassName="h-marginR--xs" />
						Log out
					</LinkWithScrollToTop>
				</div>
			</div>
		</div>
	);
};

ProfileMenu.propTypes = {
	disablePartnerSwitcher: PropTypes.bool,
	userDetails: PropTypes.object,
	onChangePartner: PropTypes.func,
	showProfileMenu: PropTypes.bool,
	switcherData: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default ProfileMenu;
