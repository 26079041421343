import React from 'react';
import PropTypes from 'prop-types';
import randomKey from 'lodash.uniqueid';

import { selectText } from 'gogo-sphere-shared-util';

export default class OverviewInfoBox extends React.Component {
	static propTypes = {
		data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
		childClassName: PropTypes.string,
		title: PropTypes.string,
		dataAutomation: PropTypes.string,
		innerChildClassName: PropTypes.string,
	};

	selectNodeText = node => {
		selectText(node);
	};

	render() {
		return (
			<div className="OverviewInfo">
				<h4 className={this.props.childClassName ? this.props.childClassName : 'h-displayInline h-marginR--xs'}>
					{this.props.title}:
				</h4>
				<h5
					className="OverviewInfo__text h-displayInline"
					ref={el => {
						this.text = el;
					}}
					onDoubleClick={this.selectNodeText.bind(null, this.text)} // eslint-disable-line react/jsx-no-bind
					data-automation={this.props.dataAutomation}
				>
					{Array.isArray(this.props.data)
						? this.props.data.map(text => (
								<span
									className={`OverviewInfo__textInner ${
										this.props.innerChildClassName ? this.props.innerChildClassName : ' '
									}`}
									key={randomKey()}
								>
									{text}
								</span>
						  ))
						: this.props.data}
				</h5>
			</div>
		);
	}
}
