import React from 'react';
import PropTypes from 'prop-types';

export default function CurryComponent(Component, props) {
	return class CurriedComponent extends React.Component {
		static propTypes = {
			children: PropTypes.node,
		};

		render() {
			return React.createElement(Component, { ...this.props, ...props }, this.props.children);
		}
	};
}
