import React from 'react';
import PropTypes from 'prop-types';
import Notifications from 'react-notification-system-redux';

const NotificationSystem = props => (
	<div className="container-fluid">
		<div className="row" data-automation="regionNotificationWrapper">
			<Notifications notifications={props.notifications} />
		</div>
	</div>
);

NotificationSystem.propTypes = {
	notifications: PropTypes.array,
};

export default NotificationSystem;
