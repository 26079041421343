import React from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';

import { withFormsy } from 'formsy-react';
import moment from 'moment-timezone';

import { store } from 'gogo-sphere-shared-redux';
import DateTimePicker from './DateTimePicker';

class _FormsyDateTimePicker extends React.Component {
	static propTypes = {
		currentPartner: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		dateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		dontFetchAirlineTimezone: PropTypes.bool,
		dontFetchPartnerTimezone: PropTypes.bool,
		getValue: PropTypes.func,
		setValue: PropTypes.func,
		onChange: PropTypes.func,
		selectedTimezone: PropTypes.string,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		readOnly: PropTypes.bool,
		getErrorMessage: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			timeZone: 'UTC',
			partnerDataLoading: true,
		};

		this.updateTimeZone = ::this.updateTimeZone;
		this.changeValue = ::this.changeValue;
	}

	componentWillReceiveProps(nextProps) {
		if (
			(this.props.dateTime && !this.props.dateTime.isSame(nextProps.dateTime)) ||
			(!this.props.dateTime && nextProps.dateTime)
		)
			this.props.setValue(this.transformValue(nextProps.dateTime));
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentPartner && this.props.currentPartner !== prevProps.currentPartner) {
			// some users dont have an airline, causing a page 403 redirect
			if (this.props.dontFetchAirlineTimezone || this.props.dontFetchPartnerTimezone) {
				return;
			}

			this.updateTimeZone();
		}
	}

	updateTimeZone() {
		const partnerData = this.props.currentPartner;

		const stateValue = this.props.getValue();

		const newState = {
			partnerDataLoading: partnerData.isLoading,
		};

		if (!partnerData.isLoading) {
			newState.timeZone = partnerData.data['zone_id'];
		}

		this.setState(newState, () =>
			this.props.setValue(
				this.transformValue(
					stateValue || this.props.dateTime,
					partnerData.data ? partnerData.data['zone_id'] : undefined
				)
			)
		);
	}

	changeValue(value) {
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(value); //we have to call user-supplied change handler first
		}

		this.props.setValue(this.transformValue(value));
	}

	transformValue(value, tz) {
		let val = value;
		if (typeof value === 'string') {
			val = parseInt(value);
		}

		return moment(val).tz(this.props.selectedTimezone || tz || this.state.timeZone);
	}

	render() {
		const {
			className,
			onChange,
			dateTime,
			disabled,
			readOnly,
			setValue,
			getValue,
			getErrorMessage,
			selectedTimezone,
			...other
		} = this.props;

		return (
			<div className={`${className} h-relative`}>
				<DateTimePicker
					{...other}
					value={selectedTimezone ? moment(getValue()).tz(selectedTimezone) : getValue()}
					readOnly
					onChange={this.changeValue}
					disabled={disabled || this.state.partnerDataLoading}
				/>
				<span> {getErrorMessage()}</span>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	currentPartner: state.currentPartner,
});

const FormsyDateTimePickerConnected = connect(mapStateToProps)(_FormsyDateTimePicker);

class FormsyDateTimePicker extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<FormsyDateTimePickerConnected {...this.props} />
			</Provider>
		);
	}
}

export default withFormsy(FormsyDateTimePicker);
