import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { hashHelpers as Base64 } from 'gogo-sphere-shared-util';

import LoadingIndicator from '../LoadingIndicator';
import ReportItem from './ReportListItem';

const ReportsList = props => {
	const navigateToReport = report => {
		const now = moment().format('YYYY-MM-DD');

		props.getReportToken(props.app && props.app.internal ? 'internal' : props.partnerCode, report.name, () => {
			window.location.hash = `${window.location.hash}/report/${report.id}?path=${Base64.encode(
				`${report.path}?PM_Date=${now}`
			)}&reportName=${report.name}`;
		});
	};

	let reportItems = <p>&#34;No reports are created for this user&#34;</p>;

	if (!props.isLoading && props.reports) {
		reportItems = props.reports.map(
			report =>
				props.app && props.app.name && report.tableau_application_id != props.BIATableauAppId ? ( // never strict eq pls // -.-
					<ReportItem
						key={`${report.name}_${report.partner}`}
						imgSrc={report.icon}
						reportName={report.display_name}
						reportDescription={report.description}
						reportData={{ path: report.path, name: report.name, id: report.id }}
						navigateToReport={navigateToReport}
					/>
				) : report.tableau_application_id == props.BIATableauAppId ? ( // never strict eq pls
					<ReportItem
						key={`${report.name}_${report.partner}`}
						imgSrc={report.icon}
						reportName={report.display_name}
						reportDescription={report.description}
						reportData={{ path: report.path, name: report.name, id: report.id }}
						navigateToReport={navigateToReport}
					/>
				) : null
		);
	}

	return (
		<div data-automation="regionCustomReportsPage">
			<div className="container-fluid">
				<div className="row">
					<div
						className="col-md-12 col-lg-12 h-displayFlex h-flexWrap"
						data-automation="regionDisplayCustomReportsArea"
					>
						{props.isLoading ? (
							<div className="h-textCenter h-fullWidth h-marginT--xxl">
								<LoadingIndicator />
							</div>
						) : reportItems.length > 0 ? (
							reportItems
						) : (
							<div className="h-marginT--xxl h-fullWidth h-slogan--md h-textCenter">No Reports Available.</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

ReportsList.propTypes = {
	app: PropTypes.object,
	reports: PropTypes.array.isRequired,
	getReportToken: PropTypes.func,
	isLoading: PropTypes.bool,
	partnerCode: PropTypes.string,
	BIATableauAppId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ReportsList;
