import React from 'react';
import randomKey from 'lodash.uniqueid';

export default (app, userPrefs) => {
	if (!app.navChildren) return;

	app.navChildren.map(navChild =>
		React.createElement(navChild, {
			key: `nav_child_${randomKey()}`,
			app: app.path,
			userPrefs,
		})
	);
};
