import PropTypes from 'prop-types';

import React from 'react';

export default class SimpleStats extends React.Component {
	static displayName = 'SimpleStats';

	static propTypes = {
		title: PropTypes.string,
		columns: PropTypes.number,
		data: PropTypes.array.isRequired,
		buttonText: PropTypes.string,
		buttonAction: PropTypes.func,
		noMargin: PropTypes.bool,
	};

	static defaultProps = {
		title: '',
		columns: 1,
	};

	shouldComponentUpdate(nextProps) {
		return nextProps.data !== this.props.data;
	}

	render() {
		const columnClass = `col-lg-${12 / this.props.columns}`;
		const tablesRow = [];
		let tables = [];
		let smallTitle;

		for (let i = 0, len = this.props.data.length; i < len; i++) {
			const rows = [];

			for (const key in this.props.data[i].data) {
				if (Object.prototype.hasOwnProperty.call(this.props.data[i].data, key)) {
					smallTitle = this.props.data[i].title;
					if (this.props.data[i].isLoading) {
						rows.push(
							<tr key={`table_${i}_row_${key}`}>
								<th className="SimpleStats__th" data-automation={`${smallTitle}-${key} Label`}>
									{key}
								</th>
								<td
									className="SimpleStats__td"
									data-automation={`${smallTitle}-${key} LoadingIndicator`}
								>
									Loading...
								</td>
							</tr>
						);
					} else if (this.props.data[i].data[key] === '') {
						rows.push(
							<tr key={`table_${i}_row_${key}`}>
								<th className="SimpleStats__th" data-automation={`${smallTitle}-${key} Label`}>
									{key}
								</th>
								<td
									className="SimpleStats__td SimpleStats__td--empty"
									data-automation={`${smallTitle}-${key} ValueEmpty`}
								/>
							</tr>
						);
					} else if (this.props.data[i].data[key] === null) {
						rows.push(
							<tr key={`table_${i}_row_${key}`}>
								<th className="SimpleStats__th" data-automation={`${smallTitle}-${key} Label`}>
									{key}
								</th>
								<td
									className="SimpleStats__td"
									data-automation={`${smallTitle}-${key} NoDataAvailable`}
								>
									No data available
								</td>
							</tr>
						);
					} else if (this.props.data[i].data[key] === 'N/A') {
						rows.push(
							<tr key={`table_${i}_row_${key}`}>
								<th className="SimpleStats__th" data-automation={`${smallTitle}-${key} Label`}>
									{key}
								</th>
								<td className="SimpleStats__td" data-automation={`${smallTitle}-${key} NotApplicable`}>
									Not Applicable
								</td>
							</tr>
						);
					} else
						rows.push(
							<tr key={`table_${i}_row_${key}`}>
								<th className="SimpleStats__th" data-automation={`${smallTitle}-${key} Label`}>
									{key}
								</th>
								<td className="SimpleStats__td" data-automation={`${smallTitle}-${key} Value`}>
									{this.props.data[i].data[key]}
								</td>
							</tr>
						);
				}
			}

			// Push columns here
			tables.push(
				<div
					className={`${columnClass} ${!this.props.noMargin ? 'h-marginT--sm' : ''}`}
					key={`table_wrapper_${i}`}
				>
					<div className="h-clearfix">
						<p
							className={this.props.data[i].title ? 'h-marginT--sm h-pullLeft' : 'h-hidden'}
							data-automation={
								this.props.data[i].title ? `${this.props.data[i].title} SimpleStatsTitle` : ''
							}
						>
							{this.props.data[i].title ? this.props.data[i].title : ''}&nbsp;
						</p>
						{this.props.data[i].buttonText &&
							this.props.data[i].buttonAction != undefined && (
								<button
									className="h-marginT--sm h-pullRight Button Button--small"
									onClick={this.props.data[i].buttonAction}
								>
									{this.props.data[i].buttonText}
								</button>
							)}
					</div>

					<table className="SimpleStats__table">
						<tbody>{rows}</tbody>
					</table>
				</div>
			);

			// Push rows here

			if (i >= 0 && (i + 1) % this.props.columns == 0) {
				tablesRow.push(
					<div className="row" key={`tables_wrapper_${i}`}>
						{tables}
					</div>
				);

				tables = [];
			}
		}

		return (
			<div data-automation="regionSimpleStatsPanel" className="SimpleStats">
				{this.props.title && <h4>{this.props.title}&nbsp;</h4>}
				{tablesRow}
			</div>
		);
	}
}
