import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'formsy-react-components';

export default class FormsyFloatingLabelInput extends React.Component {
	static propTypes = {
		onBlur: PropTypes.func,
		onFocus: PropTypes.func,
		labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		rowClassName: PropTypes.string,
		value: PropTypes.string,
		onChange: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			filled: !!props.value,
		};

		this.handleFocus = ::this.handleFocus;
		this.handleBlur = ::this.handleBlur;
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.value !== nextProps.value) {
			this.setState({
				filled: !!nextProps.value,
			});
		}
	}

	handleFocus(event) {
		this.setState(
			{
				filled: true,
			},
			() => {
				if (this.props.onFocus) {
					this.props.onFocus(event);
				}
			}
		);
	}

	handleBlur(event) {
		if (this.props.value) {
			if (this.props.onBlur) {
				this.props.onBlur(event);
			}
		} else {
			this.setState(
				{
					filled: false,
				},
				() => {
					if (this.props.onBlur) {
						this.props.onBlur(event);
					}
				}
			);
		}
	}

	changeValue = (name, value) => this.floatInput.setValue(value.trim());

	render() {
		const { labelClassName, rowClassName, onFocus, onBlur, onChange, ...passableProps } = this.props;

		const labelClasses = [labelClassName, 'FloatingLabel'];

		if (this.state.filled) {
			labelClasses.push('FloatingLabel--floated');
		}

		const rowClasses = [rowClassName, 'h-relative'];

		return (
			<div>
				<Input
					ref={input => {
						this.floatInput = input;
					}}
					{...passableProps}
					spellCheck="false"
					autoCapitalize="off"
					autoCorrect="off"
					labelClassName={labelClasses}
					rowClassName={rowClasses}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					onChange={this.changeValue}
				/>
			</div>
		);
	}
}
