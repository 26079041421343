import PropTypes from 'prop-types';
import React from 'react';
import ComboSelect from 'react-combo-select';
import moment from 'moment-timezone';
import i18n from './util/i18n';

const LOCAL = 'Local';

// const UTC = 'UTC';
// const availableTimezones = {
// 	fig: [{ text: UTC, value: UTC }, { text: LOCAL, value: LOCAL }],
// 	cmp: [
// 		{ text: 'HDN', value: 'America/Denver' },
// 		{ text: 'JST', value: 'Asia/Tokyo' },
// 		{ text: 'US/Central', value: 'America/Chicago' },
// 		{ text: 'US/Mountain', value: 'America/Denver' },
// 		{ text: 'US/Pacific', value: 'America/Los_Angeles' },
// 		{ text: 'US/Eastern', value: 'America/New_York' },
// 		{ text: 'GMT', value: 'UTC' },
// 	],
// };

const addTimezoneOffset = (timedateStringInUTC, offsetInt) => {
	const offsetTimeDate = new Date(timedateStringInUTC);
	offsetTimeDate.setHours(offsetTimeDate.getHours() + offsetInt);
	return offsetTimeDate.toUTCString();
};

const getNumberWithSign = number => (number < 0 ? `${number}` : `+${number}`);

const formatDateTimeWithOffset = (dateTimeStringInUTC, offset) => {
	const offsetInt = parseInt(offset);
	if (Number.isNaN(offsetInt)) return 'Local time unavailable';
	const offsetDateTime = addTimezoneOffset(dateTimeStringInUTC, offsetInt);
	return `${moment(new Date(offsetDateTime))
		.tz('UTC')
		.format('DD MMM YYYY HH:mm:ss')} GMT${getNumberWithSign(offset)}`;
};

class TimezoneSwitcher extends React.PureComponent {
	static formatDateTime(datetime, timezone, localOffset) {
		if (timezone === LOCAL) {
			return formatDateTimeWithOffset(datetime, localOffset);
		}
		return i18n._d(datetime, false, true, timezone);
	}

	static propTypes = {
		availableTimezones: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string,
				value: PropTypes.string,
			})
		).isRequired,
		onChange: PropTypes.func,
		value: PropTypes.string,
	};

	render() {
		return (
			<span className="TimezoneSwitcher" data-automation="fieldCmpTimezoneSwitcher">
				<ComboSelect
					data={this.props.availableTimezones}
					id="timezoneSwitcherSelect"
					name="timezoneSwitcherSelect"
					onChange={this.props.onChange}
					value={this.props.value}
					sort="off"
				/>
			</span>
		);
	}
}

export default TimezoneSwitcher;
