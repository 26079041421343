import React from 'react';
import ReactDOM from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';

class _GlobalClick extends React.Component {
	static propTypes = {
		closeAll: PropTypes.func,
	};

	handleClickOutside = () => {
		this.props.closeAll();
	};

	render() {
		return <div className="GlobalClick" style={{ display: 'none' }} />;
	}
}

export const GlobalClick = onClickOutside(_GlobalClick);

export default class GlobalMenuClick extends React.Component {
	static propTypes = {
		children: PropTypes.any,
	};

	render() {
		return ReactDOM.createPortal(this.props.children, document.querySelector('#app'));
	}
}
