import React from 'react';
import PropTypes from 'prop-types';
import { bugsnag } from 'gogo-sphere-shared-util';
import { authActionCreators, store } from 'gogo-sphere-shared-redux';

export default function withAccessControl(page, hasAccess, pageComponents) {
	return class extends React.Component {
		static propTypes = {
			loginStatus: PropTypes.string,
			history: PropTypes.object,
			user: PropTypes.object,
			permissions: PropTypes.array,
		};

		_authenticatedAndAuthorized() {
			if (this.props.loginStatus !== 'LOGGED_IN') {
				store.dispatch(authActionCreators.saveForcedFromPage(this.props.history.location.pathname));

				this.props.history.push('/login');
				return false;
			}

			const currentUserRoles = this.props.user ? this.props.user.roles : [];
			const permissions = this.props.user ? this.props.user.permissions : [];
			const allowed = hasAccess(currentUserRoles, permissions, page);

			if (!allowed) {
				bugsnag.notify('Access denied without making an API request', {
					metaData: {
						debug: {
							reason: 'Permission check failed',
							permissions: this.props.permissions,
							location: window.location.href,
						},
					},
					severity: 'warning',
					context: 'Client-side 403',
				});

				this.props.history.push('/forbidden');
			}

			return allowed;
		}

		_resolvePageComponent = component => (typeof component === 'string' ? pageComponents[component] : component);

		render() {
			const PageComponent = this._resolvePageComponent(page.component);

			return this._authenticatedAndAuthorized(this.props.history) && <PageComponent {...this.props} />;
		}
	};
}
