import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default function ResizeHOC(WrappedComponent) {
	return class Resize extends React.Component {
		static propTypes = {
			appHeaderWidth: PropTypes.number,
			paths: PropTypes.array,
		};

		constructor(props) {
			super(props);
			this.memorizedContainerWidth = null;
			this.state = {
				showTruncated: false,
			};

			this.breadcrumbs = null;
		}

		componentDidMount = () => {
			window.addEventListener('resize', this.checkWidth);
		};

		componentWillReceiveProps(nextProps) {
			if (this.props.paths && this.props.paths.length !== nextProps.paths.length) {
				this.memorizedContainerWidth = null;
			}
		}

		componentDidUpdate(prevProps) {
			this.checkWidth();
			if (this.props.paths && prevProps.paths && this.props.paths.length !== prevProps.paths.length) {
				this.memorizedContainerWidth = null;
				// eslint-disable-next-line react/no-did-update-set-state
				this.setState({ showTruncated: false });
			}
		}

		componentWillUnmount = () => {
			window.removeEventListener('resize', this.checkWidth);
		};

		checkWidth = () => {
			if (!this.breadcrumbs) {
				return;
			}

			const { appHeaderWidth } = this.props;
			// eslint-disable-next-line react/no-find-dom-node
			const crumbsWidth = ReactDOM.findDOMNode(this.breadcrumbs).offsetWidth;

			if (
				!this.memorizedContainerWidth &&
				crumbsWidth &&
				crumbsWidth >= appHeaderWidth &&
				!this.state.showTruncated
			) {
				this.memorizedContainerWidth = crumbsWidth;

				this.setState({ showTruncated: true });
			}

			if (
				this.memorizedContainerWidth &&
				this.memorizedContainerWidth < appHeaderWidth &&
				this.state.showTruncated
			) {
				this.memorizedContainerWidth = null;
				this.setState({ showTruncated: false });
			}
		};
		render() {
			return (
				<WrappedComponent
					ref={el => {
						this.breadcrumbs = el;
					}}
					showTruncated={this.state.showTruncated}
					{...this.props}
				/>
			);
		}
	};
}
