import React from 'react';
import PropTypes from 'prop-types';

import BaseTable from './BaseTable';
import ExportButton from './ExportButton';
import ExportModal from './ExportModal';

export default class ComboTable extends React.Component {
	static propTypes = {
		data: PropTypes.array,
		exportedFileName: PropTypes.string,
		title: PropTypes.string,
		renderTopContent: PropTypes.func,
		showSearchFilter: PropTypes.bool,
		showExportButton: PropTypes.bool,
		columns: PropTypes.array,
		dataAutomationSuffix: PropTypes.string,
		showTopRow: PropTypes.bool,
	};

	static defaultProps = {
		data: [],
		showSearchFilter: true,
		renderTopContent: () => {},
		showExportButton: true,
		showTopRow: true,
	};

	constructor(props) {
		super(props);

		this.state = {
			searchText: '',
			isExportModalOpen: false,
		};
	}

	handleSearch = event => {
		this.setState({
			searchText: event.target.value,
		});
	};

	openExportModal = () => {
		this.setState({ isExportModalOpen: true });
	};

	closeExportModal = () => {
		this.setState({ isExportModalOpen: false });
	};

	render() {
		const {
			data,
			columns,
			title,
			renderTopContent,
			showSearchFilter,
			exportedFileName,
			dataAutomationSuffix,
			showExportButton,
			showTopRow,
		} = this.props;

		return data ? (
			<div className="ComboTable" data-automation={`regionTableWrapper_${dataAutomationSuffix}`}>
				{showTopRow ? (
					<div className="ComboTable__topContent row">
						<div className="col-md-2 h-marginB--xs h-clearfix">
							{title && <h4 data-automation={`textTableTitle_${dataAutomationSuffix}`}>{title}</h4>}
						</div>
						<div className="col-md-8 h-marginB--xs h-clearfix">{renderTopContent()}</div>
						<div className="col-md-2 h-marginB--xs ComboTable__topContentFilter">
							{showSearchFilter ? (
								<input
									type="search"
									name="data_search"
									className="ComboTable__search InputBox"
									onChange={this.handleSearch}
									value={this.state.searchText}
									data-automation={`fieldComboTableSearch_${dataAutomationSuffix}`}
									placeholder="Filter search..."
								/>
							) : null}
							{showExportButton && (
								<ExportButton
									dataAutomationSuffix={dataAutomationSuffix}
									openExportModal={this.openExportModal}
								/>
							)}
						</div>

						<ExportModal
							closeExportModal={this.closeExportModal}
							isExportModalOpen={this.state.isExportModalOpen}
							name={exportedFileName || title}
							dataAutomationSuffix={dataAutomationSuffix}
							tableColumns={columns}
						/>
					</div>
				) : null}

				<BaseTable searchText={this.state.searchText} {...this.props} />
			</div>
		) : null;
	}
}
