/* eslint-disable  */

/*
 The idea is whenever you need to update datatables from the outside, just pass as props
 what you want to change, for example change sorting, then in the shouldComponentUpdate()
 method call the this.state.datatableApi() with this.props.sorting you got from the top level
 component. All datatables api calls should happen inside shouldComponentUpdate(). This way
 the render() method is called only once when the component is first rendered, after that only
 updates happen from the datatables api inside the shouldComponentUpdate().
 */

import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import $ from 'jquery';
import dt from 'datatables.net';
import dtb from 'datatables.net-buttons';
import dtbh from 'datatables.net-buttons/js/buttons.html5';
import dts from 'datatables.net-scroller';
import Modal from 'react-combo-modal';
import sticky from 'stickyfilljs';

import { grab, InfiniteScroll, setOrAddClass } from 'gogo-sphere-shared-util';

import ExportTableDialog from './ExportTableDialog';
import SmartButton from '../SmartButton';

dt(window, $);
dtb(window, $);
dtbh(window, $);
dts(window, $);

export const jQueryWithDt = $;

export const createSmartTableColumnDef = (title, data, defaultContent = '', optionalFields = {}) => ({
	title,
	data,
	defaultContent,
	...optionalFields,
});

export default class SmartTable extends React.Component {
	static propTypes = {
		dataAutomation: PropTypes.string.isRequired,
		data: PropTypes.object,
		scrollY: PropTypes.string,
		config: PropTypes.shape({
			columns: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string,
				})
			),
		}).isRequired,
		injectables: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
		title: PropTypes.string,
		type: PropTypes.string,
		filters: PropTypes.arrayOf(PropTypes.func),
		search: PropTypes.string,
		openDetailsRows: PropTypes.arrayOf(PropTypes.string),
		onToggleDetails: PropTypes.func,
		selectedRows: PropTypes.arrayOf(PropTypes.string),
		infiniteScroll: PropTypes.bool,
		hideTableOnNoData: PropTypes.bool,
		noDataMessage: PropTypes.string,
		groupRowsBy: PropTypes.string,
		rowGroupLabel: PropTypes.string,
		rowGroupSummary: PropTypes.string,
		rowGroupSummaryFields: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string,
				label: PropTypes.string,
			})
		),
		collapsedGroups: PropTypes.array,
		onGroupToggle: PropTypes.func,
		useScrollerPlugin: PropTypes.bool,
		topLabel: PropTypes.object,
		stickyHeader: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.shape({
				offsetTop: PropTypes.number,
			}),
		]),
		exportTable: PropTypes.func,
		disableScroll: PropTypes.bool,
		// add double x-axis scrollbar if there are 10 or more rows
		horizontalDoubleScroll: PropTypes.bool,
	};

	static defaultProps = {
		config: {},
		data: {
			data: [],
			isLoading: false,
		},
		filters: [],
		openDetailsRows: [],
		selectedRows: [],
		noDataMessage: 'No data available for specified search criteria.',
	};

	state = {
		isExportModalOpen: false,
		tableData: {},
	};

	data = {
		data: [],
		isLoading: false,
	};
	hasReactExportButton = false;
	datatable = null;
	filters = null;
	infiniteScroll = null;
	exportModalHolder = null;
	componentWillMount() {
		this.infiniteScroll = new InfiniteScroll();
		this.filters = [];
		this.onlyDownloadButton = false;

		this.infiniteScroll.setRows(0);

		this.datatable = {
			api: null,
			object: null,
			node: null,
		};

		setTimeout(() => {
			$('.dataTables_processing').html(
				'<span style="font-size: 14px"><i class="fa fa-spinner fa-pulse"> </i> Loading…</span>'
			);
			this.resizeTable();
		}, 1);
	}

	componentDidMount() {
		this.data = this.props.data;
		this.data.error = this.props.error;

		this.columnsBackup = [...this.props.config.columns];

		this.collapsedGroups = this.props.collapsedGroups;

		const self = this;

		const useScroller = !!this.props.useScrollerPlugin;
		const config = {
			bAutoWidth: false,
			bDestroy: true,
			data: this.props.data && this.props.data.data ? this._controlScrollItems(this.props.data) : [],
			dom: useScroller ? 'frti' : 'frtip',
			buttons: ['excel', 'pdf' /* temporarily disabled: 'copy' */],
			bScrollInfinite: true,
			paging: useScroller,
			deferRender: useScroller,
			scroller: useScroller,
			scrollX: true,
			scrollY: this.props.scrollY ? (this.props.scrollY == 'window' ? 0 : this.props.scrollY) : 300,
			scrollCollapse: true,
			language: {
				emptyTable: 'No data available',
			},
			infoCallback: (settings, start, end, max, total, pre) =>
				this._infoCallback(settings, start, end, max, total, pre),
			loading: true,
			processing: true,
			initComplete(settings) {
				if (self.props.infiniteScroll) {
					self._loadMoreDataIntoInfiniteScroll();
				}

				// This is the only way to add spinner to the loading/processing screen
				$('.dataTables_processing').html(
					'<span style="font-size: 14px"><i class="fa fa-spinner fa-pulse"> </i> Loading…</span>'
				);

				const scrollContainer = (self.scrollContainer = $(this).closest('.dataTables_scroll'));

				//remove react-id from the cloned fixed header table because it will be same as the id of main table and react will go nuts
				const headerTable = scrollContainer.find('table:first');
				headerTable.removeAttr('data-reactid');

				//remove padding from header, add it to width, set background color to the same color as fixed header table
				const headerContainer = scrollContainer.find('.dataTables_scrollHeadInner');
				headerContainer.css({
					'background-color': headerTable.find('th:first').css('background-color'),
				});

				self.thead = scrollContainer.find('.dataTables_scrollHead');
				if (self.props.stickyHeader && self.thead.length) {
					self.thead.addClass('sticky');
					if (self.props.stickyHeader.offsetTop) {
						self.thead.css('top', `${self.props.stickyHeader.offsetTop}px`);
						//used to handle IE as when we add style 'top' element will be moved down all the time
						if (navigator.userAgent.includes('Trident')) {
							self.thead.css('margin-bottom', '60px');
						}
					}
					if (self.props.disableScroll) {
						scrollContainer.find('.dataTables_scrollBody').css('overflow-y', 'visible');
					}
					sticky.addOne(self.thead);
				}
			},
			drawCallback(settings) {
				const api =
					self.datatables && self.datatable.api
						? self.datatable.api
						: this.api
							? this.api()
							: new $.fn.dataTable.Api(settings);

				//apply horizontal double scroll if passed as a prop
				if (
					api &&
					api.rows &&
					api.rows({ filter: 'applied' })[0].length > 10 &&
					self.thead &&
					self.thead.length &&
					self.props.horizontalDoubleScroll
				) {
					const scrollBody = self.scrollContainer.find('.dataTables_scrollBody');
					self.thead.css({
						'overflow-y': 'hidden',
						'overflow-x': 'auto',
					});
					self.thead.scroll(() => {
						scrollBody.scrollLeft(self.thead.scrollLeft());
					});
				} else if (self.thead) {
					self.thead.css({
						overflowX: 'hidden',
					});
					self.thead.off('scroll');
				}
				//save search params if needed
				if (api && self.props.saveSearchParameters) {
					self.props.saveSearchParameters({
						search: api.search(),
						order: api.order(),
						page: Math.ceil(settings._iDisplayStart / settings._iDisplayLength) + 1,
						pageLength: settings._iDisplayLength,
					});
				}

				//apply row grouping if needed
				/*if (self.data && self.data.data && self.data.data.length == 0) {
                 setTimeout(function () {
                 rowGrouping();
                 }, 0);
                 }*/

				if (self.props.topLabel && self.data && self.data.data && self.data.data.length > 0) {
					const columns = api.columns();
					const columnNumber = columns && columns[0] && columns[0].length;
					const regularRow = api.rows(':eq(0)', { page: 'current' }).nodes()[0];

					if (regularRow) {
						const $groupHeader = $(
							`<tr class="DataTable__label js-tablelabel" "><td class="h-textLeft" colspan="${columnNumber}"></td></tr>`
						);
						const regularGroupHeader = $groupHeader.get(0);
						const groupHeaderCell = $groupHeader.find('td');
						groupHeaderCell.append(
							`<span class="h-bold">${self.props.topLabel.label}: </span><span>${
								self.props.topLabel.value
							}</span>`
						);
						//IE doesn't like jQuery .before so we use plain JS method (targetElement.insertAdjacentElement(position, element);)
						regularRow.insertAdjacentElement('beforebegin', regularGroupHeader);
					}
				}

				//handle switch between table and placeholder when there's no data for client-side tables
				self._hideTableIfNoData();

				//apply row grouping if needed
				if (self.props.groupRowsBy && self.data && self.data.data && self.data.data.length > 0) {
					rowGrouping();
				}

				function rowGrouping() {
					//const tableRowSelector = $(self.refs.tableContainer).find('.dataTables_scrollBody table tbody tr[role=row]');
					const rows = api.rows({ page: 'current' });

					/*const groupColumnIndex = self.props.config.columns.findIndex(column => column.data == self.props.groupRowsBy);

                    self.datatable.api.order.fixed({pre: [groupColumnIndex, 'ASC']});*/

					/*const tableColumn = $(self.refs.tableContainer).find('table').eq(0).find('th').length;
                     let comparingColumnNumber;

                     $(self.refs.tableContainer).find('table').eq(0).find('th').each(function (i) {
                     if ($(this).text() == self.props.groupRowsBy) {
                     comparingColumnNumber = i;
                     return false;
                     }
                     });*/
					const columnNumber = api.columns()[0].length;

					let lastGroupKey;
					let isLastGroupCollapsed;
					rows.every(function() {
						//const groupKeyData = $(this).find('td').eq(comparingColumnNumber).text();
						const rowData = this.data();
						const groupKey = rowData[self.props.groupRowsBy];
						const $jqRow = $(this.node());
						const regularRow = this.node();

						if (lastGroupKey != groupKey) {
							lastGroupKey = groupKey;
							isLastGroupCollapsed = self.collapsedGroups.indexOf(lastGroupKey) >= 0;

							const $groupHeader = $(
								`<tr class="DataTable__label js-tablelabel" data-key="${groupKey}"><td class="h-textLeft" colspan="${columnNumber}"><i>${
									isLastGroupCollapsed
										? '<span class="expandRowPlus"></span>'
										: '<span class="expandRowMinus"></span>'
								}</i> <span class="h-bold">${self.props.rowGroupLabel ||
									self.props.groupRowsBy}: </span><span>${groupKey}</span></td></tr>`
							);
							const regularGroupHeader = $groupHeader.get(0);

							//this currently supports only summary as object so rowGroupSummaryFields has to exist
							//if support for other summary formats is required, add here and possibly remove rowGroupSummaryFields check
							if (
								self.props.rowGroupSummary &&
								self.props.rowGroupSummaryFields &&
								rowData[self.props.rowGroupSummary]
							) {
								const groupHeaderCell = $groupHeader.find('td');
								self.props.rowGroupSummaryFields.forEach(field => {
									groupHeaderCell.append(
										`<span class="h-bold">${field.label}: </span><span>${
											rowData[self.props.rowGroupSummary][field.key]
										}</span>`
									);
								});
							}

							//IE doesn't like jQuery .before so we use plain JS method (targetElement.insertAdjacentElement(position, element);)
							regularRow &&
								regularRow.insertAdjacentElement &&
								regularRow.insertAdjacentElement('beforebegin', regularGroupHeader);
						}

						if (isLastGroupCollapsed) {
							$jqRow.hide();
						}
					});

					$('.js-tablelabel').click(function(e) {
						const target = $(e.target);
						const openCloseIcon = target.is('i') ? target : target.find('i');
						const openCloseSpan = openCloseIcon.find('span');
						const wasCollapsed = openCloseSpan.hasClass('expandRowPlus');
						const row = $(this);

						row.nextAll().each(function() {
							const thisRow = $(this);

							if (thisRow.hasClass('js-tablelabel')) {
								return false;
							}

							wasCollapsed ? thisRow.show() : thisRow.hide();
						});

						if (wasCollapsed) {
							openCloseSpan.removeClass('expandRowPlus').addClass('expandRowMinus');
						} else {
							openCloseSpan.removeClass('expandRowMinus').addClass('expandRowPlus');
						}

						if (self.props.onGroupToggle) {
							self.props.onGroupToggle(row.data('key'), wasCollapsed);
						}
					});
				}

				self._renderExportButton();

				const bottomButtons = $(api.table().container()).find('.cmpTablePagination .dt-buttons');
				if (bottomButtons.length) {
					if (api.rows().count() < settings._iDisplayLength) {
						bottomButtons.css('visibility', 'hidden');
					} else {
						bottomButtons.css('visibility', 'visible');
					}
				}

				self._renderInjectables();

				//adjust size to new data
				setTimeout(this.resizeTable, 0);
			},
		};

		//restore saved search if provided
		if (this.props.search) {
			if (!config.search) config.search = {};

			config.search.search = this.props.search;
		}

		//add callback to create option cells if needed
		if (this.props.options) {
			//console.log('backing up', this.props.config.columns);
			const options = this.props.options;
			const columns = this.props.config.columns;
			const selectedIDs = this.props.selectedRows;

			config.createdRow = function(row, data) {
				const optionComponents = {};
				const cells = $(row).find('td');
				options.forEach((optionProps, optionIndex) => {
					const position = optionProps.position === undefined ? cells.length - 1 : optionProps.position;
					let component;

					let {
						onClickCallback,
						onConfirm,
						isInPendingState,
						onCheckboxChanged,
						renderCondition,
						disableCondition,
						...other
					} = optionProps;

					if (onConfirm) {
						onConfirm = onConfirm.bind(null, data);
					}

					if (onClickCallback) {
						onClickCallback = onClickCallback.bind(null, data, onConfirm);
					}

					if (optionProps.type === 'checkbox') {
						const bindClickToCell = function(node) {
							const jqNode = $(node);

							jqNode.closest('td').click(event => {
								if (event.target != node) {
									jqNode.click();
								}
							});
						};

						const selectedArguments = optionProps.fetchExtra && optionProps.fetchExtra.map(x => data[x]);
						component =
							!renderCondition || renderCondition(data) ? (
								<input
									key={`checkbox_${data[optionProps.value]}`}
									className={`jsSmartTableRowCheckbox${optionProps.position}`}
									type="checkbox"
									value={data[optionProps.value]}
									id={`checkbox_${data[optionProps.value]}`}
									defaultChecked={
										selectedIDs
											? selectedIDs.indexOf(JSON.stringify(data[optionProps.value])) >= 0
											: false
									}
									disabled={disableCondition && disableCondition(data)}
									onChange={e =>
										selectedArguments
											? onCheckboxChanged(e, ...selectedArguments)
											: onCheckboxChanged(e)
									}
									ref={bindClickToCell}
								/>
							) : (
								undefined
							);
					} else if (optionProps.type === 'custom') {
						component = optionProps.component(data);
					} else {
						component = (
							<SmartButton
								pendingText=""
								{...other}
								isLoading={false}
								onClickCallback={onClickCallback}
								isInPendingState={
									typeof isInPendingState === 'function' ? isInPendingState(data) : isInPendingState
								}
								key={optionIndex}
								dataAutomation="smartTableButton"
							/>
						);
					}

					if (optionComponents[position]) {
						optionComponents[position].push(component);
					} else {
						optionComponents[position] = [component];
					}
				});

				Object.keys(optionComponents).forEach(optionComponentKey => {
					if (optionComponents.hasOwnProperty(optionComponentKey)) {
						const domTarget =
							optionComponentKey !== 'last' ? cells.eq(optionComponentKey).get(0) : cells.last().get(0);

						ReactDOM.render(
							<span className="h-tableCenter CheckBoxGroup" role="group">
								{optionComponents[optionComponentKey]}
								<i className="checkBoxIcon" />
							</span>,
							domTarget
						);
					}
				});
			};
			// Update column headers too.
			let insertedElemWithNoPosition = false;
			options.forEach((optionProps, optionIndex) => {
				const position = undefined || optionProps.position;
				if (position !== undefined) {
					columns.splice(position, 0, {
						title: optionProps.title,
						class: optionProps.class,
						data: () => '',
						orderable: false,
					});
				} else if (!insertedElemWithNoPosition) {
					insertedElemWithNoPosition = true;
					columns.push({ title: '', data: () => '', orderable: false });
				}
				if (optionProps.disableCondition) {
					const oldCheck = this.enableDisableCheckboxes;
					this.enableDisableCheckboxes = _ => {
						this.datatable.api.rows().every(function() {
							const data = this.data();
							$(this.node())
								.find(`.jsSmartTableRowCheckbox${optionProps.position}`)
								.prop('disabled', optionProps.disableCondition(data));
							if (oldCheck) {
								oldCheck();
							}
						});
					};
				}
			});
		}

		const table = $(this.refs.dataTable);

		//console.log('merging columns', config.columns, this.props.config.columns);
		const configFinal = { ...config, ...this.props.config };

		if (this.props.initialPage && configFinal.pageLength) {
			configFinal.displayStart = (this.props.initialPage - 1) * configFinal.pageLength;
		}

		//add button class remover callback to all buttons if buttons are rendered
		if (this.props.config.dom && this.props.config.dom.indexOf('B') >= -1) {
			this._addButtonsClassRemover(configFinal.buttons);
		}

		//merge draw callbacks
		if (this.props.config.drawCallback) {
			configFinal.drawCallback = function(settings) {
				self.props.config.drawCallback(settings);
				config.drawCallback(settings);
			};
		}

		//add custom filtering functions
		if (configFinal.serverSide) {
			const ajax = configFinal.ajax;

			//HERE BE DRAGONS: since filter components are detected in their ref callback, only once they are mounted, we have to build the ajax function chain and run it as part of the ajax function
			configFinal.ajax = (data, callback, settings) => {
				const filters = [...this.filters, ...this.props.filters];
				let middlewarizedAjax = ajax;

				for (let i = 0, len = filters.length; i < len; i++) {
					middlewarizedAjax = filters[i].bind(null, middlewarizedAjax);
				}

				//this is similar to redux/express middlewares, but "next" is the first parameters and is called ajax
				//each filter will do its work, then call the next filter, the final "filter" being the original datatbles config ajax function
				return middlewarizedAjax(data, callback, settings);
			};
		} else {
			const filters = [...this.filters, ...this.props.filters];
			for (let i = 0, len = filters.length; i < len; i++) {
				$.fn.dataTable.ext.search.push(this._wrapFilter(table, filters[i]));
			}
		}

		const datatableObj = table.empty().DataTable(configFinal);

		this.datatable.object = table.dataTable();

		this.datatable.api = datatableObj;

		this.datatable.node = this.refs.dataTable;

		$(window).resize(() => {
			datatableObj.columns.adjust();
		});

		if (this.props.rowDetails != null) {
			this._processRowDetails(this.props.rowDetails, datatableObj);
		}

		if (!this.props.hideTableOnNoData && this.data.isLoading) {
			$(this.refs.tableContainer)
				.find('.dataTables_processing')
				.show();
		}

		this._renderExportButton();

		this._renderExportModal(this.state);

		//injectables start
		const injectablesArray =
			this.props.injectables instanceof Array
				? this.props.injectables
				: this.props.injectables
					? [this.props.injectables]
					: [];

		const filterContainer = $(this.refs.tableContainer).find('.dataTables_filter');

		this.filters = [];

		this.injectableHolders = injectablesArray.map((injectable, index) => {
			const holder = $(`<div id="smart-table-injectable-holder-${index}"></div>`);

			if (injectable.target) {
				holder.appendTo(`#${injectable.target}`);
			} else {
				let defaultTarget = $(this.refs.tableContainer).find('.jsTableTitle');

				if (!defaultTarget.length) {
					holder.addClass('h-displayInline');
					defaultTarget = $(this.refs.tableContainer).find('.dt-buttons:first');
				}

				holder.insertAfter(defaultTarget);
			}

			return holder;
		});

		this._renderInjectables();
		//injectables end

		// data-automation start
		const currentTable = $(`div[data-automation=${this.props.dataAutomation}]`);

		const innerTableHeaderHolder = $(currentTable.find('table')[0]);
		innerTableHeaderHolder.attr('data-automation', `table-${this.props.dataAutomation}-inner-header-holder`);

		const innerTableHeaders = innerTableHeaderHolder.find('th');
		innerTableHeaders.map((index, item) => {
			$(item).attr('data-automation', `table-${this.props.dataAutomation}-inner-header-${index}`);
		});

		const innerTableContentHolder = $(currentTable.find('table')[1]).attr(
			'data-automation',
			`table-${this.props.dataAutomation}-inner-content-holder`
		);

		const resultsPerPage = currentTable
			.find('.DataTablesLengthMenu')
			.attr('data-automation', `table-${this.props.dataAutomation}-results-per-page`);

		const searchInput = currentTable
			.find('.dataTables_filter')
			.attr('data-automation', `table-${this.props.dataAutomation}-search-input`);

		const pagination = currentTable
			.find('.dataTables_paginate')
			.attr('data-automation', `table-${this.props.dataAutomation}-pagination`);

		const cmpReqRefunds = currentTable
			.find('#cmpTableFilters')
			.find('.Button--action')
			.attr('data-automation', `table-${this.props.dataAutomation}-request-refunds`);

		const cmpBottomExportsDropdown = currentTable
			.find('.cmpTablePagination')
			.find('.dt-buttons')
			.attr('data-automation', `table-${this.props.dataAutomation}-bottom-exports-dropdown`);

		const topExportsDropdown = currentTable
			.find('.dt-buttons')
			.filter(':first')
			.attr('data-automation', `table-${this.props.dataAutomation}-top-exports-dropdown`);

		const topExportsButton = currentTable
			.find('.ExportButton')
			.attr('data-automation', `table-${this.props.dataAutomation}-top-exports-button`);

		const totalResults = currentTable
			.find('.dataTables_info')
			.attr('data-automation', `table-${this.props.dataAutomation}-total-results`);
		// data-automation end

		setTimeout(this.resizeTable, 0);
	}

	shouldComponentUpdate(nextProps, nextState) {
		//please think 30 times before introducing a case where this returns true
		//if you don't know what you're doing, don't do it
		//if you think you know what you're doing, think again
		//leaving the commented-out code below for posterity, as an example of a minor feature that caused a lot of pain

		/*if (nextState.isExportModalOpen != this.state.isExportModalOpen) {
            return true;
        }*/

		if (nextProps.collapsedGroups != this.collapsedGroups) {
			this.collapsedGroups = nextProps.collapsedGroups;
		}

		//if modal state changed, we rerender it directly
		if (nextState.isExportModalOpen != this.state.isExportModalOpen) {
			this._renderExportModal(nextState);
		}

		//if loading flag or data changed, we update it and update the table, these are the only props that can change
		if (
			this.data.isLoading != nextProps.data.isLoading ||
			this.data.data != nextProps.data.data ||
			this.data.error != nextProps.error
		) {
			this.data = nextProps.data;
			this.data.error = nextProps.error;

			this._updateTable(nextProps);
		}
		if (this.props.topLabel && nextProps.topLabel && this.props.topLabel.value !== nextProps.topLabel.value) {
			this._updateTopLabel(nextProps.topLabel.value);
		}

		//we always prevent render, with Fiber, we absolutely cannot allow this to rerender
		return false;
	}
	_updateTopLabel(newTopLabelValue) {
		const $labelHeader = $('.js-tablelabel');
		const $labelItem = $labelHeader ? $labelHeader.find('span:nth-child(2)') : null;
		$labelItem && $labelItem.text(newTopLabelValue);
	}

	_updateTable(props) {
		if (props.data.isLoading) {
			if (!props.hideTableOnNoData) {
				$(this.refs.tableContainer)
					.find('.dataTables_processing')
					.show();
			}
		} else {
			if (!props.hideTableOnNoData) {
				$(this.refs.tableContainer)
					.find('.dataTables_processing')
					.hide();
			}

			this.datatable.api.clear();
			this.infiniteScroll.setRows(0);
			if (props.data) {
				this.datatable.api.rows.add(this._controlScrollItems(props.data));
			}
			this.datatable.api.draw();
			if (props.data) {
				this._controlNumberOfItemsText(props.data.data ? props.data.data.length : 0);
			}

			const selectedIDs = props.selectedRows;
			if (selectedIDs) {
				selectedIDs.forEach(selectedID => {
					const elem = document.getElementById(`checkbox_${selectedID}`);
					if (elem) {
						elem.checked = true;
					}
				});
			}

			if (props.config.orderFixed) {
				this.datatable.api.order.fixed({ pre: props.config.orderFixed });
			}

			setTimeout(this.resizeTable, 0);
		}

		//handle switch between table and placeholder when there's no data for client-side tables
		this._hideTableIfNoData();

		this._renderExportButton();

		this._renderInjectables();
	}

	_renderInjectables() {
		const injectablesArray =
			this.props.injectables instanceof Array
				? this.props.injectables
				: this.props.injectables
					? [this.props.injectables]
					: [];

		injectablesArray.forEach((injectable, index) => {
			let injectableElement = null;

			if (React.isValidElement(injectable)) {
				const newProps = {
					key: `${this.props.dataAutomation}-injectable-${index}`,
					datatable: this.datatable,
				};

				injectableElement = React.cloneElement(injectable, newProps);
			} else if (injectable.component) {
				injectable.props.ref = component => {
					//HERE BE DRAGONS: we have to detect copmonent methods because they are wrapped in redux connect, and we can only get wrapped components once they are instantiated
					if (component) {
						if (this.props.config.serverSide) {
							const datatablesFilterServerSide =
								component.datatablesFilterServerSide ||
								(component.getWrappedInstance
									? component.getWrappedInstance().datatablesFilterServerSide
									: undefined);

							if (datatablesFilterServerSide) {
								this.filters.push(datatablesFilterServerSide);
							}
						} else {
							const datatablesFilter =
								component.datatablesFilter ||
								(component.getWrappedInstance
									? component.getWrappedInstance().datatablesFilter
									: undefined);

							if (datatablesFilter) {
								this.filters.push(datatablesFilter);
							}
						}
					}
				};

				injectable.props.key = `${this.props.dataAutomation}-injectable-${index}`;
				injectable.props.datatable = this.datatable;

				injectableElement = React.createElement(injectable.component, injectable.props);
			}

			if (this.injectableHolders && this.injectableHolders[index]) {
				ReactDOM.render(injectableElement, this.injectableHolders[index].get(0));
			}
		});
	}

	_hideTableIfNoData() {
		const api = this.datatable.api;

		if (api && this.props.hideTableOnNoData && this.data && !this.props.config.ajax) {
			const container = $(api.table().container());
			const noData = $(this.refs.noData);
			const loadingIndicator = $(this.refs.loading);
			const table = container.find('.dataTables_scroll');
			const buttons = api.buttons().containers();
			const errorMessage = $(this.refs.errorMessage);

			if (this.data.data && this.data.data.length && !this.data.isLoading) {
				container.show();
				noData.hide();
				loadingIndicator.hide();

				if (api.page.info() && api.page.info().recordsDisplay) {
					table.show();
					container
						.find('.dataTables_paginate')
						.parent()
						.show();
					container.find('.dataTables_info').show();
					buttons.css('visibility', 'visible');
				} else {
					table.hide();
					container
						.find('.dataTables_paginate')
						.parent()
						.hide();
					container.find('.dataTables_info').hide();
					buttons.css('visibility', 'hidden');
					noData.show();
				}
			} else {
				container.hide();

				if (this.data.isLoading) {
					noData.hide();
					errorMessage.hide();
					loadingIndicator.show();
				} else if (this.data.error) {
					errorMessage.show();
					loadingIndicator.hide();
				} else {
					loadingIndicator.hide();
					noData.show();
				}
			}
		}
	}

	componentWillUnmount() {
		this.filters = [];
		// Make a copy of buttons property because datatables will destroy it (reeee)
		if (this.props.config.buttons) {
			if (this.props.config.buttons instanceof Array) {
				this.props.config.buttons = [...this.props.config.buttons];
			} else if (this.props.config.buttons.buttons) {
				this.props.config.buttons.buttons = [...this.props.config.buttons.buttons];
			}
		}

		if (this.datatable && this.datatable.api) {
			this.datatable.api.destroy();
		}

		this.refs.dataTable && this.refs.dataTable.innerHTML ? (this.refs.dataTable.innerHTML = '') : null;

		/*if (this.props.options)
         this.props.config.columns.pop();*/
		if (this.columnsBackup) {
			//console.log('restoring backup', this.columnsBackup);
			this.props.config.columns = this.columnsBackup;
			//console.log('backup restored', this.props.config.columns);
		}

		if (this.exportModalHolder) {
			ReactDOM.unmountComponentAtNode(this.exportModalHolder);
		}

		if (this.props.config.orderFixed) {
			this.datatable.api.order.fixed(this.props.config.orderFixed);
		}

		self.thead && self.thead.off('scroll');
	}

	_renderExportModal = state => {
		if (this.exportModalHolder) {
			const api = this.datatable.api;

			// pulling data from table and mapping it to a proper structure
			const exportData = api.buttons.exportData();

			const exportHeader = {};
			exportData.header.forEach((item, i) => (exportHeader[i] = item));
			const exportBody = exportData.body.map(
				row => (row ? row.map((item, i) => ({ ...row, [i]: item }))[0] : {})
			);

			const dataForExport = {
				columns: exportHeader,
				data: exportBody,
			};

			ReactDOM.render(
				<Modal
					open={state.isExportModalOpen}
					onCloseCallback={() => this.setState({ isExportModalOpen: false })}
					customClassNames={{
						modal: 'ReactComboModal ReactComboModal--narrow',
					}}
				>
					<ExportTableDialog
						dataForExport={dataForExport}
						name={this.props.exportTableFileName || this.props.title}
						closeModalCallback={() => this.setState({ isExportModalOpen: false })}
						table={this.datatable.object}
						size="modal-sm"
					/>
				</Modal>,
				this.exportModalHolder
			);
		}
	};

	/*********************************
	 * Infinite scroll logic start
	 ********************************/

	_controlNumberOfItemsText = numberOfItems => {
		if (this.props.infiniteScroll) {
			this.infiniteScroll.controlNumberOfItemsText(
				numberOfItems,
				$(this.refs.dataTable)
					.parents('.dataTables_wrapper')
					.find('.dataTables_info')
			);
		}
	};

	_loadMoreDataIntoInfiniteScroll = () => {
		const table = $(this.refs.dataTable);
		const container = table.parents('.dataTables_scrollBody');

		this.infiniteScroll.loadMoreDataIntoInfiniteScroll(container, table, () => {
			const data = this._controlScrollItems(this.props.data);

			if (data && data.length > 0) {
				this.datatable.api.rows.add(data);
				this.datatable.api.draw(false);
				this._controlNumberOfItemsText(
					this.props.data && this.props.data.data ? this.props.data.data.length : 0
				);
			}
		});
	};

	_getTableHeight = () => {
		const table = $(this.refs.dataTable);

		if (this.props.heightByRows) {
			const heightOfRows = this._getNRowsHeights(this.props.heightByRows) + 20;
			const returnedHeight = heightOfRows > 300 ? heightOfRows : 300;
			table
				.closest('.dataTables_scrollBody')
				.css('max-height', this.props.disableScroll ? returnedHeight * 2 : returnedHeight + 10);
			return returnedHeight;
		}

		const hasScrollX = typeof this.props.config.scrollX === 'undefined' || this.props.config.scrollX;
		const hasScrollY =
			(typeof this.props.config.scrollY === 'undefined' && this.props.scrollY == 'undefined') ||
			this.props.config.scrollY;

		let height = 0;
		if (this.props.scrollY == 'window') {
			const tableHeight = $(this.refs.tableContainer).height();
			let totalHeight = tableHeight ? 160 : 20;

			$('main')
				.children()
				.each(function() {
					totalHeight += $(this).height();
				});

			height = $(window).height() - totalHeight + (tableHeight || 0);

			if (height < 300) {
				height = 300;
			}
		} else if (table.height() < this.props.config.scrollY && !(hasScrollX && !hasScrollY)) {
			height = table.height() + 2;
		} else {
			height = this.props.config.scrollY
				? this.props.config.scrollY
				: this.props.scrollY
					? parseInt(this.props.scrollY.replace('px', ''))
					: 300;
		}

		return height;
	};

	_getRowHeight = () =>
		$(this.refs.dataTable)
			.find('tbody td')
			.height() + 8;

	_getNRowsHeights = numberOfRows => {
		let aggregateHeight = 0;
		$(this.refs.dataTable)
			.find('tbody tr')
			.slice(0, numberOfRows)
			.each(function() {
				aggregateHeight += $(this).outerHeight();
			});

		return aggregateHeight;
	};

	_controlScrollItems = data => {
		if (!data.data) {
			return data;
		} else if (!this.props.infiniteScroll) {
			return data.data;
		}

		const tableHeight = this._getTableHeight();
		const rowHeight = this._getRowHeight();

		return this.infiniteScroll.controlScrollItems(data, tableHeight, rowHeight);
	};

	/*********************************
	 * Infinite scroll logic end
	 ********************************/
	_preDrawCallback = settings => {
		// control flow before drawing
	};

	_fnDrawCallback = settings => {
		// control flow after drawing
		this._controlNumberOfItemsText(this.props.data && this.props.data.data ? this.props.data.data.length : 0);

		if (this.datatable.node) {
			const exportButton = $(this.datatable.node)
				.closest('.js-chartlegend')
				.find('.js-chartexport');
			const numberOfResults = settings.aiDisplay.length > 0;
			numberOfResults ? exportButton.show() : exportButton.hide();
		}
	};

	_infoCallback = (settings, start, end, max, total, pre) => {
		let infoText = pre;
		if (isNaN(end) || isNaN(max) || isNaN(total)) {
			infoText = '';
		}

		return infoText;
	};

	resizeTable = () => {
		const table = $(this.refs.dataTable);
		const scrollWrapper = table.closest('.dataTables_scrollBody');
		if (!this.props.disableScroll) {
			scrollWrapper.height(this._getTableHeight());
		} else {
			scrollWrapper.css({ height: '', 'max-height': '' });
		}
		setTimeout(_ => {
			table.resize();
		}, 0);
	};

	/* Formatting DataTables Row details */
	_formatRowDetails = (data, rowDetailsProps) => {
		// Allow user to customize format of the row details
		let rowDetails = '';
		const displayType = rowDetailsProps.displayType; // "list"
		const objectPath = rowDetailsProps.objectPath; // "flight_event.attributes"
		const results = grab(data, objectPath);

		if (displayType == 'component') {
			rowDetails = $(ReactDOMServer.renderToString(rowDetailsProps.component(data)));
		} else if (displayType == 'components') {
			rowDetails = $(
				rowDetailsProps
					.component(data)
					.reduce((details, component) => details + ReactDOMServer.renderToString(component), '')
			);
		} else if (results) {
			if (displayType == 'list') {
				for (const property in results) {
					if (results[property]) {
						rowDetails += `<strong>${property.replace(/\_/g, ' ')}</strong>: ${
							!results[property] ? '/' : results[property]
						}</br>`;
					}
				}
			}
		} else {
			rowDetails = 'Unable to display details.'; // path is probably wrong as there's either none or more than 1 objects on a given path
		}

		return rowDetails;
	};

	/* Handling DataTables Row details */
	_processRowDetails = (rowDetailsProps, datatableObj) => {
		const detailRows = [...this.props.openDetailsRows];
		const table = this;

		$('tbody').on('click', 'tr td.DataTable__detailsControl', function() {
			const tr = $(this).closest('tr');
			const row = datatableObj.row(tr);
			const id = tr.attr('id');
			const idx = $.inArray(id, detailRows);

			const isVisibleBeforeToggle = row.child.isShown();

			if (isVisibleBeforeToggle) {
				tr.removeClass('DataTable__details');
				row.child.hide();

				// Remove from the 'open' array
				detailRows.splice(idx, 1);
			} else {
				table._showRowDetails(tr, row, rowDetailsProps);

				table.resizeTable();

				// Add to the 'open' array
				if (idx === -1) {
					detailRows.push(id);
				}
			}

			if (table.props.onToggleDetails) {
				table.props.onToggleDetails(id, !isVisibleBeforeToggle);
			}
		});

		// On each draw, loop over the `detailRows` array and show any child rows
		datatableObj.on('draw', () => {
			detailRows.forEach(id => {
				const tr = $(`#${id}`);
				const row = datatableObj.row(tr);
				table._showRowDetails(tr, row, rowDetailsProps);
			});

			table.resizeTable();
		});
	};

	_showRowDetails = (tr, row, props) => {
		tr.addClass('DataTable__details');
		row.child(this._formatRowDetails(row.data(), props)).show();
		if (typeof props.callback === 'function') {
			props.callback(row.data(), tr);
		}
	};

	_wrapFilter = (table, filter) =>
		function(settings, data, dataIndex) {
			if (settings.nTable !== table.get(0)) {
				return true;
			}

			return filter(settings, data, dataIndex);
		};

	_addButtonsClassRemover = buttons => {
		const buttonsArray = buttons instanceof Array ? buttons : buttons.buttons;

		buttonsArray.forEach((button, index) => {
			if (typeof button === 'string') {
				button = {
					extend: button,
				};

				buttons[index] = button;
			}

			button.init = function(dt, node) {
				node.removeClass('dt-button');
			};

			if (!button.className) {
				button.className = 'Button Button--active';
			}

			if (button.buttons) {
				this._addButtonsClassRemover(button.buttons);
			}
		});
	};

	draw = () => {
		this.datatable.api.draw();
		this.resizeTable();
	};

	getSearchValue = () =>
		$(this.refs.dataTable)
			.closest('.dataTables_wrapper')
			.find('input[type="search"]')
			.val();

	_renderExportButton = () => {
		const hasButtons =
			typeof this.props.config.buttons === 'undefined' ||
			(this.props.config.buttons && this.props.config.buttons.length);

		if (this.props.config.buttons && this.props.config.buttons.length === 1) {
			this.onlyDownloadButton = true;
		}
		//const hasData = this.props.config.ajax || (!!this.data.data && this.data.data.length && !this.data.isLoading));
		const hasData = this.datatable.api && this.datatable.api.page.info().recordsDisplay > 0 && !this.data.isLoading;

		const hasSeparateButtons = this.props.config.dom && this.props.config.dom.indexOf('B') >= 0;
		this.hasReactExportButton = hasButtons && !hasSeparateButtons;

		this.hasReactExportButton &&
			ReactDOM.render(
				<div
					id={`${this.props.type}Table`}
					className={`ExportButton fa fa-download h-marginT--xs ${!hasData && 'ExportButton--disabled'}`}
					onClick={() => {
						if (hasData) {
							if (this.onlyDownloadButton) {
								this.datatable.object
									.DataTable()
									.buttons(0, 0)
									.trigger();
							} else {
								this.setState({ isExportModalOpen: true });
							}
						}
					}}
				>
					&nbsp;
				</div>,
				this.exportBtnContainer
			);
	};

	render() {
		if (this.props.title) {
			var title = (
				<h4 className="h-leftTitle jsTableTitle" data-automation={`textPanelTitle-${this.props.title}`}>
					{this.props.title}
				</h4>
			);
		}

		return (
			<div data-automation={this.props.dataAutomation} className="ChartLegend" ref="tableContainer">
				{title}

				<div ref={r => (this.exportModalHolder = r)} />

				<div>
					<div ref={r => (this.exportBtnContainer = r)} />

					<table
						ref="dataTable"
						className={`DataTable DataTable--stripped DataTable--bordered ${this.props.className}`}
						cellSpacing="0"
						onClick={this.props.onClick}
					>
						{this.props.children}
					</table>
				</div>

				<div className="h-textCenter">
					<p ref="noData" style={{ display: 'none', margin: 0 }}>
						{this.props.noDataMessage}
					</p>
					<p ref="errorMessage" style={{ display: 'none', color: '#e80029', margin: 0 }}>
						{this.data.error || 'Error on XMLHttp request'}
					</p>
					<p
						ref="loading"
						style={{
							display: 'none',
							margin: 0,
							backgroundColor: 'rgba(0, 0, 0, 0.1)',
						}}
					>
						<span style={{ fontSize: '14px' }} data-automation="SmartTableLoadingIndicator">
							<i className="fa fa-spinner fa-pulse" /> Loading…
						</span>
					</p>
				</div>
			</div>
		);
	}
}
