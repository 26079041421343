import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';

import CheckboxRadioItem from './CheckboxRadioItem';

class FormsyCheckboxRadioGrouping extends React.Component {
	static propTypes = {
		type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
		options: PropTypes.array.isRequired,
		dataAutomationSuffix: PropTypes.string.isRequired,
		defaultChecked: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
		wrapperClassName: PropTypes.string,
		wrapperValidationMessage: PropTypes.string,
		labelClassName: PropTypes.string,
		iconClassName: PropTypes.string,
		spanLabelClassName: PropTypes.string,
		uncheckedIconClassName: PropTypes.string,
		refReference: PropTypes.string,
		validationError: PropTypes.string,
		onChange: PropTypes.func,
		setValue: PropTypes.func,
		getErrorMessage: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.checkedItemsState = ::this.checkedItemsState;
		this.state = {
			checkedItems: this.checkedItemsState(),
		};
	}

	//if item should be checked, set value also
	componentDidMount() {
		if (this.props.defaultChecked) this.props.setValue(this.state.checkedItems);
	}

	onChange = e => {
		// checkbox will return OBJECT/ARRAY on change
		if (this.props.type === 'checkbox') {
			//if clicked item is in state (it was already checked before) than remove it (uncheck it)
			if (this.state.checkedItems.includes(e.target.value)) {
				const mutatedState = [...this.state.checkedItems];
				mutatedState.splice(mutatedState.indexOf(e.target.value), 1);

				this.setState(
					{ checkedItems: mutatedState },
					//callback for formsy .setValue
					() => {
						this.props.setValue(this.state.checkedItems);
					}
				);
			} else {
				//if clicked item was not checked before than add it to state
				this.setState(
					{ checkedItems: [...this.state.checkedItems, e.target.value] },
					//callback for formsy .setValue
					() => {
						this.props.setValue(this.state.checkedItems);
					}
				);
			}
			// radio will return STRING on change
		} else {
			this.setState(
				{ checkedItems: e.target.value },
				//callback for formsy .setValue
				() => {
					this.props.setValue(this.state.checkedItems);
				}
			);
		}

		//fire onChange in parent component and send clicked item value
		return this.props.onChange && this.props.onChange(e.target.value);
	};

	checkedItemsState = () => {
		if (this.props.defaultChecked) {
			if (this.props.type === 'checkbox') {
				if (typeof this.props.defaultChecked === 'object') {
					if (this.props.defaultChecked.includes(undefined)) {
						return [];
					}
					return this.props.defaultChecked;
				}
				return [this.props.defaultChecked];
			}
			return this.props.defaultChecked;
		}
		return [];
	};

	render() {
		return (
			<div className={this.props.wrapperClassName}>
				{this.props.options.map(item => (
					<CheckboxRadioItem
						key={`${this.props.dataAutomationSuffix}-${item.value}`}
						type={this.props.type}
						dataAutomationSuffix={`${this.props.dataAutomationSuffix}${item.value}`}
						label={item.label}
						checked={
							this.props.type === 'checkbox'
								? this.state.checkedItems.includes(item.value)
								: this.state.checkedItems === item.value
						}
						onChange={this.onChange}
						value={item.value}
						labelClassName={this.props.labelClassName}
						iconClassName={this.props.iconClassName}
						uncheckedIconClassName={this.props.uncheckedIconClassName}
						spanLabelClassName={this.props.spanLabelClassName}
						disabled={item.disabled ? item.disabled : false}
						refReference={this.props.refReference}
					/>
				))}
				{/*NOTE FOR ERRORS: checkbox will return OBJECT/ARRAY and radio will return STRING as value*/}
				{this.props.getErrorMessage() != null && (
					<span className={this.props.wrapperValidationMessage}>
						<span className="help-block validation-message">{this.props.validationError}</span>
					</span>
				)}
			</div>
		);
	}
}

export default withFormsy(FormsyCheckboxRadioGrouping);
