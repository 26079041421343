import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../SVGIcon/SVGIcon';

const SidebarButton = props => {
	const toggleSidebar = event => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		props.toggleSidebar(!props.menuControl.isSidebarOpen);
		props.toggleAppMenu(false);
		props.toggleProfileMenu(false);
	};

	return props.pages === undefined || props.pages.length < 2 ? (
		<div />
	) : (
		<div data-automation="regionSidebar" className="js-ignore-global-menu-close SidebarExpandBtn-button">
			<a
				className={`SidebarExpandBtn h-pullLeft ${props.menuControl.isSidebarOpen ? 'open' : ''}`}
				href="#"
				data-automation="actionClickSidebarToggle"
				onClick={toggleSidebar}
			>
				{props.menuControl.isSidebarOpen ? (
					<SVGIcon icon="CloseIcoBold" size={20} />
				) : (
					<SVGIcon icon="Bars" size={20} />
				)}
			</a>
		</div>
	);
};

SidebarButton.propTypes = {
	pages: PropTypes.array,
	menuControl: PropTypes.shape({
		isSidebarOpen: PropTypes.bool,
	}),
	toggleSidebar: PropTypes.func,
	toggleAppMenu: PropTypes.func,
	toggleProfileMenu: PropTypes.func,
};

export default SidebarButton;
