/* eslint-disable  */
// TODO: Fix this
import React from 'react';
import moment from 'moment-timezone';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { store } from 'gogo-sphere-shared-redux';
import { timezonesIDs } from 'gogo-sphere-shared-util';

const IntlPolyfill = require('intl');
require('intl/locale-data/jsonp/en.js');
require('intl/locale-data/jsonp/en-US.js');

if (!global.Intl) {
	require('intl');
} else {
	Intl.NumberFormat = IntlPolyfill.NumberFormat;
	Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
}

const defaultOptions = { timeZone: 'UTC' };

export let dateFormat = new Intl.DateTimeFormat(store.getState().language.locale, defaultOptions);

let _currentLocale = 'en-US';

const validTimezones = [];

const niceDateFormat = new Intl.DateTimeFormat(store.getState().language.locale, {
	month: 'long',
	day: 'numeric',
	year: 'numeric',
}); // To avoid creation/destroying per table row

/*const cache = {};

 let hit = 0;
 let total = 0;*/

const i18n = {
	_text(msgId) {
		const locale = store.getState().language.messages.messages;
		return locale[msgId];
	},
	_t(id, description, defaultMessage) {
		return <FormattedMessage id={id} description={description} defaultMessage={defaultMessage} />;
	},

	_d(originalDate, plain = false, time = false, useTimezone = null) {
		const data = store.getState().currentPartner;
		if (
			data.partnerDetails &&
			data.partnerDetails['locales'] &&
			data.partnerDetails['locales'].length > 0 &&
			data.partnerDetails['locales'][0].code !== _currentLocale
		) {
			_currentLocale = data.partnerDetails['locales'][0].code.replace('_', '-');
			dateFormat = new Intl.DateTimeFormat(_currentLocale, defaultOptions);
		}

		//parse date string if it's a string
		if (typeof originalDate === 'string') {
			let concatWith = '';

			//if time zone is not specified, add UTC
			if (originalDate.indexOf('Z') < 0 && originalDate.indexOf('T') < 0) {
				concatWith = ' UTC';
			}

			var date = Date.parse(originalDate + concatWith);

			//if native parse failed, check if it is mysql timestamp and parse manually
			if (Number.isNaN(date)) {
				const dateSplit = originalDate.split(/[- :\.]/);

				if (dateSplit.length >= 3 || dateSplit.length <= 7) {
					const dateObject = new Date(
						Date.UTC(
							dateSplit[0],
							dateSplit[1] - 1,
							dateSplit[2],
							dateSplit[3] ? dateSplit[3] : 0,
							dateSplit[4] ? dateSplit[4] : 0,
							dateSplit[5] ? dateSplit[5] : 0
						)
					);
					date = dateObject.getTime();
				}
			}
		} else var date = originalDate;

		if (date) {
			if (time) {
				const mm = moment;
				/*const dateTimeFormat = new Intl.DateTimeFormat(_currentLocale, {
                 year: 'numeric',
                 month: '2-digit',
                 day: '2-digit',
                 hour: '2-digit',
                 minute: '2-digit',
                 second: '2-digit'
                 });*/
				//var offset = localeToTimezoneOffsetMapping[_currentLocale] || 0;
				//var formattedDate = dateTimeFormat.format(date + offset * 60 * 1000);
				const data = store.getState().currentPartner;
				let timeZone = 'UTC';

				if (!data.isLoading && data.partnerDetails && data.partnerDetails['zone_id']) {
					if (validTimezones.indexOf(data.partnerDetails['zone_id']) > -1) {
						timeZone = data.partnerDetails['zone_id'];
					} else if (timezonesIDs.indexOf(data.partnerDetails['zone_id']) > -1) {
						timeZone = data.partnerDetails['zone_id'];
						validTimezones.push(data.partnerDetails['zone_id']);
					}
				}

				if (
					!data.isLoading &&
					data.partnerDetails &&
					data.partnerDetails['zone_id'] &&
					timezonesIDs.indexOf(data.partnerDetails['zone_id']) > -1
				) {
					timeZone = data.partnerDetails['zone_id'];
				}

				var formattedDate = null;
				const format = 'DD MMM YYYY HH:mm:ss zz';

				try {
					formattedDate = moment(date)
						.tz(useTimezone || timeZone || 'UTC')
						.format(format);
				} catch (err) {
					formattedDate = moment(date)
						.tz('UTC')
						.format(format);
				}
			} else {
				var formattedDate = moment(date)
					.tz('UTC')
					.format('DD MMM YYYY');
			}

			if (true || plain) return formattedDate;

			return <FormattedDate value={formattedDate} />;
		}

		// In case that date is not convertible, like 'Loading...'
		return originalDate;
	},

	_parse(originalDate) {
		if (dateFormat.v8Parse) return dateFormat.v8Parse(originalDate);
		return new Date(`${originalDate} UTC`);
	},

	niceDate(date) {
		// date is expected to be in UTC format.
		const parsedDate = Date.parse(date);
		return niceDateFormat.format(parsedDate);
	},

	getFormat() {
		// returns format mm/dd/yyyy or dd/mm/yyyy based on curent locale
		const x = new Date();
		x.setMonth(11); // December
		x.setDate(31);
		let format;
		const parsed = this._d(x, true);
		if (parsed.substring(0, 2) == '31') {
			format = 'DD/MM/YYYY';
		} else if (parsed.substring(0, 2) == '12') {
			format = 'MM/DD/YYYY';
		}
		return format;
	},

	getDateFormat() {
		return 'DD MMM YYYY';
	},

	getTimeFormat() {
		return 'HH:mm:ss zz';
	},
};
/*
 var airlineToTimezoneMapping = {
 'JAL': 'Japan',
 'ACA': 'America/Toronto',
 'ASA': 'US/Alaska',
 'DAL': 'US/Eastern',
 'UAL': 'US/Central',
 'AAL': 'US/Central',
 'AMX': 'America/Mexico_City',
 'VRD': 'US/Pacific',
 'HVN': 'Asia/Vientiane'
 }
 */

export default i18n;
