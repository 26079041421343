import React from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import randomKey from 'lodash.uniqueid';

import { store, passwordActionCreators } from 'gogo-sphere-shared-redux';
import { validatePassword } from 'gogo-sphere-shared-util';

import PasswordStrength from './PasswordStrength';
import SmartButton from '../SmartButton';
import SVGIcon from '../SVGIcon/SVGIcon';

class _ChangePassword extends React.Component {
	static propTypes = {
		passwordChangeDone: PropTypes.bool,
		isPasswordChanging: PropTypes.bool,
		resetPasswordStatus: PropTypes.func,
		closeCallback: PropTypes.func,
		changePassword: PropTypes.func,
		errorMessage: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.state = {
			error: false,
			oldPassword: '',
			newPassword: '',
			newPasswordConfirmation: '',
		};
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.passwordChangeDone && this.props.passwordChangeDone) {
			this.props.resetPasswordStatus();
			this.props.closeCallback();
		}

		if (
			prevProps.errorMessage !== this.props.errorMessage ||
			prevProps.isPasswordChanging !== this.props.isPasswordChanging
		) {
			this.getErrors();
		}
	}

	getErrors = () => {
		const errorObj = this.props.errorMessage;
		let errorMessage = null;
		let errorsDescription = null;

		if (errorObj) {
			errorMessage = errorObj.message || errorObj.error;
			errorsDescription = errorObj.errors ? errorObj.errors.filter(err => err.message !== errorMessage) : [];
		}

		if (!this.props.passwordChangeDone) {
			this.setState({
				error: errorMessage,
				errorsDescription,
			});
		}
	};

	save = event => {
		event.preventDefault();

		if (this.state.isPasswordValid) {
			this.setState({
				errorsDescription: null,
			});

			if (this.state.oldPassword === this.state.newPassword) {
				this.setState({
					error: 'Current password is the same as the new password',
				});
			} else if (this.state.newPassword !== this.state.newPasswordConfirmation) {
				this.setState({
					error: 'Passwords do not match.',
				});
			} else {
				this.props.changePassword(this.state.oldPassword, this.state.newPassword);
			}
		}
	};

	handlePasswordChange = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});

		if (event.target.name === 'newPassword') {
			const { errors } = validatePassword(event.target.value);
			const { isPasswordValid } = validatePassword(event.target.value);

			this.setState({
				passwordStrengthErrors: isPasswordValid ? {} : errors,
				isPasswordValid,
				errorsDescription: !isPasswordValid ? false : this.state.errorsDescription,
				error: !isPasswordValid ? false : this.state.error,
			});
		}
	};

	render() {
		return (
			<div data-automation="regionChangePasswordDialog">
				<div>
					<form id="changePasswordForm" onSubmit={this.save}>
						<div>
							<div className="h-marginB--md">
								<SVGIcon
									icon="CloseIcon"
									wrapperClassName="ButtonClose"
									handleClick={e => {
										e.preventDefault();
										if (this.props.closeCallback) {
											this.props.closeCallback();
										}
									}}
									aria-label="Close"
									dataAutomation="actionCloseChangePasswordDialog"
								/>
								<h4 className="h-noMargin">Change password</h4>
							</div>

							<div>
								<div className="h-textCenter">
									<span className="h-errorColor" data-automation="textChangePasswordErrorMessage">
										{this.state.error}
									</span>
								</div>
								{!this.state.isPasswordValid &&
								this.state.passwordStrengthErrors &&
								this.state.newPassword.length > 0 ? (
									<div className="h-marginB--xxl">
										<PasswordStrength errors={this.state.passwordStrengthErrors} showHeading />
									</div>
								) : null}
								{this.state.errorsDescription
									? this.state.errorsDescription.map(ed => (
											<div className="h-textCenter h-marginB--lg" key={`error${randomKey()}`}>
												<span
													className="h-errorColor"
													data-automation="textChangePasswordErrorMessage"
												>
													{ed.message || ed}
												</span>
											</div>
									  ))
									: null}
								<div className="h-marginB--md">
									<label>*Current Password</label>
									<input
										type="password"
										onChange={this.handlePasswordChange}
										value={this.state.oldPassword}
										className="InputBox"
										name="oldPassword"
										placeholder="Please enter current password"
										autoComplete="off"
										required
										data-automation="oldPasswordField"
									/>
								</div>

								<div className="h-marginB--md">
									<label>*New Password</label>
									<input
										type="password"
										name="newPassword"
										className="InputBox"
										onChange={this.handlePasswordChange}
										value={this.state.newPassword}
										placeholder="Please enter new password"
										autoComplete="off"
										required
										data-automation="newPasswordField"
									/>
								</div>

								<div className="h-marginB--md">
									<label>*Confirm New Password</label>
									<input
										type="password"
										name="newPasswordConfirmation"
										className="InputBox"
										onChange={this.handlePasswordChange}
										value={this.state.newPasswordConfirmation}
										placeholder="Please reenter new password"
										autoComplete="off"
										required
										data-automation="newPasswordFieldConfirmation"
									/>
								</div>
							</div>

							<div>
								<button
									type="button"
									className="Button Button--text Button--text--underline h-fullWidth"
									data-automation="fieldButtonCancel"
									onClick={e => {
										e.preventDefault();
										if (this.props.closeCallback) {
											this.props.closeCallback();
										}
									}}
								>
									Cancel
								</button>

								<SmartButton
									type="submit"
									className="Button Button--action Button--action h-fullWidth"
									formNoValidate
									isInPendingState={this.props.isPasswordChanging}
									isDisabled={!this.state.isPasswordValid}
									text="Save"
									pendingText="Saving..."
									dataAutomation="actionButtonSavePassword"
									defaultValue="Save"
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

export const ChangePasswordModalMock = _ChangePassword;

const mapStateToProps = state => ({
	errorMessage: state.passwordStatus.errorMessage,
	isPasswordChanging: state.passwordStatus.isPasswordChanging,
	passwordChangeDone: state.passwordStatus.passwordChangeDone,
});

const mapDispatchToProps = dispatch => ({
	changePassword: (currentPassword, newPassword) =>
		dispatch(passwordActionCreators.changePassword(currentPassword, newPassword)),
	resetPasswordStatus: () => dispatch(passwordActionCreators.resetPasswordStatus()),
});

const ChangePassword = connect(
	mapStateToProps,
	mapDispatchToProps
)(_ChangePassword);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<ChangePassword {...this.props} />
			</Provider>
		);
	}
}
