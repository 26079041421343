import React from 'react';

const Footer = () => (
	<footer className="AppFooter" data-automation="regionFooter">
		<p className="h-textCenter" data-automation="textPageFooter">
			<small>
				© {new Date().getFullYear()} Intelsat. All trademarks are the property of their respective owners.
			</small>
		</p>
	</footer>
);

export default Footer;
