import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import withSizeMe from 'react-sizeme';
import ResizeHOC from './ResizeHOC';
import crumbsBuilderWithFilePaths from './crumbsBuilderWithFilePaths';
import crumbsBuilderWithRoutes from './crumbsBuilderWithRoutes';

const tooltipProps = {
	id: 'breadcrumb-tooltip',
	place: 'bottom',
	className: 'breadcrumbsTooltip',
};

const removeFirstCharIfExists = (word, char) => (word && word.charAt(0) === char ? word.slice(1) : word);

const delimiter = (
	<span className="Breadcrumbs__ellipsis" key="crumb-delimiter">
		...
	</span>
);

export class _Breadcrumbs extends React.Component {
	static propTypes = {
		location: PropTypes.shape({
			pathname: PropTypes.string,
		}),
		paths: PropTypes.array,
		showTruncated: PropTypes.bool,
	};

	render() {
		const { props } = this;
		const { paths, showTruncated } = props;
		const tooltipItem = { text: '' };
		const routesAsString = props.location && props.location.pathname;
		const routes = routesAsString ? removeFirstCharIfExists(routesAsString, '/').split('/') : [];
		let breadcrumbs = [];

		if (routes.length && !paths && props.apps) {
			for (const application of props.apps) {
				if (routes[0] === application.path) {
					crumbsBuilderWithRoutes(breadcrumbs, props, routes, [application], showTruncated, tooltipItem);
					break;
				}
			}
		} else if (paths) {
			// this is used for crumbs without routes
			// it is currently used only on FS
			crumbsBuilderWithFilePaths(paths, breadcrumbs, tooltipItem, showTruncated, props);
		}

		if (showTruncated && breadcrumbs.length > 2) {
			breadcrumbs = [breadcrumbs[0], delimiter, breadcrumbs[breadcrumbs.length - 1]];
		}
		return (
			<div className="BreadcrumbsWrapper">
				<div data-for="breadcrumb-tooltip" data-tip className="Breadcrumbs">
					{breadcrumbs}
				</div>
				{showTruncated && (
					<ReactTooltip key="tooltip" {...tooltipProps}>
						{tooltipItem.text}
					</ReactTooltip>
				)}
			</div>
		);
	}
}

export default withRouter(withSizeMe()(ResizeHOC(_Breadcrumbs)));
