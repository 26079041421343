import React from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { withRouter } from 'react-router';
import sizeMe from 'react-sizeme';

import { store, menuControlActionCreators, fetchPartners } from 'gogo-sphere-shared-redux';
import { transformGroupData } from 'gogo-sphere-shared-util';

import Sidebar from './Sidebar';
import SidebarButton from './SidebarButton';
import SwitchApp from './SwitchApp';
import Profile from './Profile';
import TabMenu from './TabMenu';
import AppHeader from '../AppHeader';
import HeaderLogo from './HeaderLogo';
import GlobalMenuClick, { GlobalClick } from './GlobalMenuClick';

class _Header extends React.Component {
	static propTypes = {
		currentApp: PropTypes.shape({
			header: PropTypes.shape({
				alignHeaderLeft: PropTypes.bool,
			}),
		}),
		appBase: PropTypes.string,
		appLink: PropTypes.string.isRequired,
		appName: PropTypes.string,
		apps: PropTypes.arrayOf(PropTypes.object).isRequired,
		closeAll: PropTypes.func,
		menuControl: PropTypes.object,
		navChildren: PropTypes.arrayOf(PropTypes.element),
		noAppHeader: PropTypes.bool,
		noSidebar: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
		onChangePartner: PropTypes.func,
		pages: PropTypes.arrayOf(PropTypes.object).isRequired,
		partner: PropTypes.string,
		partners: PropTypes.array,
		showPartnerLogo: PropTypes.bool,
		tabNavigationOverride: PropTypes.bool,
		tabNavMinWidth: PropTypes.number,
		toggleAppMenu: PropTypes.func,
		toggleProfileMenu: PropTypes.func,
		toggleSidebar: PropTypes.func,
		userDetails: PropTypes.object,
		currentPartner: PropTypes.shape({
			isLoading: PropTypes.bool,
			partnerDetails: PropTypes.object,
		}),
		size: PropTypes.shape({
			width: PropTypes.number,
		}),
		fetchPartners: PropTypes.func,
		partnersLoading: PropTypes.bool,
	};

	static defaultProps = {
		noSidebar: false,
		partner: null,
		tabNavMinWidth: 1024,
		currentApp: {},
	};

	state = {
		switcherData: [],
	};

	componentDidMount() {
		if (
			this.props.userDetails.partner_code === '*' &&
			this.props.partners.length === 0 &&
			!this.props.partnersLoading
		) {
			this.props.fetchPartners();
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.transformed = false;
		if (
			!this.transformed ||
			nextProps.userDetails.partner_code !== this.props.userDetails.partner_code ||
			this.props.partners.length !== nextProps.partners.length
		) {
			this.transformed = true;
			const switcherData =
				this.props.userDetails.partner_code === '*'
					? transformGroupData(this.props.partners, true)
					: this.props.userDetails.partner_code;

			this.setState({ switcherData });
		}
	}

	render() {
		const hasTabNav = this.props.tabNavigationOverride && this.props.size.width > this.props.tabNavMinWidth;
		const RouterSidebar = withRouter(Sidebar);

		const nav = hasTabNav ? (
			<TabMenu appBase={this.props.appBase} items={this.props.pages} appName={this.props.appName}>
				{this.props.navChildren}
			</TabMenu>
		) : (
			<RouterSidebar
				appBase={this.props.appBase}
				items={this.props.pages}
				menuControl={this.props.menuControl}
				toggleSidebar={this.props.toggleSidebar}
				toggleAppMenu={this.props.toggleSidebar}
				toggleProfileMenu={this.props.toggleSidebar}
			>
				{this.props.navChildren}
			</RouterSidebar>
		);

		const appHeader = hasTabNav || this.props.noAppHeader ? null : <AppHeader apps={this.props.apps} />;
		const appHeaderClassName =
			this.props.currentApp.header && this.props.currentApp.header.alignHeaderLeft
				? 'Header__logos Header__logos--pullLeft'
				: 'Header__logos';

		return (
			<div>
				<GlobalMenuClick>
					<GlobalClick closeAll={this.props.closeAll} outsideClickIgnoreClass="js-ignore-global-menu-close" />
				</GlobalMenuClick>

				<header className="h-paddingB--push" data-automation="regionHeader">
					<div className="Header h-clearfix js-header">
						{hasTabNav ? null : (
							<SidebarButton
								pages={this.props.pages}
								menuControl={this.props.menuControl}
								toggleSidebar={this.props.toggleSidebar}
								toggleAppMenu={this.props.toggleAppMenu}
								toggleProfileMenu={this.props.toggleProfileMenu}
							/>
						)}

						<div data-automation="regionLogos" className={appHeaderClassName}>
							<a data-automation="actionLogo" className="h-pullLeft Header__ggsLogo" href="/" />
							<HeaderLogo
								currentApp={this.props.currentApp}
								currentPartner={this.props.currentPartner}
								showPartnerLogo={this.props.showPartnerLogo}
							/>
						</div>

						<div className="h-pullRight">
							<SwitchApp
								appLink={this.props.appLink}
								apps={this.props.apps}
								user={this.props.userDetails}
								partner={this.props.partner}
								isAppMenuOpen={this.props.menuControl.isAppMenuOpen}
								toggleSidebar={this.props.toggleSidebar}
								toggleAppMenu={this.props.toggleAppMenu}
								toggleProfileMenu={this.props.toggleProfileMenu}
							/>
							<Profile
								appBase={this.props.appBase}
								disablePartnerSwitcher={this.props.menuControl.isAirlineSwitcherDisabled}
								isProfileMenuOpen={this.props.menuControl.isProfileMenuOpen}
								onChangePartner={this.props.onChangePartner}
								partner={this.props.partner}
								switcherData={this.state.switcherData}
								toggleAppMenu={this.props.toggleAppMenu}
								toggleProfileMenu={this.props.toggleProfileMenu}
								toggleSidebar={this.props.toggleSidebar}
								userDetails={this.props.userDetails}
							/>
						</div>
					</div>
				</header>

				{nav}
				{appHeader}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	currentPartner: state.currentPartner,
	menuControl: state.menuControl,
	partners: state.partners.partners,
	partnersLoading: state.partners.inProgress,
});

const mapDispatchToProps = dispatch => ({
	closeAll: () => dispatch(menuControlActionCreators.closeAll()),
	toggleSidebar: isSidebarOpen => dispatch(menuControlActionCreators.toggleSidebar(isSidebarOpen)),
	toggleAppMenu: isAppMenuOpen => dispatch(menuControlActionCreators.toggleAppMenu(isAppMenuOpen)),
	toggleProfileMenu: isProfileMenuOpen => dispatch(menuControlActionCreators.toggleProfileMenu(isProfileMenuOpen)),
	fetchPartners: () => dispatch(fetchPartners()),
});

const Header = withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(sizeMe()(_Header))
);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<Header {...this.props} />
			</Provider>
		);
	}
}
