import React from 'react';
import Loadable from 'react-loadable';

import MainSpinner from '../MainSpinner';

export default function withLoadable(loader) {
	return Loadable({
		loader,
		loading: MainSpinner,
		render(loaded, props) {
			const Component = loaded.default || loaded;

			return <Component {...props} />;
		},
	});
}
