import React from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { Input, Textarea } from 'formsy-react-components';
import ComboSelect from 'react-formsy-combo-select';
import Dropzone from 'react-dropzone';

import { store as sharedStore } from 'gogo-sphere-shared-redux';

import SmartButton from '../SmartButton';
import SVGIcon from '../SVGIcon/SVGIcon';
import CheckboxRadioItem from '../form/CheckboxRadioItem';

const tblDefaultAdminRoles = ['ROLE_GOGO_SUPER_ADMIN', 'ROLE_GOGO_TABLEAU_ADMIN']

export default class AddReportForm extends React.Component {
	static propTypes = {
		editMode: PropTypes.bool,
		updateReport: PropTypes.func,
		createReport: PropTypes.func,
		report: PropTypes.object,
		partnerRoles: PropTypes.object,
		internalRoles: PropTypes.object,
		internal: PropTypes.bool,
		onCloseCallback: PropTypes.func,
		availablePartners: PropTypes.array,
		isLoading: PropTypes.bool,
		partnerLoading: PropTypes.bool,
		fetchRoles: PropTypes.func,
		selectedPartnerDetails: PropTypes.object,
		fetchPartnerRoles: PropTypes.func,
		fetchPartnerDetails: PropTypes.func,
		fetchTableauPageList: PropTypes.func,
		appId: PropTypes.string,
		biaApp: PropTypes.bool,
		availablePages: PropTypes.array,
		availablePagesIsLoading: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isFormValid: false,
			icon: null,
			uploadError: undefined,
			reportName: this.props.editMode ? this.props.report.display_name : undefined,
			reportDesc: this.props.editMode ? this.props.report.description : undefined,
			reportPath: this.props.editMode ? this.props.report.path || '' : undefined,
			selectedPartner: this.props.editMode ? this.props.report.partner : undefined,
			selectedPartnerRoles: this.props.editMode
				? this.props.report.partner_roles.map(role => role.role_name)
				: [],
			selectedInternalRoles: this.props.editMode
				? this.props.report.internal_roles.map(role => role.role_name)
				: [],
			isPageTblPartnerSection: this.props.editMode ? this.props.report.custom_report || false : false,
			selectedPage: this.props.editMode ? this.props.report.selected_page : undefined,
		};
	}

	componentDidMount() {
		this.props.fetchRoles({ internal_only: true });

		if (this.props.selectedPartnerDetails?.partner_id && !this.props.internal) {
			this.props.fetchPartnerRoles(this.props.selectedPartnerDetails.partner_id);
		}

		if (this.state.selectedPartner) {
			this.props.fetchPartnerDetails(this.state.selectedPartner);
		}

		if(this.props.editMode && this.state.isPageTblPartnerSection) {
			this.props.fetchTableauPageList(this.state.selectedPartner);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!this.props.internal && nextProps.selectedPartnerDetails?.partner_id && this.props.selectedPartnerDetails?.partner_id !== nextProps.selectedPartnerDetails?.partner_id) {
			this.props.fetchPartnerRoles(nextProps.selectedPartnerDetails?.partner_id);
		}
	}

	onSubmit = data => {
		if (this.state.icon) {
			// api expects base64 format
			const reader = new FileReader();
			reader.readAsDataURL(this.state.icon);

			reader.onload = () => {
				this.handleSubmit(data, reader);
			};
		} else {
			this.handleSubmit(data);
		}
		this.props.onCloseCallback();
	};

	onDrop = files => {
		const file = files[0];

		if (!this.isImg(file)) {
			this.setState({
				icon: null,
				uploadError: '*Only image upload is allowed',
			});
		} else if (file.size > 20000) {
			this.setState({
				icon: null,
				uploadError: '*Only uploads up to 20kB are allowed',
			});
		} else {
			this.setState({
				icon: file,
				uploadError: undefined,
			});
		}
	};

	onInputChange = (name, value) =>
		this.setState({
			[name]: value,
		});

	enableSubmit = () =>
		this.setState({
			isFormValid: true,
		});

	disableSubmit = () =>
		this.setState({
			isFormValid: false,
		});

	isImg = file => file.type.indexOf('image') >= 0 || file.type.indexOf('img') >= 0;

	handleSubmit = (data, reader) => {
		const partnerRoles =
			!this.props.biaApp && this.state.selectedPartnerRoles
				? this.props.partnerRoles.data
						.filter(role => this.state.selectedPartnerRoles.indexOf(role.name) > -1)
						.map(role => ({ role_name: role.name, role_description: role.description }))
				: [];
		const internalRoles =
			!this.props.biaApp && this.state.selectedInternalRoles
				? Object.values(this.props.internalRoles)
						.filter(role => this.state.selectedInternalRoles.indexOf(role.name) > -1)
						.map(role => ({ role_name: role.name, role_description: role.description }))
				: [];

		const { internal } = this.props;

		const payloadData = {
			display_name: this.state.isPageTblPartnerSection ? 
							`${this.state.selectedPage} for ${this.state.selectedPartner}` : 
							data.reportName.trim(),
			custom_report: this.state.isPageTblPartnerSection,
			selected_page: this.state.selectedPage,
			internal,
			description: data.reportDesc.trim(),
			icon: reader ? reader.result : null,
			path: data.reportPath.trim(),
			tableau_application_id: this.props.appId,
			internal_roles: internalRoles,
		};
		if (!internal) {
			payloadData.partner = data.partner;
			payloadData.partner_id = this.props.selectedPartnerDetails?.partner_id;
			payloadData.partner_roles = partnerRoles;
		}
		
		if (this.props.editMode) {
			this.props.updateReport(payloadData, this.props.report.id);
		} else {
			this.props.createReport(payloadData);
		}
	};

	selectPartners = data =>
		this.setState(
			{
				selectedPartner: data,
				selectedPartnerRoles: undefined,
				isPageTblPartnerSection: false,
			},
			() => this.props.fetchPartnerDetails(data)
		);
	
	selectPage = data => {
		this.setState({
			selectedPage: data,
		})
	}

	selectPartnerRoles = data => this.setState({ selectedPartnerRoles: data });

	selectInternalRoles = data => this.setState({ selectedInternalRoles: data });

	handlePageTblSection = () => {
		this.setState({
			isPageTblPartnerSection : !this.state.isPageTblPartnerSection
		}, 
		() => this.state.isPageTblPartnerSection && this.props.fetchTableauPageList(this.state.selectedPartner)
		);
	}

	render() {
		let imageData;
		if (this.state.icon) {
			imageData = this.state.icon.preview;
		} else if (this.props.editMode) {
			imageData = this.props.report.icon;
		}
		
		const availablePages = this.props.editMode && this.props.availablePages?.indexOf(this.state.selectedPage) < 0 && this.state.selectedPage ? 
			[...this.props.availablePages, this.state.selectedPage] : 
			this.props.availablePages;

		return (
			<Formsy
				onValidSubmit={this.onSubmit}
				onValid={this.enableSubmit}
				onInvalid={this.disableSubmit}
				data-automation="regionNewTableauReportForm"
			>
				<SVGIcon
					icon="CloseIcon"
					wrapperClassName="ButtonClose"
					handleClick={this.props.onCloseCallback}
					aria-label="Close"
					dataAutomation="actionCloseAddNewReportAppForm"
				/>

				<h4 className="h-marginB--lg">{!this.props.editMode ? 'Add report' : 'Update report'}</h4>
				{!this.props.internal ? (
					<div className="h-marginB--md">
						<label>Select partner*</label>
						<ComboSelect
							name="partner"
							data={this.props.availablePartners}
							value={this.state.selectedPartner}
							onChange={this.selectPartners}
							defaultText="Select an partner"
							data-automation="fieldTableauReportsAllowedAirlines"
							required
						/>
					</div>
				) : null}
				{
					!this.props.internal && this.state.selectedPartner && this.props.selectedPartnerDetails?.['use_tableau_view'] ? (
						<div className="h-marginB--md" data-automation="fieldPageTblPartnerCheckboxHolder">
							<CheckboxRadioItem
								dataAutomationSuffix="pageTblPartnerCheckbox"
								key="pageTblPartnerCheckboxKey"
								label="Enable Tableau Pages UI"
								labelClassName="CheckBoxGroup h-displayBlock"
								name="pageTblPartnerCheckbox"
								onChange={this.handlePageTblSection}
								spanLabelClassName="CheckBoxGroup__spanLabel h-marginL--sm"
								type="checkbox"
								checked={this.state.isPageTblPartnerSection}
							/>
						</div>
					) : null
				}
				{!this.props.internal && this.state.isPageTblPartnerSection ? (
					<div className="h-marginB--md">
						<label>Select page*</label>
						<ComboSelect
							name="page"
							data={availablePages}
							value={this.state.selectedPage}
							onChange={this.selectPage}
							defaultText="Select an page"
							data-automation="fieldTableauReportsAllowedAirlines"
							required
							disabled={this.props.availablePagesIsLoading}
						/>
					</div>
				) : null}
				{
					!this.state.isPageTblPartnerSection && (
					<div className="h-marginB--md">
						<label>Report name*</label>
						<Input
							className="InputBox"
							type="text"
							name="reportName"
							layout="elementOnly"
							onChange={this.onInputChange}
							value={this.state.reportName}
							data-automation="fieldTableauReportName"
							required
						/>
					</div>) 
				}
				<div className="h-marginB--md">
					<label>Report path*</label>
					<Input
						className="InputBox"
						type="text"
						name="reportPath"
						layout="elementOnly"
						onChange={this.onInputChange}
						value={this.state.reportPath}
						data-automation="fieldTableauReportPath"
						required
					/>
				</div>
				{
					!this.state.isPageTblPartnerSection && (
						<div className="h-marginB--md">
							<label>Report icon</label>
							<Dropzone className="dropzone" multiple={false} onDrop={this.onDrop}>
								<p data-automation="textSelectTableauReportIconToUpload">Select report icon to upload</p>
							</Dropzone>
							{this.state.uploadError ? (
								<p
									className="ManageTableauApps__iconUploadError"
									data-automation="textIconTableauReportUploadError"
								>
									{this.state.uploadError}
								</p>
							) : null}
							{imageData ? (
								<div className="ManageTableauApps__iconUploadPreview h-borderALL h-paddingALL--xxs h-textCenter">
									<img
										src={imageData}
										className="ManageTableauApps__iconUploadPreviewImage"
										data-automation="regionTableauReportIconUploadPreview"
										alt="Icon Upload Preview"
									/>
								</div>
							) : (
								<div className="ManageTableauApps__iconUploadPreview h-borderALL h-paddingALL--xxs h-textCenter">
									<div
										className="ManageTableauApps__iconUploadPreviewImage ManageTableauApps__iconUploadPreviewImage--reportDefault"
										data-automation="regionTableauReportIconUploadPreview"
									/>
								</div>
							)}
						</div>
					)
				}
				<div className="h-marginB--md">
					<label>Description*</label>
					<Textarea
						className="InputBox"
						name="reportDesc"
						layout="elementOnly"
						onChange={this.onInputChange}
						value={this.state.reportDesc}
						data-automation="fieldTableauReportDescription"
						required
					/>
				</div>
				{this.props.appId !== sharedStore.getState().BIATableauAppId ? (
					<div className="h-marginB--md">
						<label className="h-marginB--sm">Grant permission to user roles</label>
						<div className="h-marginB--md">
							<ComboSelect
								name="internalRoles"
								data={Object.values(this.props.internalRoles).filter(
									role => tblDefaultAdminRoles.indexOf(role.name) < 0
								)}
								map={{ value: 'name', text: 'description' }}
								onChange={this.selectInternalRoles}
								value={this.state.selectedInternalRoles}
								type="multiselect"
								defaultText="Select internal roles"
								data-automation="fieldTableauReportsAllowedInternalRoles"
							/>
						</div>
						{!this.props.internal && (
							<div>
								<ComboSelect
									name="partnerRoles"
									data={this.props.partnerRoles.data}
									map={{ value: 'name', text: 'description' }}
									onChange={this.selectPartnerRoles}
									value={this.state.selectedPartnerRoles}
									type="multiselect"
									defaultText="Select a partner role"
									data-automation="fieldTableauReportsAllowedPartnerRoles"
									disabled={this.props.partnerRoles.listLoading || this.props.partnerLoading}
								/>
							</div>
						)}
					</div>
				) : null}
				<div className="h-textRight h-marginT--xxl">
					<SmartButton
						text="Cancel"
						className="Button Button--text Button--text--underline h-marginL--sm"
						onClickCallback={this.props.onCloseCallback}
						dataAutomation="actionCloseNewTableauReportModal"
					/>
					<SmartButton
						text="Save"
						type="submit"
						className="Button Button--action h-marginL--sm"
						isDisabled={!this.state.isFormValid || this.props.isLoading}
						isInPendingState={this.props.isLoading}
						dataAutomation="actionCreateUpdateNewTableauReport"
					/>
				</div>
			</Formsy>
		);
	}
}
