import React from 'react';
import PropTypes from 'prop-types';

import ProfileMenu from './ProfileMenu';
import SVGIcon from '../SVGIcon/SVGIcon';

const Profile = props => {
	const toggleProfileMenu = event => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		props.toggleSidebar(false);
		props.toggleAppMenu(false);
		props.toggleProfileMenu(!props.isProfileMenuOpen);
	};

	let mail = '';
	if (props.userDetails && props.userDetails.email) {
		mail = props.userDetails.email;
	}
	if (mail && mail.length > 24) {
		mail = `${mail.substring(0, 22)}..`;
	}
	return (
		<div
			className={`h-pullRight js-ignore-global-menu-close UserProfile${props.isProfileMenuOpen ? ' open' : ''}`}
			data-automation="regionProfile"
		>
			<a
				className="h-textCenter h-displayBlock UserProfile__link"
				href="#"
				data-automation="actionClickProfileMenu"
				onClick={toggleProfileMenu}
			>
				<span className="UserProfile__userMail" data-automation="textUserEmail">
					{mail}
				</span>
				<SVGIcon icon="UserIco" wrapperClassName="UserProfile__mobileIcon" size={20} />
				<SVGIcon icon="CaretDown" wrapperClassName="UserProfile__carretIcon" size={15} />
			</a>
			{props.isProfileMenuOpen && (
				<ProfileMenu
					email={mail}
					disablePartnerSwitcher={props.disablePartnerSwitcher}
					showProfileMenu={props.isProfileMenuOpen}
					userDetails={props.userDetails}
					switcherData={props.switcherData}
					appBase={props.appBase}
					onChangePartner={props.onChangePartner}
				/>
			)}
		</div>
	);
};

Profile.propTypes = {
	isProfileMenuOpen: PropTypes.bool,
	userDetails: PropTypes.shape({
		email: PropTypes.string,
	}),
	toggleSidebar: PropTypes.func,
	toggleAppMenu: PropTypes.func,
	toggleProfileMenu: PropTypes.func,
	onChangePartner: PropTypes.func,
	disablePartnerSwitcher: PropTypes.bool,
	appBase: PropTypes.string,
	switcherData: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Profile;
