import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from './SVGIcon/SVGIcon';

export default class OkDialogue extends React.Component {
	static displayName = 'OkDialogue';

	static propTypes = {
		title: PropTypes.string,
		pieChart: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		pieChartSvgTitle: PropTypes.string,
		trendAnalytic: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		dataAutomation: PropTypes.string,
		footerAndButton: PropTypes.bool,
		modalTitleOnTop: PropTypes.bool,
		onOkCallback: PropTypes.func,
		closeModalCallback: PropTypes.func,
		children: PropTypes.node,
	};

	static defaultProps = {
		pieChart: '',
		trendAnalytic: '',
		footerAndButton: true,
		modalTitleOnTop: false,
	};

	render() {
		let footerAndButton;
		if (this.props.footerAndButton) {
			footerAndButton = (
				<div className="h-displayFlex h-flexJustifyCenter">
					<button
						type="button"
						data-automation={`action${this.props.dataAutomation}Confirm`}
						className="Button Button--action"
						onClick={this.props.onOkCallback}
					>
						Ok
					</button>
				</div>
			);
		}

		return (
			<div data-automation={`region${this.props.dataAutomation}`}>
				<div>
					<div>
						<SVGIcon
							icon="CloseIcon"
							wrapperClassName="ButtonClose"
							handleClick={e => {
								e.preventDefault();
								this.props.closeModalCallback();
							}}
							aria-label="Close"
							dataAutomation="actionCloseModal"
						/>

						{this.props.modalTitleOnTop && <h4 className="h-textCenter ">{this.props.title}</h4>}
						<div>
							{this.props.pieChartSvgTitle && (
								<h5 className="h-textCenter h-noMarginB">{this.props.pieChartSvgTitle}</h5>
							)}
							{this.props.pieChart}
						</div>

						<div>
							{!this.props.modalTitleOnTop && <h4 className="h-textCenter ">{this.props.title}</h4>}
							{this.props.trendAnalytic}
						</div>
					</div>
					<div>{this.props.children}</div>
					{footerAndButton}
				</div>
			</div>
		);
	}
}
