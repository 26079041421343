/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { tblReportBaseUrl as baseUrl } from 'gogo-sphere-shared-redux';

import LoadingIndicator from '../LoadingIndicator';
import Tableau from './tableauHelperFunctions';

class BIAReport extends React.Component {
	static propTypes = {
		report: PropTypes.object.isRequired,
		partnerCode: PropTypes.string,
		history: PropTypes.object,
		match: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.tableau = null;
		this.path = null;
		this.checker = null;

		this.state = {
			reportIsLoading: true,
			reportIsReloading: false,
			scriptIsLoaded: false,
		};
	}

	componentWillMount() {
		//This will check if script is already loaded and use it straight away
		if (!window.tableau) {
			window.tableau = window.tableau || {};
			const script = document.createElement('script');

			script.setAttribute('src', `${baseUrl}/javascripts/api/tableau-2.2.2.min.js`);

			script.onload = () => {
				script.setAttribute('id', 'tableau');
			};

			document.body.appendChild(script);
		}
	}

	componentDidMount() {
		const { path } = this.props.report;
		
		if (path) {

			this.checker = setInterval(() => {
				if (this._isTblLoaded() && path) {
					this._initViz(path);
				}
			}, 500);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.partnerCode !== prevProps.partnerCode) {
			this.props.history.goBack();
		}

		if (this.props.match.path !== prevProps.match.path) {
			this.onTokenRefresh();
		}
	}

	onTokenRefresh = () => {
		const path = this.props.report.path ? this.props.report.path : '';

		if (this.tableau) {
			this.tableau.recreateViz(path);
		}

		this.setState({ reportIsReloading: false });
	};

	_isTblLoaded = () => !!document.getElementById('tableau');

	_initViz = path => {
		clearInterval(this.checker);
		this.tableau = new Tableau(`${path}`, 'vizContainer', baseUrl, {
			user: JSON.parse(window.localStorage.getItem('current_user')).email || '',
		});
		this.tableau.initViz();
		this.setState({
			reportIsLoading: false,
			scriptIsLoaded: true,
		});
	};

	render() {
		return (
			<div data-automation="regionCustomReport" className="h-whiteBg">
				<div className="container-fluid">
					<div className="row">
						{this.state.reportIsLoading ? (
							<div className="h-textCenter h-fullWidth h-marginT--xxl h-marginB--lg">
								<LoadingIndicator />
							</div>
						) : null}
						<div className="h-fullWidth" style={{maxWidth: '1200px'}}>
							<div id="vizContainer"/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(BIAReport);