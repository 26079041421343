import React from 'react';
import uniqueId from 'lodash.uniqueid';
import BreadcrumbItem from './BreadcrumbItem';

export default (paths, breadcrumbs, tooltipItem, showTruncated, props) => {
	paths.forEach((path, i) => {
		const crumb = (
			<BreadcrumbItem
				pathName={path.name}
				key={uniqueId()}
				// eslint-disable-next-line react/jsx-no-bind
				goBack={props.goBack.bind(null, path.id, path.position, true)}
			/>
		);
		breadcrumbs.push(crumb);
		tooltipItem.text = i > 0 ? `${tooltipItem.text} > ${path.name}` : path.name;
	});
};
