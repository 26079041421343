import { inheritPermission, hasAccess } from 'gogo-sphere-shared-util';

import withAccessControl from '../auth/withAccessControl';

let lastApp = null;
let lastReturn = null;

function parsePage(page, navItems, appPath, pageComponents) {
	if (page.sidebar) {
		navItems.push(page);
	}

	let subpages = null;

	if (page.pages) {
		subpages = page.pages.map(subpage =>
			parsePage(inheritPermission(subpage, page), navItems, appPath, pageComponents)
		);
	}

	return {
		appPath,
		page,
		hasAccess,
		subpages,
		component: withAccessControl(page, hasAccess, pageComponents),
	};
}

export default function(app, pageComponents) {
	if (app.path === lastApp) {
		return lastReturn;
	}

	const navItems = [];

	const pageRoutes = app.pages.map(page =>
		parsePage(inheritPermission(page, app), navItems, app.path, pageComponents)
	);

	lastApp = app.path;
	lastReturn = { pageRoutes, navItems };

	return lastReturn;
}
