import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-combo-modal';

import GrayWrapper from '../GrayWrapper';
import TableauReportTable from './TableauReportTable';
import AddReportForm from './AddReportForm';
import SVGIcon from '../SVGIcon/SVGIcon';

export default class TableauReports extends React.Component {
	static propTypes = {
		getSingleApp: PropTypes.func.isRequired,
		createReport: PropTypes.func.isRequired,
		updateReport: PropTypes.func.isRequired,
		deleteReport: PropTypes.func.isRequired,
		resetAllData: PropTypes.func.isRequired,
		tableauApp: PropTypes.object.isRequired,
		isSaving: PropTypes.bool,
		isDeleting: PropTypes.bool,
		user: PropTypes.object.isRequired,
		fetchRoles: PropTypes.func,
		fetchPartnerRoles: PropTypes.func,
		partnerRoles: PropTypes.object,
		internalRoles: PropTypes.object,
		fetchPartnerDetails: PropTypes.func,
		fetchTableauPageList: PropTypes.func,
		partnerDetails: PropTypes.object,
		partnerLoading: PropTypes.bool,
		biaApp: PropTypes.bool,
		showHeadline: PropTypes.bool,
		isLoading: PropTypes.bool,
		availablePages: PropTypes.array,
		availablePagesIsLoading: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isNewReportModalOpen: false,
		};
	}

	closeAddReportFormModal = () => {
		this.setState({
			isNewReportModalOpen: false,
		});
	};

	openAddReportFormModal = () => {
		this.setState({
			isNewReportModalOpen: true,
		});
	};

	render() {
		return (
			<div data-automation="regionTableauPage" className="manage-tableau-page">
				<Modal open={this.state.isNewReportModalOpen} onCloseCallback={this.closeAddReportFormModal}>
					<AddReportForm
						onCloseCallback={this.closeAddReportFormModal}
						availablePartners={this.props.tableauApp.partners}
						createReport={this.props.createReport}
						isLoading={this.props.isSaving}
						internal={this.props.tableauApp.internal}
						appId={this.props.tableauApp.id}
						fetchRoles={this.props.fetchRoles}
						fetchPartnerRoles={this.props.fetchPartnerRoles}
						partnerRoles={this.props.partnerRoles}
						internalRoles={this.props.internalRoles}
						fetchPartnerDetails={this.props.fetchPartnerDetails}
						fetchTableauPageList={this.props.fetchTableauPageList}
						selectedPartnerDetails={this.props.partnerDetails}
						partnerLoading={this.props.partnerLoading}
						biaApp={this.props.biaApp}
						availablePages={this.props.availablePages}
						availablePagesIsLoading={this.props.availablePagesIsLoading}
					/>
				</Modal>

				<GrayWrapper>
					<div className="col-md-12">
						{this.props.showHeadline && this.props.tableauApp ? (
							<div className="h-displayInline">
								<h2 className="h-marginB--md" data-automation="textTableauAppAdminHeader">
									{this.props.tableauApp.display_name}
								</h2>
								<p className="h-marginB--sm" data-automation="textTableauAppAdminDescription">
									{this.props.tableauApp.description}
								</p>
							</div>
						) : null}
						<button
							className="Button Button--action h-flexCenter h-deskRightMobileCenter"
							onClick={this.openAddReportFormModal}
							data-automation="actionOpenAddPartnerForm"
						>
							<SVGIcon icon="PlusIco" size={11} wrapperClassName="h-marginR--xxs" />
							<span>New report</span>
						</button>
					</div>
				</GrayWrapper>

				<TableauReportTable
					resetAllData={this.props.resetAllData}
					getSingleApp={this.props.getSingleApp}
					deleteReport={this.props.deleteReport}
					updateReport={this.props.updateReport}
					tableauApp={this.props.tableauApp}
					internal={this.props.tableauApp.internal}
					isSaving={this.props.isSaving}
					isDeleting={this.props.isDeleting}
					isLoading={this.props.isLoading}
					user={this.props.user}
					fetchRoles={this.props.fetchRoles}
					fetchPartnerRoles={this.props.fetchPartnerRoles}
					partnerRoles={this.props.partnerRoles}
					internalRoles={this.props.internalRoles}
					fetchPartnerDetails={this.props.fetchPartnerDetails}
					selectedPartnerDetails={this.props.partnerDetails}
					partnerLoading={this.props.partnerLoading}
					fetchTableauPageList={this.props.fetchTableauPageList}
					availablePages={this.props.availablePages}
					availablePagesIsLoading={this.props.availablePagesIsLoading}
				/>
			</div>
		);
	}
}
