import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';

export default function dateRangePickerHoc(DateTimePicker, datePropType) {
	return class DateRangePickerHoc extends React.PureComponent {
		static propTypes = {
			dataAutomation: PropTypes.arrayOf(PropTypes.string),
			fromName: PropTypes.string,
			toName: PropTypes.string,
			label: PropTypes.string,
			fromClassName: PropTypes.string,
			toClassName: PropTypes.string,
			wrapperClassName: PropTypes.string,
			required: PropTypes.bool,
			onChange: PropTypes.func,
			minDate: PropTypes.object,
			maxDate: PropTypes.object,
			to: datePropType,
			from: datePropType,
			defaultTo: datePropType,
			defaultFrom: datePropType,
			maxRange: PropTypes.number,
			fromInputProps: PropTypes.object,
			toInputProps: PropTypes.object,
			adjustRange: PropTypes.bool,
			inputProps: PropTypes.object,
			dontFetchAirlineTimezone: PropTypes.bool,
			dontFetchPartnerTimezone: PropTypes.bool,
			selectedTimezone: PropTypes.string,
			name: PropTypes.string,
			dateTime: PropTypes.string,
		};

		constructor(props) {
			super(props);

			this.state = {};

			this.updateFrom = this.updateFrom.bind(this);
			this.updateTo = this.updateTo.bind(this);
		}

		componentWillReceiveProps(nextProps) {
			if (this.props.to != nextProps.to || this.props.from != nextProps.from) {
				this.setState({
					from: this.props.from,
					to: this.props.to,
				});
			}
		}

		handleChange(from, to) {
			if (this.props.onChange) {
				this.props.onChange(from || this.state.from, to || this.state.to);
			}
		}

		updateFrom(value) {
			this._updateDate('from', value);
		}

		updateTo(value) {
			this._updateDate('to', value);
		}

		_updateDate(key, value) {
			if (this.props[key]) {
				//if used as controlled, we call handleChange with new values
				const values = {
					from: this.props.from,
					to: this.props.to,
					[key]: value,
				};
				//[key]: value will overwrite the property that has changed

				if (this.props.maxRange && Math.abs(values.from.diff(values.to, 'seconds')) > this.props.maxRange) {
					if (key === 'from') {
						values.to = values.from.clone().add(this.props.maxRange, 'seconds');
					} else {
						values.from = values.to.clone().subtract(this.props.maxRange, 'seconds');
					}
				}

				this.handleChange(values.from, values.to);
			} else {
				//if used as uncontrolled, we set state then call handleChange without arguments
				const newState = {
					from: this.state.from,
					to: this.state.to,
					[key]: value,
				};

				if (this.props.maxRange && Math.abs(newState.from.diff(newState.to, 'seconds')) > this.props.maxRange) {
					if (key === 'from') {
						newState.to = newState.from.clone().add(this.props.maxRange, 'seconds');
					} else {
						newState.from = newState.to.clone().subtract(this.props.maxRange, 'seconds');
					}
				}

				this.setState(newState, this.handleChange);
			}
		}

		render() {
			const {
				name,
				toName,
				fromName,
				minDate,
				maxDate,
				dateTime,
				dataAutomation,
				label,
				onChange,
				to,
				from,
				defaultTo,
				defaultFrom,
				maxRange,
				fromInputProps,
				toInputProps,
				inputProps,
				...other
			} = this.props;

			let head;

			if (label) {
				head = (
					<label className="h-hidden" htmlFor={label}>
						<span>{label}</span>
						{other.required ? <span> *</span> : undefined}
					</label>
				);
			}

			const dateFrom = from || this.state.from || defaultFrom;
			const dateTo = to || this.state.to || defaultTo;

			const possibleMinFrom = [];
			const possibleMaxFrom = [];
			const possibleMinTo = [];
			const possibleMaxTo = [];

			if (dateFrom) {
				possibleMinTo.push(dateFrom);
			}

			if (dateTo) {
				possibleMaxFrom.push(dateTo);
			}

			if (minDate) {
				possibleMinFrom.push(moment(minDate));
				possibleMinTo.push(moment(minDate));
			}

			if (maxDate) {
				possibleMaxFrom.push(moment(maxDate));
				possibleMaxTo.push(moment(maxDate));
			}

			/*if (maxRange) {
			 if (dateTo) {
			 possibleMinFrom.push(dateTo.clone().subtract(maxRange, 'seconds'));
			 }

			 if (dateFrom) {
			 possibleMaxTo.push(dateFrom.clone().add(maxRange, 'seconds'));
			 }
			 }*/

			return (
				<div>
					{head}
					<div className={this.props.wrapperClassName ? this.props.wrapperClassName : 'row'}>
						<DateTimePicker
							dontFetchAirlineTimezone={this.props.dontFetchAirlineTimezone}
							dontFetchPartnerTimezone={this.props.dontFetchPartnerTimezone}
							singleWrapperClassName={
								typeof this.props.fromClassName === 'string' ? this.props.fromClassName : ''
							}
							name={fromName}
							selectedTimezone={this.props.selectedTimezone}
							minDate={possibleMinFrom.length ? moment.max(possibleMinFrom) : undefined}
							maxDate={possibleMaxFrom.length ? moment.min(possibleMaxFrom) : undefined}
							dateTime={dateFrom}
							dataAutomation={dataAutomation ? dataAutomation[0] : undefined}
							onChange={this.updateFrom}
							value={dateFrom}
							inputProps={{ ...inputProps, ...fromInputProps }}
							{...other}
						/>

						<DateTimePicker
							dontFetchAirlineTimezone={this.props.dontFetchAirlineTimezone}
							dontFetchPartnerTimezone={this.props.dontFetchPartnerTimezone}
							singleWrapperClassName={
								typeof this.props.toClassName === 'string' ? this.props.toClassName : ''
							}
							name={toName}
							selectedTimezone={this.props.selectedTimezone}
							minDate={
								possibleMinTo
									? this.props.adjustRange
										? moment
												.max(possibleMinTo)
												.clone()
												.subtract(1, 'days')
										: moment.max(possibleMinTo)
									: undefined
							}
							maxDate={possibleMaxTo ? moment.min(possibleMaxTo) : undefined}
							dateTime={dateTo}
							dataAutomation={dataAutomation ? dataAutomation[1] : undefined}
							onChange={this.updateTo}
							value={dateTo}
							inputProps={{ ...inputProps, ...toInputProps }}
							{...other}
						/>
					</div>
				</div>
			);
		}
	};
}
