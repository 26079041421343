import PropTypes from 'prop-types';
import React from 'react';

import SVGIcon from '../SVGIcon/SVGIcon';

export default class CopyButton extends React.Component {
	static propTypes = {
		success: PropTypes.func,
		error: PropTypes.func,
		text: PropTypes.string,
		dataAutomationSuffix: PropTypes.string.isRequired,
		isDisabled: PropTypes.bool,
	};

	copyToClipboard = text => {
		if (!text || typeof text !== 'string') {
			if (this.props.error) {
				this.props.error();
			}
			return;
		}

		const textArea = document.createElement('textarea');

		// Ensure that textArea won't show up on the screen for a split second
		textArea.style.position = 'fixed';
		textArea.style.background = 'transparent';
		textArea.style.border = 'none';
		textArea.style.outline = 'none';
		textArea.style.boxShadow = 'none';

		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.select();

		try {
			document.execCommand('copy');
			if (this.props.success) {
				this.props.success();
			}
		} catch (e) {
			if (this.props.error) {
				this.props.error();
			}
		}

		document.body.removeChild(textArea);
	};

	render() {
		return (
			<button
				id="copy-to-clipboard"
				data-automation={`copyBtn-${this.props.dataAutomationSuffix}`}
				type="button"
				className="copyButtonIco"
				disabled={this.props.isDisabled}
				onClick={() => this.copyToClipboard(this.props.text)}
			>
				<SVGIcon icon="CopyIco1" width={14} height={14} />
			</button>
		);
	}
}
