import React from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { withRouter } from 'react-router';
import randomKey from 'lodash.uniqueid';

import { store, menuControlActionCreators } from 'gogo-sphere-shared-redux';

import LinkWithScrollToTop from './LinkWithScrollToTop';
import LoadingIndicator from './LoadingIndicator';
import SVGIcon from './SVGIcon/SVGIcon';

class _AppIcons extends React.Component {
	static propTypes = {
		appLink: PropTypes.string,
		apps: PropTypes.array.isRequired,
		location: PropTypes.object,
		closeAll: PropTypes.func,
		userDetails: PropTypes.shape({
			partner_code: PropTypes.string,
		}),
		generate: PropTypes.string,
		router: PropTypes.object,
		tableauApps: PropTypes.object,
		gotTableauApps: PropTypes.bool,
	};

	componentDidMount() {
		//if the user has only one app available we force them into the app, no need for the landing page
		if (this.props.apps.length === 1 && this.props.location === 'landing' && this.props.apps[0]) {
			this.props.router.push(this.props.apps[0].path);
		}
	}

	_generateMenu = (user, currentAppLink) => {
		const closeAllMenus = this.props.closeAll;
		const divs = this.props.apps.map(application => {
			const titleNoWhitespace = application.name.replace(/\s/g, '').toLowerCase();
			const shortName = application.shortName || application.name;
			const tableauInlineStyleAppSwitcher =
				application.tableauApp && application.icon
					? {
							background: `url(${application.icon}) no-repeat center center`,
							backgroundSize: 'contain',
					  }
					: undefined;

			const _renderSvgOrCssIconForMenu = () => {
				if (application.tableauApp) {
					if (application.icon) {
						//tableau app with user defined icon
						return (
							<div
								className={
									!application.icon && application.tableauApp
										? `AppSwitcher__icon AppSwitcher__icon--tblDefault AppSwitcher__icon--${
												application.path
										  }`
										: `AppSwitcher__icon AppSwitcher__icon--${application.path}`
								}
								key={shortName}
								style={tableauInlineStyleAppSwitcher}
							/>
						);
					}
					//tableau app with our default icon
					return <SVGIcon icon="tableaudefaultappicon" wrapperClassName="AppSwitcher__icon" size={40} />;
				}
				//regular ggs apps
				return (
					<SVGIcon
						icon={titleNoWhitespace}
						wrapperClassName={
							application.externalApp
								? 'AppSwitcher__icon AppSwitcher__iconExternal'
								: 'AppSwitcher__icon'
						}
						size={40}
					/>
				);
			};

			if (application.externalApp) {
				return (
					<div
						className="AppSwitcher__wrapper"
						key={`app_switcher_icon_${randomKey()}`}
						data-automation={`regionMenuApp_${shortName}`}
					>
						<a
							href={application.redirect}
							target={application.openInSameWindow ? '_self' : '_blank'}
							rel="noreferrer"
							onClick={closeAllMenus}
							className="AppSwitcher__wrapperLink"
							data-automation={`actionLinkToApplication_switcher_${application.redirect}`}
						>
							{_renderSvgOrCssIconForMenu()}

							<div
								data-automation={`textTitleOfApplication_switcher_${shortName}`}
								className="h-textCenter AppSwitcher__iconLabel"
							>
								{shortName}
							</div>
						</a>
					</div>
				);
				/* eslint-disable-next-line no-else-return */
			} else {
				return (
					<div
						className={`AppSwitcher__wrapper${currentAppLink == application.path ? ' active' : ''}`}
						key={`app_switcher_icon_${randomKey()}`}
						data-automation={`regionMenuApp_${shortName}`}
					>
						<LinkWithScrollToTop
							to={`/${application.redirect ? application.redirect : application.path}`}
							onClick={closeAllMenus}
							className="AppSwitcher__wrapperLink"
							data-automation={`actionLinkToApplication_switcher_${application.path}`}
						>
							{_renderSvgOrCssIconForMenu()}

							<div
								data-automation={`textTitleOfApplication_switcher_${shortName}`}
								className="h-textCenter AppSwitcher__iconLabel"
							>
								{shortName}
							</div>
						</LinkWithScrollToTop>
					</div>
				);
			}
		});

		return (
			<div className="h-marginT--xxl h-clearfix">{divs.length > 0 ? divs : <div>No available choices.</div>}</div>
		);
	};

	_generateLanding = () => {
		const divs = this.props.apps.map(application => {
			const titleNoWhitespace = application.name.replace(/\s/g, '').toLowerCase();
			const tableauInlineStyleAppSelector =
				application.tableauApp && application.icon
					? {
							background: `url(${application.icon}) center center / 80px no-repeat rgb(255, 255, 255)`,
					  }
					: undefined;

			const _renderSvgOrCssIconForLanding = () => {
				if (application.tableauApp) {
					if (application.icon) {
						//tableau app with user defined icon
						return (
							<div
								className={
									!application.icon && application.tableauApp
										? `AppLanding__icon AppLanding__icon--tblDefault AppLanding__icon--${
												application.path
										  }`
										: `AppLanding__icon AppLanding__icon--${application.path}`
								}
								key={titleNoWhitespace}
								style={tableauInlineStyleAppSelector}
							/>
						);
					}
					//tableau app with our default icon
					return <SVGIcon icon="tableaudefaultappicon" wrapperClassName="AppLanding__icon" size={80} />;
				}
				//regular ggs apps
				return (
					<SVGIcon
						icon={titleNoWhitespace}
						wrapperClassName={
							application.externalApp ? 'AppLanding__icon AppLanding__iconExternal' : 'AppLanding__icon'
						}
						size={80}
					/>
				);
			};

			if (application.externalApp) {
				return (
					<div
						data-automation={`regionLandingPageApp_${titleNoWhitespace}`}
						className="col-xs-6 col-md-3 col-lg-2 h-marginB--sm"
						key={randomKey()}
					>
						<a
							className="AppLanding__link"
							data-automation={`actionLinkToApplication_landing_${titleNoWhitespace}`}
							href={application.redirect}
							target={application.openInSameWindow ? '_self' : '_blank'}
							rel="noreferrer"
						>
							{_renderSvgOrCssIconForLanding()}

							<p
								className="h-textCenter AppLanding__label"
								data-automation={`textTitleOfApplication_landing_${titleNoWhitespace}`}
							>
								{application.name}
							</p>
						</a>
					</div>
				);
			}

			return (
				<div
					data-automation={`regionLandingPageApp_${titleNoWhitespace}`}
					className="col-xs-6 col-md-3 col-lg-2 h-marginB--sm"
					key={randomKey()}
				>
					<LinkWithScrollToTop
						className="AppLanding__link"
						data-automation={`actionLinkToApplication_landing_${titleNoWhitespace}`}
						to={`/${application.redirect ? application.redirect : application.path}`}
					>
						{_renderSvgOrCssIconForLanding()}

						<p
							className="h-textCenter AppLanding__label"
							data-automation={`textTitleOfApplication_landing_${titleNoWhitespace}`}
						>
							{application.name}
						</p>
					</LinkWithScrollToTop>
				</div>
			);
		});
		
		return (
			<div data-automation="regionAppIcons" className="container-fluid">
				<div className="AppLanding">
					<div className="row">
						{divs.length > 0 ? (
							divs
						) : this.props.userDetails && this.props.userDetails.partner_code == 'STAR' ? (
							<div data-automation="textLandingNoPermissions">
								You do not have permissions for any of existing services. If you believe this should be
								different, please contact support team at&nbsp;
								<a className="AppLanding__mailto" href="slack://channel?id=C3FDL66G7&team=T3ER8NGUD">
									startek_supv
								</a>
								&nbsp;Slack channel through your supervisor.
							</div>
						) : (
							<div data-automation="textLandingNoPermissions">
								{this.props.tableauApps.listLoading || !this.props.gotTableauApps ? (
									<LoadingIndicator />
								) : (
									<p>
										You do not have permissions for any of existing services. If you believe this
										should be different, please contact support team at&nbsp;
										<a className="AppLanding__mailto" href="mailto:gogospheresupport@gogoair.com">
											gogospheresupport@gogoair.com
										</a>.
									</p>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	render() {
		return this.props.generate && this.props.generate === 'landing'
			? this._generateLanding()
			: this._generateMenu(this.props.userDetails, this.props.appLink);
	}
}

// const AppIconsWithRouter = withRouter(_AppIcons);

const mapStateToProps = state => ({
	userDetails: state.userDetails,
	tableauApps: state.tableauApps,
	gotTableauApps: state.gotTableauApps,
});

const mapDispatchToProps = dispatch => ({
	closeAll: () => dispatch(menuControlActionCreators.closeAll()),
});

const AppIcons = withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(_AppIcons)
);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<AppIcons {...this.props} />
			</Provider>
		);
	}
}
