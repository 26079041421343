import React from 'react';
import PropTypes from 'prop-types';

export default class CheckboxRadioItem extends React.Component {
	static propTypes = {
		type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
		dataAutomationSuffix: PropTypes.string.isRequired,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
		checked: PropTypes.bool,
		onChange: PropTypes.func,
		disabled: PropTypes.bool,
		labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		iconClassName: PropTypes.string,
		spanLabelClassName: PropTypes.string,
		defaultChecked: PropTypes.bool,
		uncheckedIconClassName: PropTypes.string,
		label: PropTypes.string,
		refReference: PropTypes.func,
		id: PropTypes.string,
	};

	render() {
		const labelClassName = this.props.labelClassName
			? this.props.labelClassName
			: 'CheckBoxGroup CheckBoxGroup--inlineFilters';
		const iconClassName = this.props.iconClassName ? this.props.iconClassName : 'checkBoxIcon';
		const spanLabelClassName = this.props.spanLabelClassName
			? this.props.spanLabelClassName
			: 'CheckBoxGroup__spanLabel';

		return (
			<label
				className={this.props.checked ? `${labelClassName} ${labelClassName}--checked` : `${labelClassName}`}
				data-inputischecked={this.props.checked}
				htmlFor={this.props.id}
				data-automation={`checkboxRadioItem${this.props.dataAutomationSuffix}`}
			>
				<input
					type={this.props.type}
					value={this.props.value}
					checked={this.props.checked}
					defaultChecked={this.props.defaultChecked}
					onChange={this.props.onChange}
					disabled={this.props.disabled}
					ref={this.props.refReference}
					id={this.props.id}
				/>
				<i
					className={
						!this.props.uncheckedIconClassName
							? iconClassName
							: this.props.checked
								? iconClassName
								: this.props.uncheckedIconClassName
					}
				/>
				<span className={spanLabelClassName}>{this.props.label ? this.props.label : ' '}</span>
			</label>
		);
	}
}
