import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../SVGIcon/SVGIcon';

const PasswordStrength = props => (
	<div className="PasswordStrength" data-automation="regionPasswordStrength">
		{props.showHeading ? (
			<div className="PasswordStrength__heading">
				<SVGIcon icon="ExclamationTriangle" wrapperClassName="PasswordStrength__errorIcon" size={18} />
				<h4 className="h-displayInline h-marginL--sm">Password strength</h4>
			</div>
		) : null}
		<p
			className={
				props.errors.minLength
					? 'PasswordStrength__message PasswordStrength__message--error'
					: 'PasswordStrength__message PasswordStrength__message--valid'
			}
			data-automation="textPasswordStrengthMinLengthError"
		>
			At least 8 characters long
		</p>
		<p
			className={
				props.errors.lowerCaseLetter
					? 'PasswordStrength__message PasswordStrength__message--error'
					: 'PasswordStrength__message PasswordStrength__message--valid'
			}
			data-automation="textPasswordStrengthLowercaseError"
		>
			Contain at least one lower case letter
		</p>
		<p
			className={
				props.errors.upperCaseLetter
					? 'PasswordStrength__message PasswordStrength__message--error'
					: 'PasswordStrength__message PasswordStrength__message--valid'
			}
			data-automation="textPasswordStrengthUppercaseError"
		>
			Contain at least one upper case letter
		</p>
		<p
			className={
				props.errors.number
					? 'PasswordStrength__message PasswordStrength__message--error'
					: 'PasswordStrength__message PasswordStrength__message--valid'
			}
			data-automation="textPasswordStrengthNumberError"
		>
			Contain at least one number
		</p>
		<p
			className={
				props.errors.specialCharacter
					? 'PasswordStrength__message PasswordStrength__message--error'
					: 'PasswordStrength__message PasswordStrength__message--valid'
			}
			data-automation="textPasswordStrengthSpecialCharError"
		>
			Contain at least one special character (i.e. one of @#$%^&+=)
		</p>
	</div>
);

PasswordStrength.propTypes = {
	showHeading: PropTypes.bool,
	errors: PropTypes.object,
};

export default PasswordStrength;
