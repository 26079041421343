import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ComboSelect from 'react-combo-select';

export class _ProfilePartner extends Component {
	static propTypes = {
		partner: PropTypes.string,
		onChangePartner: PropTypes.func,
		switcherData: PropTypes.array,
		disablePartnerSwitcher: PropTypes.bool,
		userDetails: PropTypes.shape({
			partner_code: PropTypes.string,
		}),
	};

	changePartner = partner => {
		this.props.onChangePartner(partner);
	};

	renderSwitcher = () => {
		let profilePartner;
		const { userDetails } = this.props;

		if (userDetails && !userDetails.isLoading) {
			if (Array.isArray(this.props.switcherData) && this.props.switcherData.length > 1) {
				profilePartner = (
					<div className="h-marginT--md">
						<ComboSelect
							value={this.props.partner ? this.props.partner : this.props.userDetails['partner_code']}
							data={this.props.switcherData}
							search="smart"
							groups="enable"
							iconSelectInactive={false}
							iconSelectActive={false}
							onChange={this.changePartner}
							disabled={this.props.disablePartnerSwitcher}
							data-automation="fieldSwitchAirlines"
						/>
					</div>
				);
			} else if (this.props.userDetails['partner_code']) {
				profilePartner = (
					<h1 className="ProfileMenu__airlineTitle" data-automation="textSingleAirlineAvailable">
						{this.props.userDetails['partner_code']}
					</h1>
				);
			} else {
				profilePartner = (
					<h1 className="ProfileMenu__airlineTitle" data-automation="textAirlineUnavailable">
						No Available Partner
					</h1>
				);
			}
		} else {
			profilePartner = <h1 data-automation="fieldSwitchAirlinesLoading">Loading...</h1>;
		}
		return profilePartner;
	};

	render() {
		return this.renderSwitcher();
	}
}

const mapStateToProps = state => ({
	partner: state.currentPartner.selectedPartner,
});

export default connect(mapStateToProps)(_ProfilePartner);
