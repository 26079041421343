import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from './SVGIcon/SVGIcon';

const ErrorNotification = props => (
	<div className="NotificationError">
		<SVGIcon icon="ExclamationTriangle" wrapperClassName="NotificationError__icon h-marginR--xxs" size={30} />
		<span data-automation="NotificationError__text">{props.text}</span>
	</div>
);

ErrorNotification.propTypes = {
	text: PropTypes.string.isRequired,
};

export default ErrorNotification;
