import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../SVGIcon/SVGIcon';

export default class ExportButton extends React.Component {
	static propTypes = {
		openExportModal: PropTypes.func,
		dataAutomationSuffix: PropTypes.string,
	};

	constructor(props) {
		super(props);

		this.state = {
			tableData: [],
		};
	}

	componentDidMount() {
		document.addEventListener('tableData', this.getData);
	}

	componentWillUnmount() {
		document.removeEventListener('tableData', this.getData);
	}

	getData = event => {
		const { tableData } = event.detail;

		if (this.props.dataAutomationSuffix === event.detail.tableID) {
			this.setState({ tableData });
		}
	};

	render() {
		return this.state.tableData.length > 0 ? (
			<SVGIcon
				icon="Download"
				wrapperClassName="ComboTable__exportButton"
				handleClick={this.props.openExportModal}
				dataAutomation={`actionOpenComboTableExportDialog_${this.props.dataAutomationSuffix}`}
			/>
		) : (
			<SVGIcon
				icon="Download"
				wrapperClassName="ComboTable__exportButton ComboTable__exportButton--disabled"
				handleClick={this.props.openExportModal}
				dataAutomation={`actionOpenComboTableExportDialog_${this.props.dataAutomationSuffix}`}
			/>
		);
	}
}
