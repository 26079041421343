import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import moment from 'moment-timezone';

import DateTimePicker from './DateTimePicker';

class FormsyDateTime extends React.Component {
	static propTypes = {
		onChange: PropTypes.func,
		selectedTimezone: PropTypes.string,
		setValue: PropTypes.func,
		className: PropTypes.string,
		name: PropTypes.string,
		getValue: PropTypes.func,
		getErrorMessage: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.changeValue = ::this.changeValue;
	}

	changeValue(value) {
		if (this.props.onChange) {
			this.props.onChange(value);
		}

		if (this.props.selectedTimezone) {
			this.props.setValue(moment(value).tz(this.props.selectedTimezone));
		} else {
			this.props.setValue(value);
		}
	}

	render() {
		const { onChange, className, name, getValue, getErrorMessage, ...other } = this.props;
		const value = this.props.selectedTimezone ? moment(getValue()).tz(this.props.selectedTimezone) : getValue();

		return (
			<div className={`${className} h-relative`}>
				<DateTimePicker onChange={this.changeValue} value={value} name={name} {...other} />
				<span>{getErrorMessage()}</span>
			</div>
		);
	}
}

export default withFormsy(FormsyDateTime);
