import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-combo-modal';

import { isGogoUser } from 'gogo-sphere-shared-util';

import ChangePasswordModal from '../auth/ChangePasswordModal';
import SVGIcon from '../SVGIcon/SVGIcon';

export default class ProfileButtons extends React.Component {
	//click on any element will close modal,
	// that is why we use stopPropagation
	static stopEventPropagation(e) {
		e.stopPropagation();
	}

	static propTypes = {
		history: PropTypes.object,
		userDetails: PropTypes.shape({
			user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			partner_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	};

	constructor(props) {
		super(props);
		this.state = {
			isChangePasswordModalOpen: false,
		};
	}

	openPasswordModal = () =>
		this.setState({
			isChangePasswordModalOpen: true,
		});

	closePasswordModal = () =>
		this.setState({
			isChangePasswordModalOpen: false,
		});

	logout = () => this.props.history.push('/logout');

	render() {
		const showChangePass = this.props.userDetails && !isGogoUser(this.props.userDetails);
		const customClassNames = {
			modal: 'ReactComboModal js-ignore-global-menu-close',
		};

		return (
			<div data-automation="regionProfileButtons">
				<Modal
					open={this.state.isChangePasswordModalOpen}
					onCloseCallback={this.closePasswordModal}
					customClassNames={customClassNames}
				>
					<div onClick={e => ProfileButtons.stopEventPropagation(e)}>
						<ChangePasswordModal
							userId={this.props.userDetails['user_id']}
							closeCallback={this.closePasswordModal}
						/>
					</div>
				</Modal>
				{showChangePass ? (
					<button
						data-automation="actionChangePassword"
						className="Button Button--logOutMenu"
						ref={el => {
							this.changePasswordButton = el;
						}}
						key="change_password_button"
						onClick={this.openPasswordModal}
					>
						<SVGIcon icon="UnlockAlt" />
						Change Password
					</button>
				) : (
					''
				)}
				<button
					className="Button Button--logOutMenu"
					data-automation="actionLogout"
					key="logout_button"
					onClick={this.logout}
					ref={el => {
						this.logoutButton = el;
					}}
				>
					<SVGIcon icon="SignOut" />
					Log out
				</button>
			</div>
		);
	}
}
