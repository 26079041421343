import React from 'react';
import PropTypes from 'prop-types';
import DateTimeField from 'react-datetime';

import SVGIcon from '../SVGIcon/SVGIcon';
import i18n from '../util/i18n';

export const dateTimePickerValuePropType = PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]);

export default class ClickableDateTimePicker extends React.Component {
	static displayName = 'ClickableDateTimePicker';

	static propTypes = {
		timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
		dateTime: dateTimePickerValuePropType,
		closeOnSelect: PropTypes.bool,
		minDate: PropTypes.object,
		maxDate: PropTypes.object,
		timeConstraints: PropTypes.object,
		isValidDate: PropTypes.func,
		singleWrapperClassName: PropTypes.string,
		dataAutomation: PropTypes.string,
		dateFormat: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
		inputProps: PropTypes.object,
		readOnly: PropTypes.bool,
		name: PropTypes.string,
	};

	constructor(props) {
		super(props);

		this.picker = null;
	}

	getValue = () => this.picker.state.selectedDate;

	render() {
		const { dateTime, minDate, maxDate, timeConstraints, isValidDate, value, ...other } = this.props;

		let { timeFormat, closeOnSelect, dateFormat } = this.props;
		let placeholder = '';

		if (other.name === 'dateFrom') {
			placeholder = 'From';
		} else if (other.name === 'dateTo') {
			placeholder = 'To';
		}

		if (typeof closeOnSelect === 'undefined') {
			closeOnSelect = !timeFormat;
		}

		const validDateFilter = date => {
			if (minDate && date < minDate) {
				return false;
			}

			if (maxDate && date > maxDate) {
				return false;
			}

			if (isValidDate) {
				return isValidDate();
			}

			return true;
		};

		if (typeof dateFormat === 'undefined') {
			dateFormat = i18n.getDateFormat();
		}

		if (typeof timeFormat === 'undefined') {
			timeFormat = i18n.getTimeFormat();
		}

		return (
			<div
				className={
					this.props.singleWrapperClassName
						? `h-relative js-datepicker ${this.props.singleWrapperClassName}`
						: 'h-relative js-datepicker'
				}
			>
				<div className="h-relative">
					<DateTimeField
						dateFormat={dateFormat}
						timeFormat={timeFormat}
						defaultValue={dateTime}
						closeOnSelect={closeOnSelect}
						{...other}
						ref={el => {
							this.picker = el;
						}}
						isValidDate={validDateFilter}
						timeConstraints={timeConstraints}
						value={value}
						inputProps={{
							...this.props.inputProps,
							readOnly: this.props.readOnly,
							name: this.props.name,
							className: 'Datepicker__input js-datepickerInput InputBox',
							placeholder,
							'data-automation': this.props.dataAutomation,
						}}
					/>
					<SVGIcon icon="Calendar" wrapperClassName="Datepicker__icon" size={18} />
				</div>
			</div>
		);
	}
}
