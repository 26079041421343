import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import randomKey from 'lodash.uniqueid';

import SVGIcon from '../SVGIcon/SVGIcon';
import LoadingIndicator from '../LoadingIndicator';
import i18n from '../util/i18n';

const loadingRow = {
	partner_name: <LoadingIndicator />,
	email: <LoadingIndicator />,
};

const loadingRows = [loadingRow, loadingRow];

function splitArrayIntoChunks(arr, numberOfChunks) {
	const len = arr.length;
	const out = [];
	let chunks = numberOfChunks;
	let i = 0;

	while (i < len) {
		const size = Math.ceil((len - i) / chunks--);
		out.push(arr.slice(i, (i += size)));
	}
	return out;
}

export default class PartnersSection extends React.Component {
	static displayName = 'PartnersSection';

	static propTypes = {
		partners: PropTypes.object.isRequired,
		context: PropTypes.string,
	};

	_generatePartners = rows => {
		if (rows.length === 0) {
			return i18n._t('no_partners');
		}

		/*var context = this.props.location.pathname.includes('hvc');
		var path = context === true ? '/hvc' : '/admin';*/
		const path = `/${this.props.context}`;

		return rows.map(partner => (
			<Link
				key={partner.partner_id}
				to={`${path}/partners/partner/${partner.partner_id}`}
				data-automation="actionShowPartnerDetails"
				className="Partners__details"
			>
				{this._renderPartnerNameAndEmail(partner)}
			</Link>
		));
	};

	_generateLoadingPlaceholders = rows =>
		rows.map(partner => <div key={randomKey}>{this._renderPartnerNameAndEmail(partner)}</div>);

	_renderPartnerNameAndEmail = partner => (
		<div
			className="h-paddingALL--sm Partners__info h-marginB--md"
			data-automation={`regionSinglePartnerDetails-${partner['partner_code']}`}
		>
			<div className="h-blueText" data-automation={`textPartnerName-${partner['partner_code']}`}>
				{partner['partner_name'] ? (
					partner['partner_name']
				) : (
					<span className="h-errorColor">{i18n._t('partner_name_missing')}</span>
				)}
			</div>
			<div className="h-blueText" data-automation={`textPartnerEmail-${partner['partner_code']}`}>
				{partner.email ? (
					partner.email
				) : (
					<span className="h-errorColor">{i18n._t('partner_email_missing')}</span>
				)}
			</div>
			<SVGIcon icon="ChevronRight" wrapperClassName="Partners__infoIcon" size={20} />
		</div>
	);

	render() {
		const items = this.props.partners.isLoading
			? this._generateLoadingPlaceholders(loadingRows)
			: this._generatePartners(this.props.partners.data);
		const dataPerColumn = splitArrayIntoChunks(items, 2);

		return (
			<div className="Partners row" data-automation="regionPartnerDetails">
				<div className="Partners__col col-lg-6 col-md-6 col-sm-6 col-xs-12">{dataPerColumn[0]}</div>
				<div className="Partners__col col-lg-6 col-md-6 col-sm-6 col-xs-12">{dataPerColumn[1]}</div>
			</div>
		);
	}
}
