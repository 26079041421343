import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-combo-modal';
import LoadingIndicator from '../LoadingIndicator';
import YesNo from '../YesNo';

import i18n from '../util/i18n';

export default class AirlineDetailsTable extends React.Component {
	static displayName = 'AirlineDetailsTable';

	static propTypes = {
		airlines: PropTypes.shape({
			data: PropTypes.array,
			isLoading: PropTypes.bool,
		}).isRequired,
		partnerId: PropTypes.number,
		deleteAirline: PropTypes.func,
		onEditAirline: PropTypes.func,
	};

	state = {
		airline: {},
		isDeleteAirlineModalOpen: false,
	};

	shouldComponentUpdate(nextProps, nextState) {
		// If there are bugs related to this comp, check this first
		return (
			this.props.airlines.data !== nextProps.airlines.data ||
			this.props.airlines.isLoading !== nextProps.airlines.isLoading ||
			this.props.partnerId !== nextProps.partnerId ||
			nextState.isDeleteAirlineModalOpen !== this.state.isDeleteAirlineModalOpen
		);
	}

	onModalClose = () => this.setState({ isDeleteAirlineModalOpen: false });

	deleteAirline = () => {
		this.props.deleteAirline({ partner_id: this.props.partnerId, ...this.state.airline }).then(this.onModalClose);
	};

	_generateTable = tableBody => (
		<div className="h-overflowAuto h-fullWidth">
			<Modal open={this.state.isDeleteAirlineModalOpen} onCloseCallback={this.onModalClose}>
				<YesNo onYesCallback={this.deleteAirline} onNoCallback={this.onModalClose} title="Delete airline">
					<p data-automation="textDeleteAirlineConfirmation">
						Are you sure you want to delete airline
						<span>
							{this.state.airline.name} {this.state.airline.airline_code}
						</span>?
					</p>
				</YesNo>
			</Modal>

			<table
				className="DataTable DataTable--stripped DataTable--bordered DataTable--framed"
				data-automation="tableAirlineDetails"
			>
				<thead>
					<tr>
						<th data-automation="textTableHeadingAirlineName">Airline name</th>
						<th data-automation="textTableHeadingAirlineCode">Airline code</th>
						<th data-automation="textTableHeadingAirlineTail">Tail</th>
						<th data-automation="textTableHeadingAirlineLocale">Locales</th>
						<th data-automation="textTableHeadingAirlineCurrency">Currency</th>
						{this.props.onEditAirline ? <th data-automation="textTableHeadingAirlineOperations" /> : null}
					</tr>
				</thead>
				<tbody>{tableBody}</tbody>
			</table>
		</div>
	);

	render() {
		const rows = this.props.airlines.isLoading ? [] : this.props.airlines.data;
		let tableBody = [];

		if (this.props.airlines.isLoading) {
			// loading table
			tableBody.push(
				<tr key="empty">
					<td colSpan="6" className="h-textCenter">
						<LoadingIndicator />
					</td>
				</tr>
			);
		} else if (!rows.length) {
			// empty table
			tableBody.push(
				<tr key="empty">
					<td colSpan="6" className="h-textCenter">
						{i18n._t('no_airline_for_partner')}
					</td>
				</tr>
			);
		} else {
			// regular table
			tableBody = rows.map(airline => (
				<tr key={airline.id} data-automation={`airline-code-${airline['airline_code']}`}>
					<td data-automation="textAirlineName">{airline['airline_name']}</td>
					<td data-automation="textAirlineCode">{airline['airline_code']}</td>
					<td data-automation="textAirlineTailCode">{airline.tail}</td>
					<td data-automation="textAirlineLocale">
						{airline.locales && airline.locales.map(locale => locale.code).join(', ')}
					</td>
					<td data-automation="textAirlineCurrency">{airline.currency}</td>
					{this.props.onEditAirline ? (
						<td className="h-textRight">
							<span className="h-flexCenter" role="group">
								<button
									className="Button Button--small"
									data-automation="actionOpenUpdateAirlineForm"
									onClick={() => {
										if (this.props.onEditAirline) this.props.onEditAirline(airline);
									}}
									disabled={this.props.airlines.isLoading}
								>
									{i18n._t('edit')}
								</button>
								<button
									data-automation="actionOpenDeleteAirlineConfirmation"
									className="Button Button--small Button--small--passive h-marginL--xs"
									onClick={() => this.setState({ airline, isDeleteAirlineModalOpen: true })}
									disabled={this.props.airlines.isLoading}
								>
									{i18n._t('delete')}
								</button>
							</span>
						</td>
					) : null}
				</tr>
			));
		}

		return this._generateTable(tableBody);
	}
}
