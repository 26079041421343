import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import treeTableHOC from 'react-table/lib/hoc/treeTable';
import flat from 'flat';

const TreeTable = treeTableHOC(ReactTable);

export default class TreeTableHOC extends React.Component {
	static propTypes = {
		data: PropTypes.array,
		searchText: PropTypes.string,
		columns: PropTypes.array,
		loading: PropTypes.bool,
		noDataText: PropTypes.string,
		showPagination: PropTypes.bool,
		defaultPageSize: PropTypes.number,
		columnsMinWidth: PropTypes.number,
		dataAutomationSuffix: PropTypes.string,
		PaginationComponent: PropTypes.any,
		treeTable: PropTypes.bool,
		ExpanderComponent: PropTypes.any,
		getTableProps: PropTypes.func,
		getTheadProps: PropTypes.func,
		getTbodyProps: PropTypes.func,
		getPaginationProps: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.state = {
			treeTableData: [],
			expandedRows: {},
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.data !== nextProps.data) {
			const expandedRows = {};
			nextProps.data.forEach((row, index) => {
				expandedRows[index] = {};
			});

			return this.setState({ treeTableData: nextProps.data, expandedRows });
		}

		if (nextProps.searchText && this.props.searchText !== nextProps.searchText) {
			const normalizedColumns = this.props.columns.filter(item => !item.filterable);
			return this.setState({
				treeTableData: this.props.data.filter(item => {
					const flattenedItem = flat(item);
					const newItem = {};

					normalizedColumns.forEach((column, index) => {
						const { accessor } = column;

						if (column.id && typeof column.accessor === 'function') {
							newItem[column.id] = column.accessor(item);
							return;
						}

						if (column.accessor && !column.forFiltering) {
							newItem[accessor] = flattenedItem[accessor];
							return;
						}

						if (column.accessor && column.forFiltering) {
							newItem[accessor] = column.forFiltering(flattenedItem[accessor], flattenedItem);

							return;
						}

						if (!column.accessor && column.forFiltering) {
							newItem[`accessor${index}`] = column.forFiltering(null, flattenedItem);
						}
					});

					return Object.values(newItem).some(
						entry =>
							!!(
								entry &&
								entry
									.toString()
									.toLowerCase()
									.includes(nextProps.searchText.trim().toLowerCase())
							)
					);
				}),
			});
		}

		if (this.props.searchText && !nextProps.searchText) {
			return this.setState({ treeTableData: this.props.data });
		}

		return null;
	}

	handleExpandedChange = (newExpanded, index) => {
		const expanded = this.state.expandedRows;

		if (expanded[index]) {
			delete expanded[index];
		} else {
			expanded[index] = true;
		}

		this.setState({ expandedRows: { ...expanded } });
	};

	render() {
		const {
			loading,
			data,
			columns,
			noDataText,
			showPagination,
			defaultPageSize,
			columnsMinWidth,
			dataAutomationSuffix,
			PaginationComponent,
			searchText,
			treeTable,
			ExpanderComponent,
			getTableProps,
			getTheadProps,
			getTbodyProps,
			getPaginationProps,
			...props
		} = this.props;
		return (
			<TreeTable
				{...props}
				data={this.state.treeTableData}
				columns={columns}
				showPagination={showPagination}
				minRows={0}
				defaultPageSize={!showPagination ? 10000 : defaultPageSize}
				loading={loading}
				noDataText={loading ? '' : noDataText}
				PaginationComponent={PaginationComponent}
				getTableProps={getTableProps}
				getTheadProps={getTheadProps}
				getTbodyProps={getTbodyProps}
				getPaginationProps={getPaginationProps}
				lazyLoadUniqueID={dataAutomationSuffix}
				ExpanderComponent={ExpanderComponent}
				expanded={this.state.expandedRows}
				onExpandedChange={this.handleExpandedChange}
				className="BaseTable TreeTable"
			>
				{(state, makeTable, instance) => {
					const eventData = {
						tableData: state.sortedData,
						tableID: dataAutomationSuffix,
					};
					const event = new CustomEvent('tableData', { detail: eventData });

					document.dispatchEvent(event);

					return (
						<>
							{makeTable()}
							{!showPagination &&
								instance &&
								!loading && (
									<p
										className="ComboTable__itemCount"
										data-automation={`textTableItemCount_${dataAutomationSuffix}`}
									>
										{`Showing ${
											state.resolvedData.length !== 0
												? state.sortedData.length === 0
													? 0
													: 1
												: 0
										} to ${state.sortedData.length} of ${state.resolvedData.length} entries`}
									</p>
								)}
						</>
					);
				}}
			</TreeTable>
		);
	}
}
