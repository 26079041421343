/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { getAllowedApps } from 'gogo-sphere-shared-redux';

import AppIcons from '../AppIcons';
import SVGIcon from '../SVGIcon/SVGIcon';

const SwitchApp = props => {
	const toggleAppsMenu = event => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		props.toggleSidebar(false);
		props.toggleAppMenu(!props.isAppMenuOpen);
		props.toggleProfileMenu(false);
	};

	const render = () => {
		const apps = getAllowedApps(props.user, props.apps, props.partner);
		let appsMenu;

		if (apps.length < 2) {
			return null;
		}

		//getAllowedApps is memoized, no need to optimize it out of render
		if (props.isAppMenuOpen) {
			appsMenu = (
				<div
					className="js-ignore-global-menu-close AppSwitcher__selectorMenu"
					role="menu"
					data-automation="regionAppsMenu"
				>
					<div className="AppSwitcher__srollableHolder">
						<AppIcons apps={apps} appLink={props.appLink} />
					</div>
				</div>
			);
		}

		return (
			<div
				className={`h-pullLeft js-ignore-global-menu-close AppSwitcher__button${
					props.isAppMenuOpen ? ' open' : ''
				}`}
				data-automation="regionSwitchApp"
			>
				<SVGIcon
					icon="EllipsisHDouble"
					wrapperClassName="AppSwitcher__selector"
					size={35}
					handleClick={toggleAppsMenu}
					dataAutomation="actionClickSwitchApp"
				/>
				{appsMenu}
			</div>
		);
	};

	return render();
};

SwitchApp.propTypes = {
	user: PropTypes.object,
	apps: PropTypes.array,
	partner: PropTypes.string,
	isAppMenuOpen: PropTypes.bool,
	appLink: PropTypes.string,
};

export default SwitchApp;
