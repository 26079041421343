import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../LoadingIndicator';
import LinkWithScrollToTop from '../LinkWithScrollToTop';
import SVGIcon from '../SVGIcon/SVGIcon';

const HeaderLogo = ({ currentPartner, currentApp: { name, path, header = {} }, showPartnerLogo }) => {
	let partnerLogo;

	//on CMP we want to align header left and show custom text: "Customer Management Portal" instead of partner logos
	if (header.alignHeaderLeft) {
		partnerLogo = (
			<>
				<div className="Header__lineSeparator h-pullLeft" />
				<div className="Header__airlineLogo h-pullRight" data-automation="regionHeaderLogo">
					<span
						className="Header__noAirlineLogo h-displayFlex h-flexAlignItemsCenter"
						data-automation="textNavbarTitle"
					>
						{/* NOTE: We're always using path from manifest for SVG Icons name */}
						{header.showAppIconInHeader ? (
							<LinkWithScrollToTop to={`/${path}/`} style={{ color: '#efefef' }}>
								<SVGIcon icon={path} wrapperClassName="h-marginR--xxs" size={33} />
							</LinkWithScrollToTop>
						) : null}
						{name}
					</span>
				</div>
			</>
		);
	} else if (currentPartner.isLoading && showPartnerLogo) {
		partnerLogo = (
			<>
				<div className="Header__lineSeparator h-pullLeft" />
				<div className="Header__airlineLogo h-pullRight" data-automation="regionAirlineLogoLoading">
					<LoadingIndicator />
				</div>
			</>
		);
	} else if (currentPartner.partnerDetails && currentPartner.partnerDetails['logo_uri'] && showPartnerLogo) {
		partnerLogo = (
			<>
				<div className="Header__lineSeparator h-pullLeft" />
				<div className="Header__airlineLogo h-pullRight" data-automation="regionAirlineLogo">
					<img
						data-automation="resourceAirlineLogo"
						id="airline_logo"
						src={currentPartner.partnerDetails['logo_uri']}
						alt="Gogo Sphere"
						height="60"
					/>
				</div>
			</>
		);
	} else {
		// this is left for QE (not sure if they use it?)
		partnerLogo = <div className="Header__airlineLogo h-pullRight" data-automation="regionNoAirlineLogo" />;
	}

	return partnerLogo;
};

HeaderLogo.propTypes = {
	currentPartner: PropTypes.shape({
		isLoading: PropTypes.bool,
		partnerDetails: PropTypes.shape({
			logo_uri: PropTypes.string,
		}),
	}),
	currentApp: PropTypes.shape({
		name: PropTypes.string,
		path: PropTypes.string,
		header: PropTypes.shape({
			alignHeaderLeft: PropTypes.bool,
			showAppIconInHeader: PropTypes.bool,
		}),
	}),
	showPartnerLogo: PropTypes.bool,
};

HeaderLogo.defaultProps = {
	currentApp: {},
};

export default HeaderLogo;
