import React from 'react';
import PropTypes from 'prop-types';

const defaultButton = data => <button {...data}>{data.children}</button>;

export default class Pagination extends React.Component {
	static propTypes = {
		pages: PropTypes.number,
		page: PropTypes.number,
		PageButtonComponent: PropTypes.any,
		onPageChange: PropTypes.func,
		previousText: PropTypes.string,
		nextText: PropTypes.string,
		dataAutomationSuffix: PropTypes.string,
	};

	static defaultProps = {
		PageButtonComponent: defaultButton,
	};

	constructor(props) {
		super();

		this.changePage = this.changePage.bind(this);

		this.state = {
			visiblePages: this.getVisiblePages(null, props.pages),
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.pages !== nextProps.pages) {
			this.setState({
				visiblePages: this.getVisiblePages(null, nextProps.pages),
			});
		}

		this.changePage(nextProps.page + 1);
	}

	getVisiblePages = (page, total) => {
		if (total < 7) {
			return this.filterPages([1, 2, 3, 4, 5, 6], total);
		}
		if (page % 5 >= 0 && page > 4 && page + 2 < total) {
			return [1, page - 1, page, page + 1, total];
		} else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
			return [1, total - 3, total - 2, total - 1, total];
		}
		return [1, 2, 3, 4, 5, total];
	};

	filterPages = (visiblePages, totalPages) => visiblePages.filter(page => page <= totalPages);

	changePage(page) {
		const activePage = this.props.page + 1;

		if (page === activePage) {
			return;
		}

		const visiblePages = this.getVisiblePages(page, this.props.pages);

		this.setState({ visiblePages: this.filterPages(visiblePages, this.props.pages) });

		this.props.onPageChange(page - 1);
	}

	render() {
		const { PageButtonComponent, dataAutomationSuffix } = this.props;
		const { visiblePages } = this.state;
		const activePage = this.props.page + 1;

		return (
			<div className="ComboTable__pagination" data-automation={`table-${dataAutomationSuffix}-pagination`}>
				<div className="ComboTable__prevPageWrapper">
					<PageButtonComponent
						className="ComboTable__pageButton"
						onClick={() => {
							if (activePage === 1) return;
							this.changePage(activePage - 1);
						}}
						disabled={activePage === 1}
						data-automation={`actionTablePaginationPrevious_${dataAutomationSuffix}`}
					>
						{this.props.previousText}
					</PageButtonComponent>
				</div>
				<div className="ComboTable__visiblePagesWrapper">
					{visiblePages.map((page, index, array) => (
						<PageButtonComponent
							key={page}
							className={
								activePage === page
									? 'ComboTable__pageButton ComboTable__pageButton--active'
									: 'ComboTable__pageButton'
							}
							onClick={this.changePage.bind(null, page)} // eslint-disable-line react/jsx-no-bind
							data-automation={`actionTablePaginationPage_${page}_${dataAutomationSuffix}`}
						>
							{array[index - 1] + 2 < page ? `...${page}` : page}
						</PageButtonComponent>
					))}
				</div>
				<div className="ComboTable__nextPageWrapper">
					<PageButtonComponent
						className="ComboTable__pageButton"
						onClick={() => {
							if (activePage === this.props.pages) return;
							this.changePage(activePage + 1);
						}}
						disabled={activePage === this.props.pages}
						data-automation={`actionTablePaginationNext_${dataAutomationSuffix}`}
					>
						{this.props.nextText}
					</PageButtonComponent>
				</div>
			</div>
		);
	}
}
