import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import randomKey from 'lodash.uniqueid';

const TabMenu = props => (
	<div className="TabMenu" data-automation="regionTabMenu">
		<div className="container-fluid">
			<div className="h-clearfix">
				<nav className="h-pullLeft h-clearfix" data-automation="regionTabMenuNavigation">
					{props.items.length > 1 ? (
						props.items.map(item => {
							if (!item) {
								return null;
							}

							const url = `/${props.appBase}/${item.path}`;

							return (
								<span
									className={`TabMenu__link${
										props.location.pathname === url ||
										(props.location.pathname.includes(props.appBase) &&
											props.location.pathname.includes(item.path))
											? ' TabMenu__link--active'
											: ''
									}`}
									data-automation={`actionClickToSection${item.path}`}
									style={{ cursor: 'pointer' }}
									key={`tab-menu-item-${randomKey()}`}
									onMouseUp={() => {
										window.scrollTo(0, 0);
										props.history.push(url);
									}}
								>
									{item.name}
								</span>
							);
						})
					) : (
						<p className="TabMenu__appName" data-automation="textTabMenuAppName">
							{props.appName}
						</p>
					)}
				</nav>
				{props.children}
			</div>
		</div>
	</div>
);

TabMenu.propTypes = {
	items: PropTypes.array,
	location: PropTypes.object,
	appName: PropTypes.string,
	appBase: PropTypes.string,
	children: PropTypes.node,
};

export default withRouter(TabMenu);
