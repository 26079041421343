/* eslint-disable  */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { exportData, store } from 'gogo-sphere-shared-redux';
import SVGIcon from '../SVGIcon/SVGIcon';

class _ExportTableDialog extends Component {
	static displayName = 'ExportTableDialog';

	static propTypes = {
		table: PropTypes.object,
		size: PropTypes.string,
		exportTable: PropTypes.func,
		dataForExport: PropTypes.object,
	};

	triggerExport = type => {
		this.props.exportData(
			this.props.dataForExport,
			type,
			!this.props.name.includes('data') ? this.props.name.replace(/\s/g, '_').toLowerCase() : this.props.name
		);
		this.props.closeModalCallback();
	};

	render() {
		return (
			<div>
				<div data-automation="regionModalExportTable">
					<div>
						<div>
							<SVGIcon
								icon="CloseIcon"
								wrapperClassName="ButtonClose"
								handleClick={e => {
									e.preventDefault();
									this.props.closeModalCallback();
								}}
								aria-label="Close"
								dataAutomation="actionCloseModal"
							/>
							<h4 className="h-textCenter">Export</h4>
						</div>

						<div id="exportChart" className="h-flexCol h-flexCenter">
							<button
								type="button"
								className="Button Button--action h-marginB--sm h-flexCenter"
								onClick={() => this.triggerExport('excel')}
								data-automation="actionButtonExportExcel"
							>
								<SVGIcon icon="FileExcel" wrapperClassName="h-marginR--xxs" />
								Excel
							</button>

							<button
								type="button"
								className="Button Button--action h-flexCenter"
								onClick={() => this.triggerExport('pdf')}
								data-automation="actionButtonExportPDF"
							>
								<SVGIcon icon="FileImage" wrapperClassName="h-marginR--xxs" />
								PDF
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	exportData: (...args) => dispatch(exportData(...args)),
});

const ExportTableDialog = connect(
	null,
	mapDispatchToProps
)(_ExportTableDialog);

export default function(props) {
	return (
		<Provider store={store}>
			<ExportTableDialog {...props} />
		</Provider>
	);
}
