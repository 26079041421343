import PropTypes from 'prop-types';
import React from 'react';
import Formsy, { addValidationRule } from 'formsy-react';
import ComboSelect from 'react-formsy-combo-select';
import { Input } from 'formsy-react-components';
import { timezones, validators } from 'gogo-sphere-shared-util';
import FormsyDropzone from '../FormsyDropzone';
import CheckboxRadioItem from '../form/CheckboxRadioItem';
import SmartButton from '../SmartButton';
import SVGIcon from '../SVGIcon/SVGIcon';

addValidationRule('isNotWhitespaceOnly', (values, value) => validators.isNotWhitespaceOnly(value));

const partnerTypes = [
	{ text: 'Airline', value: 'Airline' },
	{ text: 'OEM', value: 'OEM' },
	{ text: 'IFC Provider', value: 'IFC_Provider' },
	{ text: 'Other', value: 'Other' },
];

const validationErrorsUsernameTxt = {
	username: 'Please enter username.',
	partnerCode: 'Please enter User name.',
};

let tblUsername = ''; //we do this outside of state bec of slow rerendering
let tblPassword = ''; //we do this outside of state bec of slow rerendering
let code = ''; //we do this outside of state bec of slow rerendering

export default class AddEditPartnerForm extends React.Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		disableCodeField: PropTypes.bool,
		onSubmitCallback: PropTypes.func.isRequired,
		closeModalCallback: PropTypes.func,
		isLoading: PropTypes.bool,
		isModalUpdate: PropTypes.bool,
		partner: PropTypes.object,
		partnerCode: PropTypes.string,
		resetTblPartnerDetails: PropTypes.func,
		fetchTblPartnerDetails: PropTypes.func,
		currentPartner: PropTypes.object,
		currentPartnerCode: PropTypes.string,
		locales: PropTypes.array,
		getLocales: PropTypes.func,
		resetCurrentPartnerOnClose: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			error: null,
			formIsValid: false,
			selectedType: this.props.isModalUpdate ? this.props.currentPartner.type : undefined,
			isTblPartnerSection: false,
			validationErrorsUsername: validationErrorsUsernameTxt.username,
			//eslint-disable-next-line
			tblUserExist: false,
			uploadLogoError: null,
			logo: null,
			timeZone: this.props.currentPartner && this.props.currentPartner.zone_id,
			selectedLocales:
				this.props.isModalUpdate && this.props.currentPartner && this.props.currentPartner.locales
					? this.props.currentPartner.locales.map(locale => locale.code)
					: [],
			isHandlingTableauPageReportingSection: this.props.isModalUpdate ? this.props.currentPartner['use_tableau_view'] || false : false,
		};

		code = this.props.isModalUpdate ? this.props.currentPartner['partner_code'] : '';

		this.createUpdatePartnerForm = React.createRef();
		this.name = React.createRef();
		this.codeRef = React.createRef();
		this.tblUsernameRef = React.createRef();
		this.tblPasswordRef = React.createRef();
		this.emailContact = React.createRef();
	}

	componentDidMount() {
		if (this.props.isModalUpdate) this.props.fetchTblPartnerDetails(this.props.currentPartner['partner_code']);
		this.props.getLocales();
	}

	static getDerivedStateFromProps(nextProps, state) {
		if (
			nextProps.tblPartnerDetails != null &&
			Object.keys(nextProps.tblPartnerDetails).length > 0 &&
			!state.tblUserExist //run all this only once
		) {
			tblUsername = nextProps.tblPartnerDetails.username;
			tblPassword = nextProps.tblPartnerDetails.password;
			return {
				isTblPartnerSection: true, //force true we don't want to hide/delete tableau users if they exist
				tblUserExist: true,
			};
		}
		return null;
	}

	componentWillUnmount() {
		if (this.props.isModalUpdate) this.props.resetTblPartnerDetails();
	}

	onLogoDrop = files => {
		const file = files[0];

		if (!this.isImg(file)) {
			this.setState({
				logo: null,
				uploadLogoError: '*Only image upload is allowed',
			});
		} else {
			this.setState({
				logo: file,
				uploadLogoError: undefined,
			});
		}
	};

	isImg = file => file && file.type && (file.type.indexOf('image') >= 0 || file.type.indexOf('img') >= 0);

	submit = data => {
		if (this.props.isModalUpdate) {
			data.partner_id = this.props.currentPartner.partner_id;
		}

		data['partner_name'] = data['partner_name'].trim();

		if (this.state.selectedType == 'Airline') {
			data['partner_code'] = data['partner_code'].trim();
		}

		if (this.state.tblUserExist) {
			data['tableau_user']['username'] = tblUsername;
		}

		data.locales = data.locales.map(
			locale => this.props.locales && this.props.locales.find(item => item.code === locale)
		);

		data['use_tableau_view'] = this.state.isHandlingTableauPageReportingSection;

		const submitData = () => {
			this.props
				.onSubmitCallback(data)
				.then(() => this.props.closeModalCallback())
				.catch(response => {
					const { error } = response;
					let errorMessage = '';
					let errorsDesc;

					if (error) {
						errorMessage = error.error || 'Error';
						errorsDesc = error.errorsDescription;
					}
					return this.setState({
						error: errorMessage,
						errorsDescription: errorsDesc,
					});
				});
		};

		if (this.state.logo) {
			// api expects base64 format
			const reader = new FileReader();
			reader.readAsDataURL(this.state.logo);
			reader.onload = () => {
				data.logo = reader.result;
				submitData();
			};
		} else {
			data.logo = undefined;
			submitData();
		}

		return null;
	};

	handleTblPartnerSection = () => {
		if (this.props.isModalUpdate && this.state.tblUserExist) {
			//force true we don't want to hide/delete tableau users if they exist
			this.setState({
				isTblPartnerSection: true,
			});
		} else if (this.state.selectedType == 'Airline') {
			tblUsername = this.props.isModalUpdate ? tblUsername : code;
			tblPassword = this.props.isModalUpdate ? tblPassword : '';
			this.setState({
				isTblPartnerSection: true, // forced checked state
				validationErrorsUsername: validationErrorsUsernameTxt.username
			});
		} else {
			tblUsername = ''; //reset values
			tblPassword = ''; //reset values
			this.setState({
				isTblPartnerSection: !this.state.isTblPartnerSection,
				validationErrorsUsername: validationErrorsUsernameTxt.username,
			});
		}
	};

	handleCodeChange = (e, val) => {
		// tblUsername = val;
		code = val;
	};

	handleTblUsernameChange = (e, val) => {
		tblUsername = val;
	};

	handleTblPasswordChange = (e, val) => {
		tblPassword = val;
	};

	handleTimeZoneChange = values => {
		this.setState({
			timeZone: values,
		});
	};

	handleLocaleChange = values => {
		this.setState({
			selectedLocales: values,
		});
	};

	handleHandlingTableauPageReportSection = () => {
		this.setState({
			isHandlingTableauPageReportingSection: !this.state.isHandlingTableauPageReportingSection
		})
	}

	selectType = data => {
		if (data == 'Airline') {
			this.setState(
				{
					selectedType: data,
				},
				() => this.handleTblPartnerSection()
			);
		} else {
			this.setState({
				selectedType: data,
				isTblPartnerSection: false, // reset/uncheck
			});
		}
	};

	enableForm = () => {
		//disable if no changes inside ComboSelect (for create modal)
		if (
			(!this.props.isModalUpdate && this.state.selectedType == undefined) ||
			(!this.props.isModalUpdate && this.state.selectedType.length == 0)
		) {
			return this.disableForm();
		}
		return this.setState({
			formIsValid: true,
		});
	};

	disableForm = () =>
		this.setState({
			formIsValid: false,
		});

	render() {
		const maxHeightProps = {
			scrollMaxHeight: 295, // number
			preferredDirection: 'top', // 'top' | 'down'
		};

		let name = '';
		let email = '';
		let imageData = this.state.logo && this.state.logo.preview;

		if (this.props.isModalUpdate) {
			name = this.props.currentPartner['partner_name'];
			//eslint-disable-next-line
			email = this.props.currentPartner.email;
			imageData =
				this.state.logo && this.state.logo.preview
					? this.state.logo.preview
					: this.props.currentPartner['logo_uri'] && `${this.props.currentPartner['logo_uri']}?${Date.now()}`;
		}

		const { formIsValid } = this.state;

		return (
			<div data-automation="regionModalUpdatePartner">
				<>
					<>
						<SVGIcon
							icon="CloseIcon"
							wrapperClassName="ButtonClose"
							handleClick={e => {
								e.preventDefault();
								this.props.closeModalCallback();
							}}
							aria-label="Close"
							dataAutomation="actionCloseModalUpdatePartner"
						/>
						<h4 data-automation={`textModalTitle${this.props.title}`}>{this.props.title}</h4>
					</>
					<>
						<div className="h-textCenter">
							<span style={{ color: 'red' }}>{this.state.error}</span>
						</div>
						{this.state.errorsDescription
							? this.state.errorsDescription.map(ed => (
									<div className="h-textCenter" key={ed.field}>
										<span style={{ color: 'red' }}>{ed.message}</span>
									</div>
							  ))
							: null}
						<Formsy
							id="createUpdatePartnerForm"
							className="h-marginT--md"
							ref={this.createUpdatePartnerForm}
							onValidSubmit={this.submit}
							onValid={this.enableForm}
							onInvalid={this.disableForm}
							data-automation="formCreateUpdatePartner"
						>
							<div className="h-marginB--sm" data-automation="fieldPartnerNameHolder">
								<label>Partner type *</label>
								<ComboSelect
									name="type"
									data={partnerTypes}
									defaultText="Select partner type"
									data-automation="fieldPartnerType"
									value={this.state.selectedType}
									onChange={this.selectType}
									required={this.props.isModalUpdate ? !!this.props.currentPartner : true}
									disabled={this.props.isModalUpdate ? !!this.props.currentPartner : false}
								/>
							</div>

							<div className="h-marginB--sm" data-automation="fieldPartnerNameHolder">
								<Input
									type="text"
									name="partner_name"
									label="Partner name"
									value={name}
									className="InputBox"
									id="name"
									placeholder="Partner name"
									ref={this.name}
									required
									layout="vertical"
									data-automation="fieldPartnerName"
									validations="isNotWhitespaceOnly,maxLength:49"
									validationError="Max Name's length is 49 characters"
								/>
							</div>

							{this.state.selectedType == 'Airline' && (
								<div className="h-marginB--sm" data-automation="fieldPartnerCodeHolder">
									<Input
										type="text"
										name="partner_code"
										label="Partner code"
										value={code}
										className="InputBox"
										id="code"
										placeholder="Partner code"
										ref={this.codeRef}
										onChange={this.handleCodeChange}
										required
										disabled={this.props.disableCodeField}
										layout="vertical"
										data-automation="fieldPartnerCode"
										validations={!this.props.isModalUpdate ? 'isLength:3,isAlpha' : 'isExisty'}
										validationError="Please enter three letters for Partner code."
									/>
								</div>
							)}

							<div className="h-marginB--sm" data-automation="fieldLogoHolder">
								<label>{!this.props.isModalUpdate ? 'Logo *' : 'Logo'}</label>
								<FormsyDropzone
									className="dropzone"
									multiple={false}
									onDrop={this.onLogoDrop}
									name="logo"
									required={!this.props.isModalUpdate}
									value={this.state.logo}
									data-automation="fieldLogo"
								>
									<p data-automation="textSelectAirlineLogo">Select partner logo</p>
								</FormsyDropzone>

								{this.state.uploadLogoError ? (
									<p
										className="AddAirline__iconUploadError"
										data-automation="textAddAirlineLogoUploadError"
									>
										{this.state.uploadLogoError}
									</p>
								) : null}
							</div>

							{imageData && (
								<div className="LogoPreview h-marginB--xs">
									<img
										className="LogoPreview__img"
										src={imageData}
										crossOrigin="anonymous"
										alt="Logo uri broken."
									/>
								</div>
							)}

							<div className="h-marginB--sm" data-automation="fieldSelectTimezoneHolder">
								<label htmlFor="timezone">Partner headquarters timezone *</label>
								<ComboSelect
									name="zone_id"
									data={timezones}
									layout="vertical"
									sort="off"
									value={this.state.timeZone}
									search="on"
									data-automation="fieldSelectTimezone"
									required
									onChange={this.handleTimeZoneChange}
									{...maxHeightProps}
								/>
							</div>

							<div className="h-marginB--sm" data-automation="fieldSelectLocaleHolder">
								<label htmlFor="locales">Locale *</label>
								<ComboSelect
									name="locales"
									data={this.props.locales ? this.props.locales : []}
									type="multiselect"
									layout="vertical"
									sort="off"
									map={{ text: 'name', value: 'code' }}
									value={this.state.selectedLocales}
									onChange={this.handleLocaleChange}
									data-automation="fieldSelectLocale"
									required
									{...maxHeightProps}
								/>
							</div>

							<div className="h-marginB--sm" data-automation="fieldTblPartnerCheckboxHolder">
								<CheckboxRadioItem
									dataAutomationSuffix="tblPartnerCheckbox"
									key="tblPartnerCheckboxKey"
									label="Enable Custom Reports"
									labelClassName="CheckBoxGroup h-displayBlock"
									name="tblPartnerCheckbox"
									onChange={this.handleTblPartnerSection}
									spanLabelClassName="CheckBoxGroup__spanLabel h-marginL--sm"
									type="checkbox"
									checked={this.state.isTblPartnerSection}
								/>
							</div>

							{this.state.isTblPartnerSection && (
								<>
									<div
										className="h-marginB--sm h-relative"
										data-automation="fieldTblPartnerUsernameHolder"
									>
										{/*fake Tab- part of username and send it always*/}
										{/* <span
											style={{
												position: 'absolute',
												top: '2.6em',
												left: 15,
											}}
										>
											Tab-
										</span> */}
										<Input
											type="text"
											name={`tableau_user[username]: ${tblUsername}`}
											label="Tableau username"
											value={tblUsername}
											className="InputBox"
											id="username"
											placeholder="Username"
											autoComplete="off"
											ref={this.tblUsernameRef}
											onChange={this.handleTblUsernameChange}
											// disabled={!this.props.isModalUpdate && this.state.selectedType == 'Airline'}
											layout="vertical"
											validations="isNotWhitespaceOnly"
											validationError={this.state.validationErrorsUsername}
											data-automation="fieldUsername"
											// style={{ paddingLeft: 41 }}
										/>
									</div>

									<div className="h-marginB--sm" data-automation="fieldTblPartnerPasswordHolder">
										<Input
											type="password"
											name={`tableau_user[password]: ${tblPassword}`}
											label="Tableau password"
											value={tblPassword}
											className="InputBox"
											id="password"
											placeholder="Password"
											ref={this.tblPasswordRef}
											onChange={this.handleTblPasswordChange}
											// disabled={!this.props.isModalUpdate && this.state.selectedType == 'Airline'}
											layout="vertical"
											data-automation="fieldPassword"
										/>
									</div>
								</>
							)}

							<div className="h-marginB--md" data-automation="fieldHandlingTableauPageReport">
								<CheckboxRadioItem
									dataAutomationSuffix="pageHandlingTableauPageReportCheckbox"
									key="pageHandlingTableauPageReportCheckboxKey"
									label="Enable Tableau - BIA UI"
									labelClassName="CheckBoxGroup h-displayBlock"
									name="pageTblPartnerCheckbox"
									onChange={this.handleHandlingTableauPageReportSection}
									spanLabelClassName="CheckBoxGroup__spanLabel h-marginL--sm"
									type="checkbox"
									checked={this.state.isHandlingTableauPageReportingSection}
								/>
							</div>

							<div className="h-marginB--sm" data-automation="fieldEmailHolder">
								<Input
									type="email"
									name="email"
									label="Email contact"
									value={email}
									className="InputBox"
									id="email_contact"
									placeholder="Email contact"
									ref={this.emailContact}
									required
									layout="vertical"
									validations="isEmail,maxLength:49"
									validationErrors={{
										isEmail: 'Please enter valid email',
										maxLength: 'Max Length is 49 characters',
									}}
									help="Email notifications will go to this address"
									data-automation="fieldEmail"
								/>
							</div>

							<div className="h-pullRight">
								<input
									type="button"
									className="Button Button--text Button--text--underline"
									onClick={() => {
										this.props.closeModalCallback();
									}}
									defaultValue="Cancel"
									data-automation={`actionButtonCancel${this.props.title}`}
								/>
								<SmartButton
									type="submit"
									className="Button Button--action"
									formNoValidate
									isInPendingState={this.props.isLoading}
									text="Save"
									data-dismiss="no-dismiss"
									isDisabled={!formIsValid}
									pendingText="Saving..."
									dataAutomation="actionButtonSaveAirline"
									defaultValue="Save"
								/>
							</div>
						</Formsy>
					</>
				</>
			</div>
		);
	}
}
