import React from 'react';
import PropTypes from 'prop-types';

const ReportListItem = props => {
	const showReport = () => {
		props.navigateToReport(props.reportData);
	};

	return (
		<div className="col-xs-12 col-sm-6 col-md-3 h-marginT--lg h-displayFlex">
			<div className="Thumbnail">
				{props.imgSrc ? (
					<img src={props.imgSrc} className="Thumbnail__image" alt="Report preview" />
				) : (
					<div className="Thumbnail__image Thumbnail__image--tblReportDefault" aria-hidden="true" />
				)}
				<div className="Thumbnail__caption">
					<h3 className="h-textCenter h-marginB--l h-bold">{props.reportName}</h3>
					<button
						className="Button Button--action h-fullWidth h-marginT--md"
						data-automation="actionOpenTableauReport"
						data-dismiss="modal"
						onClick={showReport}
					>
						Show report
					</button>
				</div>
			</div>
		</div>
	);
};

ReportListItem.propTypes = {
	imgSrc: PropTypes.string,
	reportName: PropTypes.string.isRequired,
	reportData: PropTypes.object.isRequired,
	navigateToReport: PropTypes.func,
};

export default ReportListItem;
