import React from 'react';
import BreadcrumbItem from './BreadcrumbItem';

const crumbsBuilderWithRoutes = (breadcrumbs, props, route, app, showTruncated, tooltipItem) => {
	for (const page of app) {
		if (page.path && route && route.indexOf(page.path.split('/')[0]) > -1) {
			let matchedPath = '';
			const path = props.location.pathname;
			if (path && !page.path.includes('/:')) {
				const pathMatchPattern = new RegExp(`^(.*?)/${page.path}/`);
				const patternMatchingResult = path.match(pathMatchPattern);
				matchedPath = patternMatchingResult ? patternMatchingResult[0] : '';
			} else if (path && page.path.includes('/:')) {
				const slicedPath = page.path.split('/:')[0];
				const parsedUrl = path.match(new RegExp(`^(.*?)/${slicedPath}/((.*?)/|(.*))`));

				matchedPath = parsedUrl ? parsedUrl[0] : '';
			}

			const crumb = (
				<BreadcrumbItem
					appName={app.name}
					pathName={page.name}
					key={`breadcrumb-item-${page.name}`}
					location={matchedPath && matchedPath.replace(/\/$/, '')}
				/>
			);

			tooltipItem.text = app.name
				? `${app.name} > ${page.name}`
				: tooltipItem.text
					? `${tooltipItem.text} > ${page.name}`
					: page.name;

			breadcrumbs.push(crumb);
			if (page.pages) {
				crumbsBuilderWithRoutes(breadcrumbs, props, route, page.pages, showTruncated, tooltipItem);
			}
			break;
		}
	}
};

export default crumbsBuilderWithRoutes;
